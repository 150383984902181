import request from '@/router/axios';

export const getUserInfo = () => {
  return request({
    url: '/api/sino-user/info',
    method: 'get',
  })
}

export const getUserAndCompany = () => {
  return request({
    url: '/api/sino-user/getUserAndCompany',
    method: 'get',
  })
}

export const checkRegPhoneCode = (phone,regCode,key) => {
  return request({
    url: '/api/sino-user/checkRegPhoneCode',
    method: 'post',
    params: {
      phone,
      regCode,
      key
    }
  })
}

export const checkRegEmailCode = (email,regCode,key) => {
  return request({
    url: '/api/sino-user/checkRegEmailCode',
    method: 'post',
    params: {
      email,
      regCode,
      key
    }
  })
}

export const findPassword = (row) => {
  return request({
    url: '/api/sino-user/findPassword',
    method: 'post',
    data: row
  })
}

export const updatePassword = (oldPassword, newPassword, newPassword1) => {
  return request({
    url: '/api/sino-user/update-password',
    method: 'post',
    params: {
      oldPassword,
      newPassword,
      newPassword1,
    }
  })
}


export const cmsUpdateUser = (row) => {
  return request({
    url: '/api/sino-user/cmsUpdateUser',
    method: 'post',
    data: row
  })
}