<template>
  <div>
    <div>
      <div class="attract-left-div">
        <div class="title-row-div">
          <span class="to-buy-span">求购</span>
          <span class="detail-title-span">{{detailData.title}}</span>
        </div>
        <div class="detail-price-div">
          <span class="price-label-span">意&nbsp;向&nbsp;价</span>
          <span class="price-value-span">{{detailData.price}}{{detailData.priceUnit}}</span>
        </div>
        <div>
          <div class="detail-item-info-div">
            <span class="item-label-span">标的类型</span>
            <span class="item-value-span">{{formatDataType(detailData.type)}}</span>
          </div>
          <div class="detail-item-info-div">
            <span class="item-label-span">数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量</span>
            <span class="item-value-span">{{detailData.num}}{{detailData.numUnit}}</span>
          </div>
          <div class="detail-item-info-div">
            <span class="item-label-span">交&nbsp;&nbsp;货&nbsp;&nbsp;地</span>
            <span class="item-value-span">{{detailData.publishArea}}</span>
          </div>
          <div class="detail-item-info-div">
            <span class="item-label-span">交收方式</span>
            <span class="item-value-span">{{detailData.deliveryMethod}}</span>
          </div>
          <div class="detail-item-info-div">
            <span class="item-label-span">发布时间</span>
            <span class="item-value-span">{{detailData.publishTime}}</span>
          </div>
          <div class="detail-item-info-div">
            <span class="item-label-span">有效时间</span>
            <span class="item-value-span">{{detailData.dayCount}}天</span>
          </div>
        </div>
        <div class="bottom-button-div">
          <span class="to-supply-span button-cursor-span" @click="openDialog(1)">我要供应</span>
          <span class="send-message-span button-cursor-span" @click="openDialog(2)">发站内信</span>
        </div>
      </div>
      <div class="attract-right-div">
        <div class="attract-company-info-div">
          <div class="title-top-div">&nbsp;&nbsp;&nbsp;&nbsp;联系方式</div>
          <div v-if="detailData.company&&detailData.user">
            <div class="concat-info-div">{{detailData.company.name}}</div>
            <div class="concat-info-div">联系人：{{detailData.company.legalName}}</div>
            <div class="concat-info-div">地址：无</div>
            <div class="concat-info-div">手机：{{detailData.user.phone}}</div>
            <div class="concat-info-div">固话：无</div>
            <div class="concat-info-div">邮箱：{{detailData.user.email}}</div>
            <div class="concat-info-div">微信：无</div>
          </div>
          <div v-else>
            <div class="concat-info-div">未完善全部信息</div>
          </div>
        </div>
        <div class="attract-other-info-div">            
          <div class="title-top-div">&nbsp;&nbsp;&nbsp;&nbsp;该买家的其他求购</div>
          <div v-if="detailData.otherAttract">
            <div class="other-title-div">
                <span class="to-buy-span">求购</span>
                <span class="detail-title-span">{{detailData.otherAttract.title}}</span>
            </div>
            <div class="other-item-div">数量：{{detailData.otherAttract.num}}{{detailData.otherAttract.numUnit}}</div>
            <div class="other-item-div">发布时间：{{detailData.otherAttract.publishTime}}</div>
            <div class="other-item-div">有效时间：{{formatDataEndTIme(detailData.otherAttract.endTime)}}天</div>
          </div>
          <div v-else>
            <span class="detail-title-span">暂无其他信息</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-if="dialogVisible" :title="'填写'+(showType==1?'供应':'站内信')" :visible.sync="dialogVisible">
        <el-form :model="addForm" :rules="rules" status-icon ref="ruleForm" label-width="100px">
        <el-form-item label="姓名" prop="sendName">
          <el-input v-model="addForm.sendName"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input v-model="addForm.content" type="textarea" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      <el-button @click="dialogVisible=false">关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {getCmsDetail} from '@/api/biz/columnBulletin/attractInvestment'
import {getBizDictByCode} from '@/api/system/dict'
import {cmsSubmit} from '@/api/biz/columnBulletin/attractMeet'
import {mapGetters} from "vuex";
export default {
  name: 'AttractInverstmentDetailView',
  data() {
    return {
      detailData: {},
      bulletinTypeDict: [],
      dialogVisible: false,
      showType: 1,
      addForm: {},
      rules: {
        sendName: [{ required: true, message: '请输入姓名', trigger: 'change' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'change' }],
        content: [{ required: true, message: '请输入内容', trigger: 'change' }],
      }
    }
  },
  computed: {
    baiId() {
      return this.$route.query.id;
    },
    ...mapGetters(["userInfo"])
  },
  created(){
    this.init();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addForm.type = this.showType;
          this.addForm.baiId = this.baiId;
          cmsSubmit(this.addForm).then(res=>{
            if(res.data.data){
              this.dialogVisible = false;              
              this.$message({type: 'success',message: '操作成功！'})
            }else{
              this.$message({type: 'error',message: '操作失败！'})
            }
          })
        } else {
          return this.$message({type: 'warning',message: '请完成表单填写'})
        }
      });
    },
    openDialog(type){
      if(this.userInfo.account){
        // if(this.userInfo.role_name.indexOf('bidAgency')==-1){
        //   return this.$message({type:'warning', message: '本功能仅企业可用！'})
        // }
        if(this.userInfo.isAuth!=2){
          return this.$message({type:'warning', message: '请先完善实名认证！'})
        }
        this.showType = type;
        this.dialogVisible = true;
      }else{
        this.$message({type:'warning', message: '请登录后再使用当前功能！'})
      }
    },
    init(){
      this.getDict();
      this.queryDetail();
    },
    queryDetail(){
      if(this.baiId){
        getCmsDetail(this.baiId).then(res=>{
          this.detailData = res.data.data;
          this.detailData.dayCount = this.formatDataEndTIme(this.detailData.endTime)
        })
      }
    },
    formatDataEndTIme(time){
      let today = new Date().getTime();
      let endTime = new Date(time).getTime()
      if(endTime<today){
        return 0;
      }else{
        let subTime = (endTime - today)/(24*60*60*1000)
        return parseInt(subTime);
      }
    },
    formatDataType(type){
      let data = '';
      this.bulletinTypeDict.forEach(ele=>{if(ele.dictKey==type)data = ele.dictValue;})
      return data;
    },
    getDict(){
      getBizDictByCode('bulletin_type').then(res=>{
        this.bulletinTypeDict = res.data.data;
      });
    },
  },
}
</script>

<style scoped>
  .other-item-div{
    display: block;
    margin-left: 30px;
    margin-top: 21px;
    height: 21px;
    opacity: 1;
    /** 文本1 */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(0, 0, 0, 1);
  }
  .other-title-div{
    margin-top: 20px;
  }
  .concat-info-div{
    display: block;
    margin-left: 20px;
    margin-top: 15px;
    width: 270px;
    height: 21px;
    opacity: 1;
    /** 文本1 */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
  }
  .button-cursor-span:hover{
    cursor: pointer;
    box-shadow: 0px 4px 10px  rgba(0, 0, 0, 0.35);
  }
  .send-message-span{
    display: inline-block;
    margin-left: 44px;
    width: 200px;
    height: 50px;
    opacity: 1;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(246, 193, 101, 1) 0%, rgba(224, 168, 50, 1) 100%);
    /** 文本1 */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 4px;
    line-height: 50px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  .to-supply-span{
    display: inline-block;
    margin-left: 44px;
    width: 200px;
    height: 50px;
    opacity: 1;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(0, 102, 166, 1) 0%, rgba(59, 133, 243, 1) 100%);
    /** 文本1 */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 4px;
    line-height: 50px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  .bottom-button-div{
    margin-top: 28px;
    height: 50px; 
  }
  .item-value-span{
    display: inline-block;
    margin-top: 29px;
    margin-left: 20px;
    /** 文本1 */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26px;
    color: rgba(0, 0, 0, 1);
  }
  .item-label-span{
    display: inline-block;
    margin-top: 33px;
    margin-left: 44px;
    width: 58px;
    height: 20px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(153, 153, 153, 1);
    text-align: left;
    vertical-align: top;
  }
  .detail-item-info-div{
    height: 50px;
    opacity: 1;
    /** 文本1 */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
  }
  .price-value-span{
    display: inline-block;
    margin-top: 18px;
    margin-left: 20px;
    height: 45px;
    opacity: 1;
    /** 文本1 */
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 43.44px;
    color: rgba(212, 48, 48, 1);
    text-align: left;
    vertical-align: top;
  }
  .price-label-span{
    display: inline-block;
    margin-top: 30px;
    margin-left: 20px;
    width: 52px;
    height: 20px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .detail-price-div{
    margin-top: 15px;
    margin-left: 30px;
    width: 920px;
    height: 80px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(245, 247, 250, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
  }
  .detail-title-span{
    display: inline-block;
    margin-left: 15px;
    height: 28px;
    opacity: 1;
    /** 文本1 */
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 28.96px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .to-buy-span{
    display: inline-block;
    margin-left: 30px;
    width: 70px;
    height: 26px;
    opacity: 1;
    border-radius: 2px;
    background: rgba(236, 245, 255, 1);
    border: 1px solid rgba(64, 158, 255, 1);
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26px;
    color: rgba(64, 158, 255, 1);
    text-align: center;
  }
  .title-row-div{
    margin-top: 30px;
    height: 28px;
    opacity: 1;
    /** 文本1 */
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 28.96px;
    color: rgba(0, 0, 0, 1);
  }
  .title-top-div{
    width: 300px;
    height: 50px;
    opacity: 1;
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(180deg, rgba(0, 102, 166, 1) 0%, rgba(59, 133, 243, 1) 100%), rgba(0, 102, 166, 1);
    /** 文本1 */
    font-size: 18px;
    font-weight: 400;
    line-height: 50px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
  }
  .attract-other-info-div{
    margin-top: 20px;
    width: 300px;
    height: 243px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
  }
  .attract-company-info-div{
    width: 300px;
    height: 320px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
  }
  .attract-right-div{
    display: inline-block;
    margin-left: 18px;
    width: 300px;
    height: 583px;
    vertical-align: top;
  }
  .attract-left-div{
    display: inline-block;
    width: 980px;
    height: 583px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    vertical-align: top;
  }
</style>