<template>
    <div class="sifa-cont-right">
        <div class="personbox">
            <h2>保证金</h2>
            <div class="tabDiv" style="margin-top: 20px;">
                <el-table :data="tableData" stripe style="width: 100%" header-align="center">
                    <el-table-column prop="companyName" label="拍卖企业" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="personName" label="联系人" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="phone" label="联系电话" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="lotName" label="标的名称" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="payType" label="支付方式" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payType === 1">线上支付</span>
                            <span v-if="scope.row.payType === 2">线下支付</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="depositePrice" label="保证金(元)" align="center"></el-table-column>
                    <el-table-column prop="applyTime" label="缴纳时间" align="center" width="150px"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 0">待确认</span>
                            <span v-if="scope.row.status === 1">已缴纳</span>
                            <span v-if="scope.row.status === 2">已退款</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column fixed="right" label="操作" width="100" align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                            <el-button type="text" size="small">编辑</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
            <div class="block">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10,20,30,40,50,100]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
            </el-pagination>
            </div>
        </div>
    </div>
</template>
  
<script>
import {mapGetters} from "vuex";
import {cmsPageDeposit as getList} from '@/api/biz/auction/lotApply'

export default {
    name: 'platformPrice',
    computed: {
        ...mapGetters(["userInfo"]),
    },
    watch:{
    },
    data(){
        return {
            page: {
            pageSize: 10,
            currentPage: 1,
            total: 0
            },
            tableData: []
        }
    },
    created(){
        if(this.userInfo&&this.userInfo.user_name){
            this.init();
        }
    },
    methods:{
        init(){
            this.onLoad(this.page);
        },
        handleSizeChange(val) {
            console.log(1111,val)
            this.page.pageSize = val;
            this.onLoad(this.page);
        },
        handleCurrentChange(val) {
            console.log(2222,val)
            this.page.currentPage = val;
            this.onLoad(this.page);
        },
        onLoad(page) {
            getList(page.currentPage, page.pageSize).then((res) => {
                const data = res.data.data;
                this.page.total = data.total;
                this.tableData = data.records;
                console.log('data',this.tableData)
            })
        }
    }
}
</script>

<style>
.tableDiv h3 {
    background: #f3f5f7;
    border-bottom: 0;
    line-height: 40px;
    font-size: 14px;
    color: #666;
    font-weight: 400;
    overflow: hidden;
}
.auctionCompany {
    width: 263px;
    float: left;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding-left: 50px;
    text-align: left;
    white-space: nowrap;
    word-break: keep-all;
    word-wrap: normal;
}
.counselor, .phone {
    width: 320px;
    float: left;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pp-table {
    width: 100%;
    margin: 10px auto 0;
}
.pp-table .tab_img {
    width: 294px;
}
.pp-table td {
    float: left;
    border-right: 1px solid #d7d7d7;
    line-height: 22px;
    height: 80px;
    font-size: 12px;
    width: 165px;
    text-align: center;
}
.pp-table td:last-child {
    line-height: normal;
    border-right: none;
}
.tableDiv {
    width: 96%;
    margin: 18px auto 0;
    border: 1px solid #d7d7d7;
}
.block{
    margin-left: 50%;
    margin-bottom: 2%;
    margin-top: 2%;
}
.viewButton{
    margin-left: 20%;
    margin-top: 10%;
}
.tab_img img {
    vertical-align: middle;
    width: 120px;
    height: 80px;
    float: left;
    margin-right: 10px;
    margin-left: 5%;
}
.tab_price .price-box {
    /* padding: 22px 0 0; */
    line-height: 18px;
}
.time_box{
    line-height: 22px;
    padding-top: 22px;
}
.tab_time em {
    color: #999;
}
.personbox {
  margin: 0 0 40px;
  display: none;
  background-color: #fff;
  display: block;
}
.personbox h2 {
  height: 30px;
  line-height: 30px;
  background: #e9e9e9;
  color: #424242;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
</style>