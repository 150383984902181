<template>
  <div>
    <el-table :data="attractList" style="width: 100%" :cell-style='styleCenter' :header-cell-style='styleCenter'>
        <el-table-column prop="type" label="标的类型" width="120">
        <template slot-scope="scope">
            {{formatDataType(scope.row.type)}}
        </template>
        </el-table-column>
        <el-table-column prop="title" label="标的名称" width="140"></el-table-column>
        <el-table-column prop="price" label="价格" width="120">
        <template slot-scope="scope">
            {{scope.row.price}}{{scope.row.priceUnit}}
        </template>
        </el-table-column>
        <el-table-column prop="endTime" label="有效时间" width="120">
            <template slot-scope="scope">
                {{scope.row.endTime.slice(0,10)}}
            </template>
        </el-table-column>
        <el-table-column prop="publishArea" label="所在地" width="140"></el-table-column>
        <el-table-column prop="publishStatus" label="审核状态" width="160">
            <template slot-scope="scope">
                {{scope.row.publishStatus==1?'已提交':(scope.row.publishStatus==2?'已发布':'已驳回')}}
            </template>
        </el-table-column>
        <el-table-column prop="publishStatus" label="操作" width="160">
            <template slot-scope="scope">
                <el-button v-if="scope.row.publishStatus==2" type="text" @click="openDetail(scope.row,1)">查看供应</el-button>
                <el-button v-if="scope.row.publishStatus==2" type="text" @click="openDetail(scope.row,2)">查看站内信</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="bottom-page-div">
        <el-pagination  @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
    </div>
    <el-dialog v-if="dialogVisible" title="查看" :visible.sync="dialogVisible">
      <div>
        <el-table :data="showData" style="width: 100%">
          <el-table-column prop="sendName" label="姓名" width="180"></el-table-column>
          <el-table-column prop="phone" label="电话" width="180"></el-table-column>
          <el-table-column prop="content" label="内容" width="400"></el-table-column>
        </el-table>
        <el-pagination  @size-change="dialogHandleSizeChange" @current-change="dialogHandleCurrentChange" :current-page="dialogPage.currentPage"
          :page-sizes="[10, 20, 30, 50]" :page-size="dialogPage.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dialogPage.total">
        </el-pagination>
      </div>        
      <el-button @click="dialogVisible=false">关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { cmsMyList } from "@/api/biz/columnBulletin/attractInvestment";
import {mapGetters} from "vuex";
import {getBizDictByCode} from '@/api/system/dict'
import {getCmsList} from '@/api/biz/columnBulletin/attractMeet'
export default {

  name: 'attractInvestmentManagerView',
  data() {
    return {
      attractList: [],
      styleCenter: {'text-align':'center'},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      dialogPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      dialogVisible: false,
      showType: 1,
      rowData: {},
      showData: [],
    }
  },
  computed: {...mapGetters(["userInfo"])},
  created() {
    this.getDict();
    this.queryData();
  },
  methods: {
    dialogHandleSizeChange(val){
      this.dialogPage.pageSize = val;
      this.queryDialogData();
    },
    dialogHandleCurrentChange(val){
      this.dialogPage.currentPage = val;
      this.queryDialogData();
    },
    queryDialogData(){      
      let page = this.dialogPage;
      getCmsList(page.currentPage, page.pageSize, {baiId: this.rowData.id, type: this.showType}).then(res=>{
        const data = res.data.data;
        this.showData = data.records;
        this.dialogPage.total = data.total;
      })
    },
    openDetail(row, type){
      this.showType = type;
      this.rowData = row;
      let page = this.dialogPage;
      getCmsList(page.currentPage, page.pageSize, {baiId: row.id, type: type}).then(res=>{
        const data = res.data.data;
        this.showData = data.records;
        this.dialogPage.total = data.total;
        if(data.total>0){
          this.dialogVisible = true;
        }else{
          return this.$message({type: 'warning', message: '未查询到数据'})
        }
      })
    },
    getDict(){
      getBizDictByCode('bulletin_type').then(res=>{
        this.bulletinTypeDict = res.data.data;
      });
    },
    formatDataType(type){
      let data = '';
      this.bulletinTypeDict.forEach(ele=>{if(ele.dictKey==type)data = ele.dictValue;})
      return data;
    },
    queryData(){
      let page = this.page;
      cmsMyList(page.currentPage, page.pageSize, {createUser: this.userInfo.user_id}).then(res=>{
        const data = res.data.data;
        this.attractList = data.records;
        this.page.total = data.total;
      })
    },
    handleSizeChange(val){
      this.page.pageSize = val;
      this.queryData(this.page);
    },
    handleCurrentChange(val){
      this.page.currentPage = val;
      this.queryData(this.page);
    },
    
  }
}
</script>

<style>

</style>