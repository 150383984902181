<template>
    <div class="sifa-cont-right">
      <div class="personbox">
        <h2>{{title}}</h2>
        <div class="sub-page"></div>
        <router-view></router-view>
      </div>
    </div>
  </template>
  
  <script>
    import {mapGetters} from "vuex";
  export default {
    name: 'accountSecurity',
    computed: {...mapGetters(["userInfo"])},
    created(){
        this.$router.push("/personalCenter/accountSecurity/accountShow")
    },
    data(){
      return {
        password: true,
        form: {},
        data: {},
        title: "账户信息",
      }
    },
    methods:{
        
    }
  }
  </script>
  
  <style>
  .sifa-cont-right {
    width: 1000px;
    float: left;
    min-height: 500px;
    margin-left: 20px;
    margin-top: -35px;
}
.personbox {
    margin: 0 0 40px;
    display: none;
    background-color: #fff;
    display: block;
}
.personbox h2 {
    height: 30px;
    line-height: 30px;
    background: #e9e9e9;
    color: #424242;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 400;
}
.information {
    width: 100%;
    overflow: hidden;
}
.subtitle {
    padding-top: 20px;
    font-size: 16px;
    color: #333;
    padding-left: 40px;
    float: left;
}
.info-wrap {
    padding: 20px 0;
    border-bottom: 1px solid #d7d7d7;
}
.infoList {
    padding: 10px 0;
    font-size: 12px;
    color: #666;
    line-height: 16px;
}
.subinfo-label {
    width: 120px;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    font-size: 14px;
}
.subinfo-value {
    font-size: 14px;
    color: #333;
    margin-left: 10px;
}
.infolist span {
    margin-left: 10px;
}
.security-wrap {
    padding: 30px 30px 1px;
}
.securitylist {
    line-height: 70px;
    border-bottom: 1px dotted #d7d7d7;
    padding-left: 100px;
    font-size: 12px;
}
.security-label {
    font-size: 14px;
    color: #333;
}
.security-tip {
    color: #666;
    margin-left: 30px;
}
.security-btn {
    float: right;
    margin-right: 164px;
    color: #d6363b;
    width: 50px;
    cursor: pointer;
}
.authForm{
    margin-top: 3%;
}
.authRow {
    margin-left: 7%;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  </style>