<template>
  <div>注册页</div>
</template>

<script>
export default {
  name: "RegisterView",
}
</script>

<style>

</style>