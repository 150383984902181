/**
 * 拍卖状态枚举
 */
export default [
  {key: 0, value: '未开始'},
  {key: 1, value: '进行中'},
  {key: 2, value: '已暂停'},
  {key: 3, value: '已成交'},
  {key: -1, value: '已流拍'},
]
  