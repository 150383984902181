<template>
  <div>
    <div>
      <p class="top-div">
        <span class="top-span1">拍卖会&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span
          class="top-span1">{{firstLot.companyName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="top-span2">{{meetingData.meetingName}}</span>
      </p>
    </div>
    <div class="meeting-content">
      <img class="content-left" :src="imgLink">
      <div class="content-right">
        <div>
          <span
            :class="meetingData.meetingType==0?'active-detail-network-type':'active-detail-sync-type'">{{meetingData.meetingType==1?'同步拍':'网络拍'}}</span>
        </div>
        <p class="detail-name">
          {{meetingData.meetingName}}
        </p>
        <p class="detail-company">
          拍卖企业：{{firstLot.companyName}}
        </p>
        <p class="detail-company">
          联系电话：{{bulletinData.iphone}}
        </p>
        <div class="meeting-detail-time">
          <img class="detail-time-img" src="../../../public/img/time.png">
          <span class="meeting-detail-start-time">{{meetingData.startAuctionTime}}</span>
        </div>
        <div>
          <span class="content-button-jump" @click="toLotDetail(firstLot)">进入拍卖大厅</span>
        </div>
        <div>
          <span class="content-number-out-span">
            <span class="content-number-span">{{lotList.length}}</span><br>
            <span class="content-name-span">标的数量</span>
          </span>
          <span class="content-number-center"></span>
          <span class="content-number-out-span">
            <span class="content-number-span">{{meetingViewCount}}</span><br>
            <span class="content-name-span">围观次数</span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <div>
        <span :class="activeType==1?'title-selected':'title-unSelected'" @click="changeActiveType(1)">标的列表</span>
        <span :class="activeType!=1?'title-selected':'title-unSelected'" @click="changeActiveType(2)">拍卖公告</span>
      </div>
      <div class="meeting-bottom-div">
        <template v-if="activeType==1">
          <div style="margin-bottom:20px;">
            <LotDiv :lotList='lotList' :meetingData="meetingData"></LotDiv>
          </div>
        </template>
        <template v-else>
          <div class="active-bulletin-content">
            <p class="meeting-bulletin-content" v-html="bulletinData.content"></p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { listByMeetingId } from '@/api/biz/auction/auctionLot';
import LotDiv from '../lots/LotDiv.vue'
export default {
  name: 'MeetingDetailView',
  components: { LotDiv },
  data() {
    return {
      activeType: 1,//1列表、2公告
      lotList: [],
      meetingData: {},
      bulletinData: {},
      firstLot: {},
      imgLink: '',
      meetingViewCount: 0,
    }
  },
  computed: {
    meetingId() {
      return this.$route.query.meetingId;
    }
  },
  created() {
    this.activeType = this.$route.query.activeType;
    this.getLotsByMeetingId();
  },
  methods: {
    toLotDetail(lot) {
      this.$router.push({ path: "/lotDetail", query: { lotId: lot.id } })
    },
    changeActiveType(type) {
      this.activeType = type;
    },
    getLotsByMeetingId() {
      listByMeetingId(this.meetingId).then(res => {
        this.lotList = res.data.data;
        this.firstLot = this.lotList[0];
        this.imgLink = this.firstLot.auctionAttach[0].link
        this.meetingData = this.firstLot.auctionMeeting;
        this.bulletinData = this.firstLot.bulletin;
        this.lotList.forEach(e => { this.meetingViewCount = this.meetingViewCount + e.viewCount });
      })
    },

  },
}
</script>

<style>
.meeting-bulletin-content {
  max-width: 1200px;
  overflow: hidden;
}
.meeting-bottom-div {
  width: 1320px;
  overflow: hidden;
}
.active-bulletin-content {
  margin-top: 20px;
  background: rgba(255, 255, 255, 1);
  width: 1200px;
  min-height: 250px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  left: 310px;
  border-radius: 5px;
  border: 1px solid rgba(215, 215, 215, 1);
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 20px;
}
.title-unSelected:hover {
  cursor: pointer;
}
.title-unSelected {
  display: inline-block;
  margin-right: 20px;
  margin-top: 20px;
  width: 64px;
  height: 34px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  vertical-align: top;
}
.title-selected {
  display: inline-block;
  margin-right: 20px;
  margin-top: 20px;
  /* width: 64px; */
  height: 34px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(0, 102, 166, 1);
  text-align: center;
  vertical-align: top;
  border-bottom: 2px solid rgba(0, 102, 166, 1);
}
.content-name-span {
  display: inline-block;
  width: 56px;
  height: 21px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content-number-span {
  display: inline-block;
  width: 56px;
  height: 27px;
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}
.content-number-center {
  margin-left: 85px;
  width: 0px;
  height: 20px;
  opacity: 1;
  border: 1px solid rgba(215, 215, 215, 1);
  display: inline-block;
}
.content-number-out-span {
  margin-top: 20px;
  margin-left: 85px;
  display: inline-block;
  width: 56px;
  /* height: 55px; */
  opacity: 1;
}
.content-button-jump:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
}
.content-button-jump {
  display: inline-block;
  margin-left: 135px;
  margin-top: 20px;
  width: 200px;
  height: 42px;
  line-height: 42px;
  opacity: 1;
  background: rgba(0, 102, 166, 1);
  /** 文本1 */
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  vertical-align: top;
}
.detail-time-img {
  margin-top: -5px;
  height: 20px;
}
.meeting-detail-time {
  margin-top: 25px;
  margin-left: 41px;
  min-height: 27px;
}
.detail-company {
  margin-top: 10px;
  margin-left: 40px;
  width: 410px;
  height: 18px;
  opacity: 1;
  /** 文本1 */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(153, 153, 153, 1);
  text-align: left;
  vertical-align: top;
}
.detail-name {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 12px;
  margin-left: 40px;
  width: 410px;
  /* min-height: 53px; */
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}
.active-detail-network-type {
  display: inline-block;
  margin-left: 410px;
  width: 60px;
  height: 28px;
  opacity: 1;
  border-radius: 2px 0px, 2px, 2px;
  background: rgba(42, 130, 228, 1);
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.active-detail-sync-type {
  display: inline-block;
  margin-left: 410px;
  width: 60px;
  height: 28px;
  opacity: 1;
  border-radius: 2px 0px, 2px, 2px;
  background: rgba(253, 167, 0, 1);
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.content-left {
  width: 810px;
  height: 337px;
}
.content-right {
  position: absolute;
  margin-left: 20px;
  display: inline-block;
  width: 470px;
  height: 337px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.meeting-content {
  margin-top: 10px;
  height: 337px;
  opacity: 1;
}
.top-span1 {
  /** 文本1 */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(0, 0, 0, 1);
}
.top-span2 {
  /** 文本2 */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(0, 102, 166, 1);
  text-align: left;
  vertical-align: top;
}
.top-div {
  margin-top: -10px;
  height: 18px;
  opacity: 1;
}
</style>