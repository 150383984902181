import { render, staticRenderFns } from "./accountSecurity.vue?vue&type=template&id=588f8231&"
import script from "./accountSecurity.vue?vue&type=script&lang=js&"
export * from "./accountSecurity.vue?vue&type=script&lang=js&"
import style0 from "./accountSecurity.vue?vue&type=style&index=0&id=588f8231&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports