<template>
  <div class="footer-div">
    <div class="footer-center">
      <div class="footer-center-logo-div">
        <img class="footer-logo" src="../../public/img/logo.png" alt="">
        <br>
        <span class="footer-center-text">儒风商才 义利合一</span>
      </div>
      <div class="footer-bidder-help-div">
        <img class="loneline-position" src="../../public/img/loneline.png">
        <span class="footer-text-title cursor-pointer-text" @click="jumpHelp('竞买人帮助')">竞买人帮助</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('竞拍流程')">竞拍流程</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('出价规则')">出价规则</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('延时规则')">延时规则</span>
      </div>
      <div class="footer-bidder-help-div">
        <img class="loneline-position" src="../../public/img/loneline.png">
        <span class="footer-text-title cursor-pointer-text" @click="jumpHelp('支付帮助')">支付帮助</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('如何报名交保证金')">如何报名交保证金</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('交保遇到限额')">交保遇到限额</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('尾款如何支付')">尾款如何支付</span>
      </div>
      <div class="footer-bidder-help-div">
        <img class="loneline-position" src="../../public/img/loneline.png">
        <span class="footer-text-title cursor-pointer-text" @click="jumpHelp('常见问题')">常见问题</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('退还保证金')">退还保证金</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('悔拍怎么办')">悔拍怎么办</span><br>
        <span class="footer-text-context cursor-pointer-text" @click="jumpHelp('如何办理过户')">如何办理过户</span>
      </div>
      <div class="footer-bidder-help-div">
        <img class="loneline-position" src="../../public/img/loneline.png">
        <span class="footer-text-title cursor-pointer-text">关于我们</span><br>
        <span class="footer-text-context">邮编：250013</span><br>
        <span class="footer-text-context">客户专线：0531-67894776</span><br>
        <span class="footer-text-context">客服邮箱：kefu@caa123.org.cn</span><br>
        <span class="footer-text-context">联系地址：济南市历下区舜耕路8-2号</span>
      </div>
      <div class="footer-bidder-help-div erweima-div">
        <img class="footer-erweima-img" src="../../public/img/qiyeerweima.jpg" alt="">
        <br>
        <span class="footer-erweima-text">齐鲁观水公众号</span>
      </div>
      <div class="footer-bidder-help-div erweima-div">
        <img class="footer-erweima-img" src="../../public/img/paimaierweima.png" alt="">
        <br>
        <span class="footer-erweima-text">拍卖公众号</span>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bootom-text">
        <span>齐鲁观水信息技术有限公司版权所有</span><br>
        <span class="cursor-pointer-text" @click="jumpOut">鲁ICP备10025418号-2</span>
        <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
        <!-- <span>鲁公网安备鲁公网安备37010202001156号</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterView',
  methods: {    
    jumpHelp(type) {
      this.$router.push({ path: "/help",query: {type} })
    },
    jumpOut() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style>
.cursor-pointer-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
.footer-bootom-text {
  padding-top: 10px;
  margin-left: 354px;
  width: 597px;
  height: 60px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(51, 51, 51, 1);
}
.erweima-div {
  text-align: center;
}
.footer-erweima-text {
  display: inline-block;
  margin-top: 10px;
  width: 126px;
  height: 24px;
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 23.74px;
  color: rgba(255, 255, 255, 1);
}
.footer-erweima-img {
  width: 80px;
  height: 80px;
}
.footer-text-context {
  display: inline-block;
  margin-top: 15px;
  margin-left: 15px;
  height: 22px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 21.1px;
  color: rgba(255, 255, 255, 1);
}
.loneline-position {
  margin-top: -10px;
}
.footer-text-title {
  margin-left: 10px;
  width: 110px;
  height: 30px;
  opacity: 1;
  /** 文本1 */
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 29.02px;
  color: rgba(255, 255, 255, 1);
}
.footer-center-logo-div {
  float: left;
}
.footer-bidder-help-div {
  min-width: 150px;
  margin-top: 71px;
  float: left;
}
.footer-center-text {
  margin-left: 53px;
  margin-top: 8px;
  display: inline-block;
  width: 180px;
  height: 26px;
  opacity: 1;
  /** 文本1 */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 25.6px;
  color: rgba(255, 255, 255, 1);
}
.footer-logo {
  margin-left: 77px;
  margin-top: 71px;
  width: 122px;
  height: 117px;
  opacity: 1;
}
.footer-center {
  margin: 0 auto;
  width: 1300px;
  height: 300px;
  opacity: 1;
  background: rgba(64, 64, 64, 1);
}
.footer-bottom {
  text-align: center;
  margin: 0 auto;
  width: 1300px;
  height: 80px;
  opacity: 1;
  background: rgba(204, 204, 204, 1);
}
.footer-div {
  z-index: 0;
  background: rgba(238, 242, 245, 1);
}
</style>