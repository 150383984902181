import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsMeeting = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/cmsMeeting',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/save',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/update',
    method: 'post',
    data: row
  })
}

export const pageMeetingByAuctioneer = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/pageMeetingByAuctioneer',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const cmsDetail = (id) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/cmsDetail',
    method: 'get',
    params: {
      id
    }
  })
}

export const cmsQueryMeetingCount = (timeCount) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/cmsQueryMeetingCount',
    method: 'get',
    params: {
      timeCount
    }
  })
}

export const getCmsCompanyList = (timeCount) => {
  return request({
    url: '/api/sino-biz/auctionMeeting/cmsCompanyList',
    method: 'get',
    params: {
      timeCount
    }
  })
}