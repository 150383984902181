<template>
  <div>
    <div>
        <el-button @click="openDialog">开具发票</el-button>
    </div>
    <br>
    <div>
      <el-table :data="winLotList" style="width: 100%" :cell-style='styleCenter' :header-cell-style='styleCenter'
        @selection-change="handleSelectionChange">
        <el-table-column type="selection"  width="55"></el-table-column>
        <el-table-column prop="platformOrderCode" label="订单号" width="200"></el-table-column>
        <el-table-column prop="lotName" label="标的信息" width="200"></el-table-column>
        <el-table-column prop="platformCost" label="金额" width="200"></el-table-column>
        <el-table-column prop="platformPayTime" label="缴纳时间" width="200"></el-table-column>
      </el-table>
      <div class="bottom-page-div">
        <el-pagination  @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="申请发票" :visible.sync="platformDialog">
      <div>
        <span>开票金额：</span>
        <span>￥{{platformSumPrice}}</span>
      </div>
      <el-divider></el-divider>
      <el-checkbox-group v-model="invoiceType" :max="1">
        <el-checkbox label="个人普票（电子版）" :true-label='1'></el-checkbox>
        <el-checkbox label="企业普票（电子版）" :true-label='2'></el-checkbox>
        <el-checkbox label="企业专票" :true-label='3'></el-checkbox>
      </el-checkbox-group>
      <el-divider></el-divider>
      <el-form :model="addForm" :rules="rules" status-icon ref="ruleForm" label-width="120px">
        <el-form-item label="发票抬头：" prop="invoiceTitle">
          <el-input v-model="addForm.invoiceTitle"></el-input>
        </el-form-item>
        <el-form-item v-if="invoiceType!=1" label="纳税人识别号：" prop="taxpayerNum">
          <el-input v-model="addForm.taxpayerNum"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号：" prop="recipientsPhone">
          <el-input v-model="addForm.recipientsPhone"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱：" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <template v-if="invoiceType!=1">
          <el-form-item label="注册公司地址：" prop="companyAddress">
            <el-input v-model="addForm.companyAddress"></el-input>
          </el-form-item>
          <el-form-item label="开户行：" prop="openingBank">
            <el-input v-model="addForm.openingBank"></el-input>
          </el-form-item>
          <el-form-item label="银行账号：" prop="bankAccount">
            <el-input v-model="addForm.bankAccount"></el-input>
          </el-form-item>
          <el-form-item label="注册公司电话：" prop="companyPhone">
            <el-input v-model="addForm.companyPhone"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="发票备注：" prop="invoiceNote">
          <el-input v-model="addForm.invoiceNote"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button @click="platformDialog=false">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getCmsPlatformPage} from '@/api/biz/auction/winLot'
import {cmsSave} from '@/api/biz/auction/invoiceInfo'
import { isvalidatemobile } from "@/util/validate";
export default {
  name: 'orderInvoiceView',
  data() {
    const validatePhone = (rule, value, callback) => {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]));
      } else {
        callback();
      }
    };
    return {        
      styleCenter: {'text-align':'center'},
      winLotList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      multipleSelection: [],
      platformDialog: false,
      platformSumPrice: 0,
      addForm: {},
      invoiceType: 1,
      rules: {
        invoiceTitle: [{ required: true, message: '请输入发票抬头', trigger: 'change' }],
        taxpayerNum: [{ required: true, message: '请输入纳税人识别号', trigger: 'change' }],
        recipientsPhone: [{ required: true, message: '请输入收件人手机号', trigger: 'change', validator: validatePhone }],
        email: [{ required: true, message: '请输入电子邮箱', trigger: 'change'},
                {type: 'email', message:'邮箱格式不正确，请重新输入',trigger: 'blur'}],
      }
    }
  },
  computed: {...mapGetters(["userInfo"])},
  created(){
    this.queryData(this.page);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            this.addForm.invoiceType = this.invoiceType;
            this.addForm.money = this.platformSumPrice;
            this.addForm.winLotVOList = this.multipleSelection;
          cmsSave(this.addForm).then(res=>{
            if(res.data.data){
              this.platformDialog = false;              
              this.$message({type: 'success',message: '操作成功！'})
            }else{
              this.$message({type: 'error',message: '操作失败！'})
            }
            this.queryData(this.page);
          })
        } else {
          return this.$message({type: 'warning',message: '请完成表单填写'})
        }
      });
    },
    openDialog(){
      if(this.multipleSelection.length>0){
        this.platformSumPrice = 0;
        this.multipleSelection.forEach(ele=>{
          this.platformSumPrice+=parseFloat(ele.platformCost);
        })
        this.platformDialog = true;
      }else{
        this.$message({type: 'warning', message: '请至少选择一条数据'})
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    queryData(page) {
      getCmsPlatformPage(page.currentPage, page.pageSize, {platformType: 2}).then(res=>{
        const data = res.data.data;
        this.winLotList = data.records;
        this.page.total = data.total;
      })
    }, 
    handleSizeChange(val){
      this.page.pageSize = val;
      this.queryData(this.page);
    },
    handleCurrentChange(val){
      this.page.currentPage = val;
      this.queryData(this.page);
    },
  },
}
</script>

<style scoped>
  .bottom-page-div{
    text-align:right;
    margin-right:50px;
    height: 60px;
    margin-top: 20px;
  }
</style>