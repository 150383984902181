import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionLot/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsCompanyLotList = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionLot/cmsCompanyLotList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsCompanyDetail = (id) => {
  return request({
    url: '/api/sino-biz/auctionLot/cmsCompanyDetail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getAllList = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionLot/cmsAllTypePage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sino-biz/auctionLot/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sino-biz/auctionLot/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sino-biz/auctionLot/save',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sino-biz/auctionLot/update',
    method: 'post',
    data: row
  })
}

export const listNotJoinMeeting = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionLot/listNotJoinMeeting',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const joinLot = (ids, meetingId) => {
  return request({
    url: '/api/sino-biz/auctionLot/joinLot',
    method: 'post',
    params: {
      ids,
      meetingId
    }
  })
}

export const updateSort = (data) => {
  return request({
    url: '/api/sino-biz/auctionLot/updateSort',
    method: 'post',
    data: data
  })
}

export const deleteJoin = (row) => {
  return request({
    url: '/api/sino-biz/auctionLot/deleteJoin',
    method: 'post',
    data: row
  })
}

export const updateViewCount = (row) => {
  return request({
    url: '/api/sino-biz/auctionLot/updateViewCount',
    method: 'post',
    data: row
  })
}

export const listByMeetingId = (meetingId) => {
  return request({
    url: '/api/sino-biz/auctionLot/listByMeetingId',
    method: 'get',
    params: {
      meetingId
    }
  })
}

export const listByBulletinId = (bulletinId) => {
  return request({
    url: '/api/sino-biz/auctionLot/listByBulletinId',
    method: 'get',
    params: {
      bulletinId
    }
  })
}

export const getSystemNow = () => {
  return request({
      url: '/api/sino-biz/auctionLot/getNow',
      method: 'get',
  })
}