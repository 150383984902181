<template>
  <div>
    <div>
      <el-table :data="invoiceList" style="width: 100%" :cell-style='styleCenter' :header-cell-style='styleCenter'
        @selection-change="handleSelectionChange" @row-click='openDialog'>
        <el-table-column prop="invoiceTitle" label="发票抬头" width="200"></el-table-column>
        <el-table-column prop="invoiceType" label="发票类型" width="200">
          <template slot-scope="scope">
            {{scope.row.invoiceType==1?'个人普票（电子版）':(scope.row.invoiceType==2?'企业普票（电子版）':'企业专票')}}
          </template>
        </el-table-column>
        <el-table-column prop="money" label="金额" width="200"></el-table-column>
        <el-table-column prop="invoiceStatus" label="发票状态" width="200">
          <template slot-scope="scope">
            {{scope.row.invoiceStatus==1?'已提交':'已开票'}}
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-page-div">
        <el-pagination  @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="申请发票" :visible.sync="platformDialog">
      <div>
        <span>开票金额：</span>
        <span>￥{{showForm.money}}</span>
      </div>
      <el-divider></el-divider>
      <el-checkbox-group disabled v-model="showForm.invoiceType" :max="1">
        <el-checkbox label="个人普票（电子版）" :true-label='1'></el-checkbox>
        <el-checkbox label="企业普票（电子版）" :true-label='2'></el-checkbox>
        <el-checkbox label="企业专票" :true-label='3'></el-checkbox>
      </el-checkbox-group>
      <el-divider></el-divider>
      <el-form disabled :model="showForm" :rules="rules" status-icon ref="ruleForm" label-width="120px">
        <el-form-item label="发票抬头：" prop="invoiceTitle">
          <el-input v-model="showForm.invoiceTitle"></el-input>
        </el-form-item>
        <el-form-item v-if="showForm.invoiceType!=1" label="纳税人识别号：" prop="taxpayerNum">
          <el-input v-model="showForm.taxpayerNum"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号：" prop="recipientsPhone">
          <el-input v-model="showForm.recipientsPhone"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱：" prop="email">
          <el-input v-model="showForm.email"></el-input>
        </el-form-item>
        <template v-if="showForm.invoiceType!=1">
          <el-form-item label="注册公司地址：" prop="companyAddress">
            <el-input v-model="showForm.companyAddress"></el-input>
          </el-form-item>
          <el-form-item label="开户行：" prop="openingBank">
            <el-input v-model="showForm.openingBank"></el-input>
          </el-form-item>
          <el-form-item label="银行账号：" prop="bankAccount">
            <el-input v-model="showForm.bankAccount"></el-input>
          </el-form-item>
          <el-form-item label="注册公司电话：" prop="companyPhone">
            <el-input v-model="showForm.companyPhone"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="发票备注：" prop="invoiceNote">
          <el-input v-model="showForm.invoiceNote"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align:center">
        <el-button @click="platformDialog=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getList} from '@/api/biz/auction/invoiceInfo'
export default {
  name: 'InvoiceHistoryView',
  data() {
    return {        
      styleCenter: {'text-align':'center'},
      invoiceList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      platformDialog: false,
      showForm: {},
      rules: {
        invoiceTitle: [{ required: true, message: '请输入发票抬头', trigger: 'change' }],
        taxpayerNum: [{ required: true, message: '请输入纳税人识别号', trigger: 'change' }],
        recipientsPhone: [{ required: true, message: '请输入收件人手机号', trigger: 'change'}],
        email: [{ required: true, message: '请输入电子邮箱', trigger: 'change'},
                {type: 'email', message:'邮箱格式不正确，请重新输入',trigger: 'blur'}],
      }
    }
  },
  computed: {...mapGetters(["userInfo"])},
  created(){
    this.queryData(this.page);
  },
  methods: {
    openDialog(row){
      console.log('row',row)
      this.showForm = row; 
      this.platformDialog = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    queryData(page) {
      getList(page.currentPage, page.pageSize, {bidderId: this.userInfo.user_id}).then(res=>{
        const data = res.data.data;
        this.invoiceList = data.records;
        this.page.total = data.total;
      })
    }, 
    handleSizeChange(val){
      this.page.pageSize = val;
      this.queryData(this.page);
    },
    handleCurrentChange(val){
      this.page.currentPage = val;
      this.queryData(this.page);
    },
  },
}
</script>

<style>

</style>