<template>
  <div class="meeting-list-page">
    <div class="meeting-list-top">
      <div class="search-top-div">
        <span class="search-title">拍卖会形式：</span>
        <span :class="searchParam.meetingType==null?'search-item-selected':'search-item-class'" @click="changeMeetingType(null)">全部</span>
        <span :class="searchParam.meetingType==1?'search-item-selected':'search-item-class'" @click="changeMeetingType(1)">同步拍</span>
        <span :class="searchParam.meetingType==0?'search-item-selected':'search-item-class'" @click="changeMeetingType(0)">网络拍</span>
      </div>
      <div class="search-top-div">
        <span class="search-title">拍卖会阶段：</span>
        <span :class="searchParam.meetingStatus==null?'search-item-selected':'search-item-class'" @click="changeMeetingStatus(null)">全部</span>
        <span :class="searchParam.meetingStatus==10?'search-item-selected':'search-item-class'" @click="changeMeetingStatus(10)">即将开始</span>
        <span :class="searchParam.meetingStatus==20?'search-item-selected':'search-item-class'" @click="changeMeetingStatus(20)">正在进行</span>
        <span :class="searchParam.meetingStatus==30?'search-item-selected':'search-item-class'" @click="changeMeetingStatus(30)">已结束</span>
      </div>
      <div class="search-top-div">
        <span class="search-title">开始时间：</span>
        <span :class="searchParam.timeType==0?'search-item-selected':'search-item-class'" @click="changeTimeType(0)">全部</span>
        <span :class="searchParam.timeType==1?'search-item-selected':'search-item-class'" @click="changeTimeType(1)">今天</span>
        <span :class="searchParam.timeType==2?'search-item-selected':'search-item-class'" @click="changeTimeType(2)">未来三天</span>
        <span :class="searchParam.timeType==3?'search-item-selected':'search-item-class'" @click="changeTimeType(3)">未来一周</span>
        <el-date-picker v-model="searchTimeData" type="daterange" range-separator="—" @change='changeSearchTime' 
        size='small' value-format="yyyy-MM-dd">
        </el-date-picker>
        <span class="search-button" @click="queryData">确定</span>
      </div>
    </div>
    <div>
      <MeetingDiv :meetingData='meetingData' :showHall="true"></MeetingDiv>
    </div>
    <div class="page-class">
      <div class="el-page-class">
        <el-pagination   @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </div>
    </div>
    <div style="height:20px"></div>
  </div>
</template>

<script>
import {getCmsMeeting} from "@/api/biz/auction/auctionMeeting";
import {getBizDictByCode} from '@/api/system/dict'
import {dateFormat} from "@/util/date"
import MeetingDiv from "./MeetingDiv.vue"
export default {
  name: "MettingsList",
  components: {MeetingDiv},
  data(){
    return {
      searchParam:{
        meetingType: null,
        meetingStatus: null,
        timeType: 0,
        startTime: null,
        endTime: null,
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      meetingLoading: false,
      meetingData: [],
      searchTimeData: '', 
      bulletinTypeDict: [],
    }
  },
  created(){
    document.documentElement.scrollTop=0;
    this.init()
  },
  methods: {
    formatBulletinType(type){
      let value = '';
      this.bulletinTypeDict.forEach(t => {if(t.dictKey==type){value = t.dictValue;}})
      return value;
    },
    getBulletinType(){
      getBizDictByCode('bulletin_type').then(res=>{
        this.bulletinTypeDict = res.data.data;
        this.bulletinTypeDict.unshift({id: 111,dictValue: '全部',dictKey: null, sort: 0})
      });
    },
    getStartOfDistance(date){
      return parseInt((new Date(date).getTime()-new Date().getTime())/(24*60*60*1000)+1)
    },
    handleSizeChange(val){
      this.page.pageSize = val;
      this.queryData();
    },
    handleCurrentChange(val){
      this.page.currentPage = val;
      this.queryData();
    },
    changeSearchTime(){
      if(this.searchTimeData && this.searchTimeData.length==2){
        this.searchParam.timeType = 5;
      }else{
        this.searchParam.timeType = 0;
      }
    },
    changeMeetingType(val){
      this.searchParam.meetingType = val;
      this.queryData();
    },
    changeMeetingStatus(val){
      this.searchParam.meetingStatus = val;
      this.queryData();
    },
    changeTimeType(val){
      this.searchParam.timeType = val;
      this.searchTimeData = '',
      this.queryData();
    },
    meetingJump(meeting, type){
      this.$router.push({path:"/meetingDetial", query: {meetingId: meeting.id, activeType: type}})
    },
    async init(){
      await this.getBulletinType();
      let timeCount = this.$route.query.timeCount;
      if(timeCount!=null){
        if(timeCount==-1){
          this.changeMeetingStatus(30);
        }else{
          let todayTimeStamp = (parseInt(new Date().getTime()/(24*60*60*1000))-(1/3))*(24*60*60*1000)
          let todayStart = dateFormat(new Date(todayTimeStamp + ((timeCount-1)*(24*60*60*1000))));
          let todayEnd = dateFormat(new Date(todayTimeStamp + ((timeCount)*(24*60*60*1000))));
          this.searchParam.startTime = todayStart;
          this.searchParam.endTime = todayEnd;
          this.searchParam.timeType = 99;
          this.queryData();
        }
      }else{
        this.queryData();
      }
    },
    queryData(){
      this.meetingLoading = true;
      let params = this.formatQueryParam();
      getCmsMeeting(this.page.currentPage, this.page.pageSize, params).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.meetingData = data.records;
        for(let i = 0; i < this.meetingData.length; i++){
          let d = this.meetingData[i];
          d.bulletinType = this.formatBulletinType(d.bulletin.type);
        }
        this.meetingLoading = false;
      });
    },
    formatQueryParam(){
      let param = this.searchParam;
      if(this.searchParam.timeType!=0&&this.searchParam.timeType!=99&&this.searchParam.timeType!=5){
        let timeCount = 0;//当天
        if(this.searchParam.timeType==2){//三天
          timeCount = 3;
        }else if(this.searchParam.timeType==3){//一周
          timeCount = 7;          
        }
        let todayTimeStamp = (parseInt(new Date().getTime()/(24*60*60*1000))-(1/3))*(24*60*60*1000)
        let startTime = dateFormat(new Date(todayTimeStamp));
        let endTime = dateFormat(new Date(todayTimeStamp + ((timeCount+1)*(24*60*60*1000))));
        this.searchParam.startTime = startTime;
        this.searchParam.endTime = endTime;
      }else if(this.searchParam.timeType==5){
        this.searchParam.startTime = this.searchTimeData[0] + ' 00:00:00';
        let endDate = new Date(this.searchTimeData[1] + ' 00:00:00');
        let endTime = dateFormat(new Date(endDate.getTime() + (24*60*60*1000)));
        this.searchParam.endTime = endTime;
      }else if(this.searchParam.timeType!=99){
        this.searchParam.startTime = null;
        this.searchParam.endTime = null;
      }
      return param;
    }
  }
}
</script>

<style>

  .el-date-editor--daterange.el-input__inner{
    width: 280px !important;
  }
  .el-page-class{
    margin-right: 45px;
    float: right;
    padding-top: 45px;
    height: 50px;
    line-height: 50px;
  }
  .page-class{
    padding-bottom: 18px;
    /* width: 300px; */
    height: 100px;
    background-color: rgb(255,255,255);
  }
  .search-button:hover{    
    cursor: pointer;
    box-shadow: 0px 4px 10px  rgba(0, 0, 0, 0.35);
  }
  .search-button{
    display: inline-block;
    margin-left: 17px;
    text-align: center;
    line-height: 30px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    width: 60px;
    height: 30px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(0, 102, 166, 1);

  }
  .meeting-list-page{
    left: 310px;
    width: 1300px;    
  }
  .meeting-list-top{
    width: 1300px;
    height: 152px;
    padding-top: 10px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
  }
  .search-top-div{
    margin-top: 10px;
    margin-left: 31px;
  }
  .search-item-class{
    display: inline-block;
    width: 56px;
    height: 21px;
    margin-right: 16px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
  }
  .search-item-selected{
    display: inline-block;
    margin-right: 16px;
    width: 56px;
    height: 21px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 102, 166, 1);
  }
  .search-title{
    display: inline-block;
    width: 107px;
    height: 30px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(51, 51, 51, 1);
    text-align: left;
  }
  .jump-button:hover{
    cursor: pointer;
    box-shadow: 0px 4px 10px  rgba(0, 0, 0, 0.35);
  }
  .search-item-class:hover{
    cursor: pointer;
    text-decoration:underline;
  }
</style>