<template>
  <div>
    <ul class="lot-ul">
      <li v-for="n in lotsData" :key='n._id' class="lot-li" @click="toLotDetail(n)">
        <a class="lot-li-a">
          <div class="lot-header-section">
            <img class="lot-pic" :src="n.lotImg[0].link" :alt="n.lotName">
          </div>
          <p class="lot-title">
            <span class="lot-font">标的{{n.sort}}:{{n.lotName}}</span>
          </p>
          <p class="lot-title">起拍价：
            <span class="money-start">{{n.startPrice}}元人民币</span>            
          </p>
          <div class="lot-state-div lot-time-div">
            <p class="lot-time time-doing pai-status-doing-show">
              <span class="value pai-countdown pai-xmpp-countdown">
                <span class="time-text">{{n.address}}</span>
              </span>
            </p>
            <p class="num-auction">
              <span class="time-text">开拍时间：{{n.auctionMeeting.startAuctionTime}}</span>
            </p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {listByMeetingId, listByBulletinId} from '@/api/biz/auction/auctionLot'
export default {
  name: "LotsView",
  data(){
    return {
      activeMeetingId: null,
      activeBulletinId: null,
      lotsData: [],
      meetingData: {},
    }
  },
  created(){
    document.documentElement.scrollTop=0;
    if(this.$route.query.meetingId){
      this.activeMeetingId = this.$route.query.meetingId;
      this.queryLotsByMeetingId(this.activeMeetingId);
    }
    if(this.$route.query.bulletinId){
      this.activeBulletinId = this.$route.query.bulletinId;
      this.queryLotsByBulletinId(this.activeBulletinId);
    }
  },
  methods:{
    toLotDetail(lot){
      this.$router.push({path: "/lotDetail", query: {lotId: lot.id}})
    },
    queryLotsByBulletinId(id){
      listByBulletinId(id).then(res=>{
        this.lotsData = res.data.data;
      })
    },
    queryLotsByMeetingId(id){
      listByMeetingId(id).then(res=>{
        this.lotsData = res.data.data;
      })
    }
  }
}
</script>

<style>
  .lot-font{
    font-size: 17px;
    font-weight: 400;
  }
  .money-start{
    font-size: 18px;
    font-weight: 300;
    color: rgb(20,170,102)
  }
  .lot-li{
    float: left;
    margin-left: 10px;
    background-color: rgb(255,255,255)
  }
  .lot-ul{
    background-color: rgb(245,245,245)
  }
</style>