import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const cmsMyList = (current, size, params) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/cmsMyList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getCmsDetail = (id) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/cmsDetail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/submit',
    method: 'post',
    data: row
  })
}

export const cmsSave = (row) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/cmsSave',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sino-attractInvestment/attractInvestment/submit',
    method: 'post',
    data: row
  })
}

