<template>
  <div class="apply-page">
    <div class="apply-top">
      <p>
        <span class="top-label">拍卖公告：</span>
        <span class="top-font">{{ meetingDetail.bulletinName }}</span>
      </p>
      <p>
        <span class="top-label">拍卖会名称：</span>
        <span class="top-font">{{ meetingDetail.meetingName }}</span>
      </p>
      <p v-show="meetingDetail.isAllAuction == 1 && !isApply">
        <span class="top-label">本场拍卖会支持全场拍报名，可切换至全场拍报名并缴纳保证金</span>
        <span class="top-font"> <el-button type="primary" size="small" style="width: 8%;"
            @click="allClick">全场拍</el-button></span>
      </p>
      <p>
        <span class="top-label">保 证 金：</span>
        <span class="deposite-price">¥ {{ depositePrice }}</span>
      </p>
      <p>说明：保证金仅适用于该项目，竞买结束后请联系拍卖企业进行线下处理。</p>

      <p>注意： <a>如何拿回保证金？</a><a> 如何支付大额保证金？</a></p>
    </div>
    <div class="info-upload" v-if="meetingDetail.isApplyMaterials === 1">
      <p>
        <span class="top-font">{{meetingDetail.materialsName}}上传</span>
        <!-- <span class="top-label"> 图片资料</span> -->
      </p>
      <el-upload style="margin-left:50px;margin-top:5px;"
        :action="`api/sino-resource/oss/endpoint/put-file?${this.website.tokenHeader}=${this.token}`" :on-exceed="handleExceed"
        :limit="3" list-type="fileList" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传 JPG/PNG/PDF 文件，且不超过2mb.最多上传三个文件</div>
      </el-upload>
    </div>
    <div class="apply-lot" style="display: block;">
      <div class="big-title">
        <span class="top-font">请选择您要报名参拍的标的（可多选）</span>
        <span class="lot-top-right-text">
          <el-button type="text" @click='selectAllLot'>{{selectAllType?'全选':'全不选'}}</el-button>
        </span>
      </div>
      <div>
        <el-checkbox-group v-model="checkLotList" style="padding: 15px;
    display: flex;">
          <el-checkbox v-for="l in lotList" :label="l.balId" :key="l.balId"
            :disabled="l.isDisabled">{{l.lotName}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="bidder">
      <div class="bidderSection">
        <div class="hd">
          <h3>
            <p class="title yahei" style="text-align: left !important;float: none;">
              <span>1</span>
              竞买人已阅读并同意
            </p>
          </h3>
        </div>
        <div class="bd agree">
          <div class="fields">
            ● 竞买人成功竞得拍卖标的后，如果悔拍，保证金不予退还并承担相应的法律责任。
          </div>
          <div class="fields">
            ● 竞买人如未实地看样，视为接受标的所有瑕疵等细则。
          </div>
          <div class="fields">
            ● 如竞价成功，需另付软件使用费，支付标准具体见标的页面显示。
          </div>
          <!-- <div class="fields">
            ● 竞买人成功竞得拍卖标的后，如果悔拍，保证金不予退还并承担相应的法律责任。
          </div> -->
          <div class="fields">
            ● <em class="link" @click="toggleAgreement()">《齐鲁观水拍卖平台拍卖规则》</em>及<em class="link"
              @click="toggleContent">《网络拍卖平台使用协议》</em>
          </div>
          <div class="fields">
            <el-checkbox v-model="agreeOne" @change="agreeOneChange" fill="#ffffff">本人已知晓并同意以上所有事项。</el-checkbox>
            <em style="font-weight: bold;color: #d6363b;">(提示：需将齐鲁观水平台拍卖规则以及网络拍卖平台使用协议阅读完毕后才可勾选。)</em>
          </div>
          <div class="fields">
            <el-checkbox v-model="agreeTwo">标的物竞拍成功后，买受人需向中拍平台支付软件使用费，请竞拍人悉知，勾选代表同意此条款。</el-checkbox>
          </div>
          <div class="agree-content agree-content hidden" style="display: none;">
            <!-- 拍卖规则 -->
            <auctionRule></auctionRule>
          </div>
          <div class="agree-content agree-content4 hidden" style="display: none;">
            <usageAgreement></usageAgreement>
          </div>
        </div>
      </div>

      <el-dialog title="齐鲁观水平台拍卖规则" :visible.sync="agreeOneDialog" width="40%" :before-close="handleClose" class="custom-dialog">
        <dialogAuctionRule></dialogAuctionRule>
        <span slot="footer" class="dialog-footer">
          <div class="buttonCenter">
            <el-button type="primary" size="medium" @click="agreeOneClick">同意《齐鲁观水平台拍卖规则》</el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog title="网络拍卖平台使用协议" :visible.sync="agreeTwoDialog" width="40%" :before-close="handleClose" class="custom-dialog">
        <dialogUsageAgreement></dialogUsageAgreement>
        <span slot="footer" class="dialog-footer">
          <div class="buttonCenter">
            <el-button type="primary" size="medium" @click="agreeTwoClick">同意《网络拍卖平台使用协议》</el-button>
          </div>
        </span>
      </el-dialog>

      <div class="bidderSection">
        <div class="hd">
          <h3>
            <p class="title yahei">
              <span>2</span>
              竞买人身份确认
            </p>
          </h3>
        </div>
        <div class="bd">
          <el-form ref="userForm" :model="userForm" label-width="100px" size="small" disabled>
            <el-form-item label="竞买人姓名:">
              <el-input v-model="userForm.realName" :style="{ width: '300px' }"></el-input>
            </el-form-item>
            <!-- <el-form-item label="证件类型:">
              <el-input v-model="userForm.certificateName" :style="{ width: '300px' }"></el-input>
              <el-input v-model="userForm.certificateCode" :style="{ width: '300px', marginLeft: '4%'  }"></el-input>
            </el-form-item> -->
            <el-form-item label="手机号码:">
              <el-input v-model="userForm.phone" :style="{ width: '300px' }"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="bidderSection bidderCheck">
        <div class="hd">
          <h3>
            <p class="title yahei">
              <span>3</span>
              选择支付方式
            </p>
          </h3>
        </div>
        <div class="radioDiv">
          <el-radio v-model="payType" v-show="payType == 1" label="1">线上支付</el-radio>
          <el-radio v-model="payType" v-show="payType == 2" label="2">线下支付</el-radio>
          <span v-show="payType == 1">
            <el-button type="text" @click="dialogVisible = true">添加缴费信息</el-button>
            <el-form :model="bankInfo" label-width="100px" size="small" disabled>
              <el-form-item label="缴费账号:">
                <el-input v-model="meetingDetail.depositPayAccount" :style="{ width: '300px' }"></el-input>
              </el-form-item>
              <el-form-item label="缴费户名:">
                <el-input v-model="bankInfo.accountName" :style="{ width: '300px' }"></el-input>
              </el-form-item>
            </el-form>
          </span>
          <span v-show="payType == 2">
            <el-upload class="upload-demo" drag multiple :limit="1"
              :action="`api/sino-resource/oss/endpoint/put-file?${this.website.tokenHeader}=${this.token}`"
              :on-success="handleDepositeSuccess" :on-remove="depFileRemove" :before-upload="beforeUpload"
              :on-preview="uploadPreview">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">请上传缴费凭证附件</div>
            </el-upload>
          </span>
        </div>
        <div>
          <el-button type="primary" class="subButton" @click="submitApply" :disabled="buttonDisable">确认</el-button>
          <!-- <el-button v-else type="primary" class="subButton" @click="dialogVisible = true">线上支付确认</el-button> -->
        </div>
        <!-- 添加缴费信息弹窗 -->
        <el-dialog title="添加缴费信息" :visible.sync="dialogVisible" width="50%" center class="custom-dialog">
          <div class="depositPrice">
            <h3 style="font-weight: 600; color: #000;">您参与的拍卖会信息</h3>
            <p>
              <span>拍卖会名称：</span>
              <span>{{ meetingDetail.meetingName }}</span>
            </p>
            <p>
              <span>拍卖时间：</span>
              <span>{{ meetingDetail.startAuctionTime }}</span>
            </p>
            <p v-show="applyWay === 1">
              <span>全场拍保证金：</span>
              <span>{{ meetingDetail.allDepositPrice }}</span>
            </p>
          </div>
          <div v-for="item in depositList" :key="item.balId" class="depositPrice">
            <h3 style="font-weight: 600; color: #000;">您的缴费信息</h3>
            <p>
              <span>标的名称：</span>
              <span>{{ item.lotName }}</span>
            </p>
            <p v-show="applyWay === 0">
              <span>保证金金额：</span>
              <span>{{ item.depositePrice }}元</span>
            </p>
          </div>
          <div class="custom-button-wrapper">
            <el-button type="primary" size="small" @click="getDepositeRecords">一键获取缴费记录</el-button>
          </div>
          <!-- 表格 -->
          <div>
            <el-table :data="tableData" height="250" border style="width: 100%" size="small">
              <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
              <el-table-column prop="payAccount" label="付款人账号" align="center"></el-table-column>
              <el-table-column prop="genterName" label="收款银行名称" align="center"></el-table-column>
              <el-table-column prop="genterAccount" label="收款账号" align="center"></el-table-column>
              <el-table-column prop="payAmount" label="到账金额" align="center"></el-table-column>
              <el-table-column prop="accountTime" label="到账时间" align="center"></el-table-column>
            </el-table>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="PDFdialogVisible"
      fullscreen
      append-to-body>
      <iframe :src="'/pdf/web/viewer.html?file='+ url" width="100%" height="850px" type="application/pdf"></iframe>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="IMGdialogVisible"
      :modal-append-to-body="false"
      center
      append-to-body>
      <div v-if="IMGdialogVisible">
          <el-image :src="url"></el-image>
      </div>
      </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/util/auth";
import { queryApplyLots, toApply, getDepositeRecord, cmsAllApply } from '@/api/biz/auction/lotApply'
import { cmsDetail } from '@/api/biz/auction/auctionMeeting'
import { getUserAndCompany } from '@/api/system/user'
import auctionRule from '@/components/agreement/auctionRule.vue'
import usageAgreement from '@/components/agreement/usageAgreement.vue'
import dialogAuctionRule from '@/components/agreement/dialogAuctionRule.vue'
import dialogUsageAgreement from '@/components/agreement/dialogUsageAgreement.vue'
import {getDetailByCreateUser} from '@/api/biz/bankInfo'

export default {
  name: "ToApplyView",
  components: {
    auctionRule,
    usageAgreement,
    dialogAuctionRule,
    dialogUsageAgreement
  },
  data() {
    return {
      dialogStyle1: {
        backgroundColor: '#fff',
      },
      lotList: [],
      checkLotList: [],
      // 线上/线下支付
      payType: '',
      applyFileList: [],
      selectAllType: true,
      meetingDetail: {},
      bankInfo: {},
      depositePrice: '',
      userForm: {},
      dialogVisible: false,
      //  弹窗展示标的数组
      depositList: [],
      tableData: [],
      // 线下保证金支付凭证
      depositeFile: {},
      // 是否已报名，控制全场拍按钮
      isApply: false,
      // 全场拍1/单标的0
      applyWay: 0,
      // 平台拍卖规则选择框
      agreeOne: false,
      // 网络拍卖平台使用协议选择框
      agreeTwo: false,
      // 平台拍卖规则弹窗
      agreeOneDialog: false,
      // 网络拍卖平台使用协议弹窗
      agreeTwoDialog: false,
      // 确认按钮
      buttonDisable: true,
      // pdf预览弹窗
      PDFdialogVisible: false,
      IMGdialogVisible: false,
      // 地址
      url: '',
    }
  },
  computed: {
    token() {
      return getToken();
    },
    lotId() {
      return this.$route.query.lotId;
    },
    meetingId() {
      return this.$route.query.meetingId
    },
  },
  watch: {
    checkLotList(val) {
      this.depositePrice = this.calculateTotalDeposit(val);
    },
    'agreeOne'(val) {
      if (val && this.agreeTwo) {
        this.buttonDisable = false;
      } else {
        this.buttonDisable = true;
      }
    },
    'agreeTwo'(val) {
      if (val && this.agreeOne) {
        this.buttonDisable = false;
      } else {
        this.buttonDisable = true;
      }
    }
  },
  methods: {
    handleExceed() {
      this.$message.warning(`当前限制选择 3 个文件`);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'|| file.type === 'image/png' || file.type === 'application/pdf';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG/PNG/PDF 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传附件大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    uploadPreview(file){
      let fileName = file.name;
      let index = fileName.lastIndexOf('.');
      let fileExten = fileName.substring(index + 1).toLowerCase();
      if(fileExten == 'pdf'){
        this.PDFdialogVisible = true;
        this.url = file.response.data.link;
      } else {
        this.IMGdialogVisible = true;
        this.url = file.response.data.link;
      }
    },
    // 弹窗叉号关闭
    handleClose(done) {
      this.agreeOneDialog = false;
      this.agreeOne = false;
      done();
    },
    agreeOneClick() {
      // this.agreeOneDialog = false;
      this.agreeTwoDialog = true;
    },
    agreeTwoClick() {
      this.agreeOneDialog = false;
      this.agreeTwoDialog = false;
    },
    // 勾选框1
    agreeOneChange(val) {
      if (val) {
        this.agreeOneDialog = true;
      }
    },
    getDepositeRecords() {
      let params = {
        meetingId: this.meetingId,
        applyWay: this.applyWay,
      }
      getDepositeRecord(params).then((res) => {
        const data = res.data.data;
        this.tableData = [];
        if (res.data.success && data.length !== 0) {
          data.forEach((item, index) => {
            // 格式化到账时间 
            let tempTime = (item.trxdat ? (item.trxdat.substring(0, 4) + "-" + item.trxdat.substring(4, 6) + "-" + item.trxdat.substring(6, 8)) : new Date().toLocaleDateString().replaceAll('/', '-'))
              + " " + item.trxtim.substring(0, 2) + ":" + item.trxtim.substring(2, 4) + ":" + item.trxtim.substring(4, 6);
            let tableItem = {
              index: index,
              personCoName: item.acName,
              payAccount: item.rpyacc,
              genterName: item.rpynam,
              genterAccount: (item.inbacc + item.dyanbr),
              payAmount: item.trxamt,
              accountTime: tempTime,
              busino: item.trxnbr,
              trxset: item.trxset
            }
            this.tableData.push(tableItem);
          })
          this.$message.success('已获取到缴费记录，请核实无误后提交审核')
        } else {
          this.$message.warning('未查询到缴费记录')
        }
      })
    },
    // 全场拍按钮单击
    allClick() {
      // 控制报名参拍标的
      var applyLot = document.querySelector('.apply-lot');
      if (applyLot.style.display !== 'none') {
        applyLot.style.display = 'none';
        // 改变保证金金额为全场拍金额
        this.depositePrice = this.meetingDetail.allDepositPrice;
        this.applyWay = 1;
        this.depositList = this.lotList;
      } else {
        applyLot.style.display = 'block';
        this.depositePrice = this.calculateTotalDeposit(this.checkLotList);
        this.applyWay = 0;
      }
    },
    // 保证金凭证限制文件类型
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isPDF = file.type === 'application/pdf';

      if (!isJPG && !isPDF) {
        this.$message.error('只能上传jpg、png或pdf格式的文件');
        return false; // 阻止上传
      }
      return true; // 允许上传
    },
    // 保证金凭证删除
    depFileRemove() {
      this.depositeFile = null;
    },
    // 保证金凭证上传
    handleDepositeSuccess(res) {
      this.depositeFile = res.data
    },
    toggleAgreement() {
      var agreementContent = document.querySelector('.agree-content');
      var agreementContent4 = document.querySelector('.agree-content4');
      if (agreementContent.style.display !== 'none') {
        agreementContent.style.display = 'none';
      } else {
        agreementContent.style.display = 'block';
        agreementContent4.style.display = 'none';
      }
    },
    toggleContent() {
      var agreementContent4 = document.querySelector('.agree-content4');
      var agreementContent = document.querySelector('.agree-content');
      if (agreementContent4.style.display !== 'none') {
        agreementContent4.style.display = 'none';
      } else {
        agreementContent4.style.display = 'block';
        agreementContent.style.display = 'none';
      }
    },
    // 获取竞买人信息
    getUser() {
      getUserAndCompany().then((res) => {
        this.userForm = res.data.data;
      })
    },
    calculateTotalDeposit(lotIds) {
      // 计算保证金总金额
      let totalDeposit = 0;
      this.depositList = [];
      lotIds.forEach((lotId) => {
        const selectedLot = this.lotList.find((ele) => (ele.balId === lotId && !ele.isDisabled));
        if (selectedLot) {
          // 将字符串形式的保证金金额转换为数字并累加
          totalDeposit += parseFloat(selectedLot.depositePrice);
          this.depositList.push(selectedLot)
        }
      });
      totalDeposit = totalDeposit.toFixed(2);
      return totalDeposit;
    },
    // 获取拍卖会信息，用于保证金支付
    getMeeting() {
      cmsDetail(this.meetingId).then((res) => {
        this.meetingDetail = res.data.data;
        this.payType = this.meetingDetail.depositPayWay.toString();
        if(this.payType==1){
          getDetailByCreateUser(this.meetingDetail.createUser).then(resp=>{
            this.bankInfo = resp.data.data;
          })
        }        
      })
    },
    selectAllLot() {
      this.checkLotList = []
      this.lotList.forEach(ele => {
        if (ele.bidderId) {
          this.checkLotList.push(ele.balId);
        } else if (this.selectAllType) {
          this.checkLotList.push(ele.balId);
        }
      })
      this.selectAllType = !this.selectAllType;
    },
    handleAvatarSuccess(res) {
      this.applyFileList.push(res.data)
    },
    submitApply() {
      // 未认证通过不让报名
      if (this.userForm.isAuth != 2) {
        return this.$message('请通过实名认证后在进行报名')
      }
      // 如果需要上传报名材料
      if (this.meetingDetail.isApplyMaterials === 1 && this.applyFileList.length === 0) {
        return this.$message('请上传您的报名材料')
      }
      // 线下支付校验
      if (this.payType == 2) {
        if (this.depositeFile == null || Object.keys(this.depositeFile).length === 0) {
          return this.$message('请上传您的保证金缴费凭证')
        }
      }
      // 线上支付校验
      if (this.payType == 1 && this.tableData.length == 0) {
        return this.$message('请获取您的保证金缴费记录')
      }
      // 报名方式，单标的
      if (this.applyWay === 0) {
        let applyCount = 0;
        let firstApplyId = null;
        this.lotList.forEach(ele => {
          if (!ele.isDisabled && this.checkLotList.includes(ele.balId)) {
            if (!firstApplyId) firstApplyId = ele.balId;
            applyCount++;
          }
        })
        if (applyCount == 0) {
          return this.$message('请选择您要参加的标的！')
        }
        let params = {
          meetingId: this.meetingId,
          payType: this.payType,
          checkLotList: this.checkLotList,
          applyFileList: this.applyFileList,
          applyWay: this.applyWay,
          depositeFile: this.depositeFile,
          payInfoList: this.tableData,
        };
        toApply(params).then(res => {
          if (res.data.success) {
            let result = '' + res.data.data;
            if (result != null && result.indexOf('标的') != -1) {
              return this.$message({ type: 'error', message: result });
            } else {
              this.$message({ type: 'success', message: '报名成功' });
              firstApplyId = this.checkLotList.includes(this.lotId) ? this.lotId : firstApplyId;
              this.$router.push({ path: "/lotDetail", query: { lotId: firstApplyId } })
            }
          }
        })
      } else {
        // 全场拍
        let params = {
          meetingId: this.meetingId,
          payType: this.payType,
          applyWay: this.applyWay,
          depositeFile: this.depositeFile,
          payInfoList: this.tableData,
          applyFileList: this.applyFileList,
        }
        cmsAllApply(params).then((res) => {
          if (res.data.success) {
            let result = '' + res.data.data;
            if (result != null && result.indexOf('标的') != -1) {
              return this.$message({ type: 'error', message: result });
            } else {
              this.$message({ type: 'success', message: '报名成功' });
              this.$router.push({ path: "/lotDetail", query: { lotId: this.lotList[0].balId } })
            }
          }
        })
      }

    },
    getLotsList() {
      this.checkLotList.push(this.lotId);
      queryApplyLots({ meetingId: this.meetingId }).then(res => {
        this.lotList = res.data.data;
        this.lotList.forEach(ele => {
          ele = this.getIsDisabled(ele);
          if (ele.balId == this.lotId) {
            this.depositePrice = ele.depositePrice;
            this.depositList.push(ele);
          }
          if (ele.bidderId) {
            this.checkLotList.push(ele.balId);
          }
        })
      });
    },
    getIsDisabled(data) {
      let nowTime = new Date().getTime;
      if (data.applyEndTime) {
        // 根据截止报名时间，判断是否禁用
        let endTime = new Date(data.applyEndTime);
        data.isDisabled = endTime.getTime() <= nowTime;
      }
      if (data.bidderId) {
        // 如果已经报名则禁用
        data.isDisabled = true;
        this.isApply = true;
      }
      //根据标的成交状态判断是否需要报名
      return data;
    }
  },
  created() {
    this.getLotsList();
    this.getMeeting();
    this.getUser();
  }
}
</script>

<style scoped>
.lot-top-right-text {
  float: right;
  margin-right: 20px;
}
.big-title {
  line-height: 45px;
  width: 100%;
  height: 45px;
  background-color: rgb(235, 236, 240);
}
.el-upload--picture-card .el-upload-list__item {
  height: 124px !important;
  line-height: 123px !important;
}
.top-label {
  font-size: 15px;
  color: #666;
  line-height: 35px;
}
.deposite-price {
  margin-left: 20px;
  color: #d6363b;
  font-size: 24px;
}
.top-font {
  margin-right: 5px;
  font-size: 18px;
  color: #000;
  margin-left: 12px;
}
.apply-page {
  color: rgb(48, 21, 21);
  line-height: 30px;
}
.info-upload {
  padding-left: 5px;
  padding-top: 10px;
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
  height: 200px;
}
.bidderSection {
  /* padding-left: 20px;
    padding-top: 20px;
    margin-top: 20px;
    height: 800px; */
  margin: 0 40px;
  padding: 30px 0;
  border-bottom: 1px solid #ddd;
}
.apply-lot {
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
}
.apply-top {
  padding: 20px;
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
  /* height: 210px; */
}
.hd {
  margin-bottom: 5px;
}
.hd,
.hd h3 {
  height: 30px;
  line-height: 30px;
}
.hd h3 .title {
  font-size: 16px;
  font-weight: 600;
  height: 16px;
  color: black;
  text-align: left;
}
.yahei {
  margin-left: 8px;
}
.hd h3 .title span {
  background: #a3a3a3;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  font-weight: 400;
  margin-right: 10px;
}
.bidder {
  margin-top: 20px;
  padding-bottom: 30px;
  background: #fff;
}
.radioDiv {
  margin-left: 5%;
}
.subButton {
  margin-left: 4%;
  width: 10%;
  margin-top: 2%;
}
.bidder .agree .fields {
  font-weight: 400;
  font-size: 14px;
  margin-top: 14px;
  height: 22px;
  line-height: 22px;
}
.link {
  color: #2196f3;
  cursor: pointer;
  text-decoration: none;
}
.bidder .agree-content,
.disabled,
.fields select,
.input {
  border: 1px solid #dedede;
}
.bidder .agree-content {
  display: none;
  padding: 20px;
  margin-top: 10px;
}
.bidder .agree-detail {
  color: #999;
  height: 200px;
  line-height: 20px;
  overflow-y: scroll;
  padding-right: 11px;
}
.bidder .agree-detail p {
  color: #666;
  text-indent: 24px;
}
.depositPrice {
  background-color: rgba(229, 229, 229, 1);
  margin-bottom: 2%;
  margin-top: 2%;
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left: 4%;
}
.custom-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.bidderCheck {
  border-bottom: none;
}
::v-deep .custom-dialog .el-dialog__header {
  background-color: #2196f3;
  padding: 16px 16px 10px;
}
::v-deep .custom-dialog .el-dialog__title {
  color: #fff;
  font-size: 16px;
}
::v-deep .custom-dialog .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
.buttonCenter {
  display: flex;
  justify-content: center;
}
.el-checkbox .el-checkbox__label.is-checked {
  color: inherit !important;
}
</style>