import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sino-company/company/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsCompanyList = () => {
  return request({
    url: '/api/sino-company/company/cmsCompanyList',
    method: 'get',
  })
}

export const getDetail = () => {
  return request({
    url: '/api/sino-company/company/detail',
    method: 'get',
    params: {
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sino-company/company/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sino-company/company/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sino-company/company/submit',
    method: 'post',
    data: row
  })
}

export const getDetailById = (companyId,userId) => {
  return request({
    url: '/api/sino-company/company/detailById',
    method: 'get',
    params: {
      companyId: companyId,
      userId: userId
    }
  })
}