/**
 * 审核状态枚举类
 */
export default {
    NOT_SUBMIT: {
      name: '未提交',
      value: 0
    },
    SUBMIT: {
      name: '已提交',
      value: 10
    },
    SUCCESS: {
      name: '已通过',
      value: 20
    },
    FAIL: {
      name: '未通过',
      value: 99
    }
  }
  