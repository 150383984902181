import Vue from 'vue'
import App from './App.vue'
// 引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入路由
import router from '@/router/index'
import store from './store';
import website from '@/config/website';
// 引入竞品样式
// import './styles/index-a0afe05738.css';
// import './styles/swiper-bundle-1e3d195150.min.css';


Vue.use(ElementUI);
// 加载website
Vue.prototype.website = website;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
