//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
// 引入路由组件
import Home from '@/pages/Home'
import HelloWorld from '@/components/HelloWorld'
import Login from '@/pages/Login'
import Register from '@/pages/Register'
import findPassword from '@/pages/findPassword/findPassword'
import Main from '@/components/Main'
import EnterprisesList from '@/components/enterprises/list'
import LotsList from '@/components/lots/list'
import MeettingsList from '@/components/meetings/list'
import MeetingDetail from '@/components/meetings/detail'
import NoticeList from '@/components/notices/list'
import BulletinList from '@/components/bulletin/list'
import ColumnBulletinList from '@/components/columnBulletin/index'
import ColumnBulletinDetail from '@/components/columnBulletin/detail'
import AttractList from '@/components/attractInvestment/list'
import AttractDetail from '@/components/attractInvestment/detail'
import AuctionEnterprise from '@/components/auctionEnterprise/list'
import AuctionEnterpriseDetail from '@/components/auctionEnterprise/detail'
import AttractManager from '@/components/attractInvestment/manager'
import TopList from '@/components/top/list'
import lotDetail from '@/components/lots/detail'
import noticeDetail from '@/components/notices/detail'
import BulletinDetail from '@/components/bulletin/detail'
import PersonalCenter from '@/pages/PersonalCenter'
import accountSecurity from '@/components/personal/accountSecurity'
import myBid from '@/components/personal/myBid'
import ToApply from '@/components/apply/ToApply'
import authForm from '@/components/personal/authForm'
import accountShow from '@/components/personal/accountShow'
import accountEdit from '@/components/personal/accountEdit'
import Commission from '@/components/personal/commission'
import OrderInvoice from '@/components/personal/invoice/orderInvoice'
import InvoiceHistory from '@/components/personal/invoice/invoiceHistory'
import PlatformPrice from '@/components/personal/platformPrice'
import Deposit from '@/components/personal/deposit'
import HelpIndex from '@/components/help/index'

//2.使用路由
Vue.use(VueRouter);
// 把VueRouter原型对象push，保存一份
let originPush = VueRouter.prototype.push
let originReplace=VueRouter.prototype.replace
 
// 重写push|replace
// 第一个参数：告诉原来的push方法，往哪里跳转（传递哪些参数）
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => { }, () => { })
    }
}
VueRouter.prototype.replace=function(location,resolve,reject){
    if(resolve&&reject){
        originReplace.call(this,location,resolve,reject)
    }else{
        originReplace.call(this,location,()=>{},()=>{})
    }
}
 
//3.创建VueRouter的实例
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    mode: 'history',
    //4.配置路由的path和组件
    routes: [
        {path: "/home",component: Home,meta: { show: true },children:[
            {path: "/index",component: Main,meta: { show: false }},
            {path: "/enterprises",component: EnterprisesList,meta: { show: false }},
            {path: "/lots",component: LotsList,meta: { show: false }},
            {path: "/meeting",component: MeettingsList,meta: { show: false }},
            {path: "/meetingDetial",component: MeetingDetail,meta: { show: false }},
            {path: "/notice",component: NoticeList,meta: { show: false }},
            {path: "/bulletin",component: BulletinList,meta: { show: false }},
            {path: "/columnBulletin",component: ColumnBulletinList,meta: { show: false }},
            {path: "/columnBulletinDetail",component: ColumnBulletinDetail,meta: { show: false }},
            {path: "/companyList",component: AuctionEnterprise,meta: { show: false }},
            {path: "/companyDetail",component: AuctionEnterpriseDetail,meta: { show: false }},
            {path: "/attractList",component: AttractList,meta: { show: false }},
            {path: "/attractDetail",component: AttractDetail,meta: { show: false }},
            {path: "/topSearch",component: TopList,meta: { show: false }},
            {path: "/lotDetail",component: lotDetail,meta: { show: false }},
            {path: "/noticeDetail",component: noticeDetail,meta: { show: false }},
            {path: "/bulletinDetail",component: BulletinDetail,meta: { show: false }},
            {path: "/toApply",component: ToApply,meta: { show: false }},
            {path: "/help",component: HelpIndex,meta: { show: false }},
            {path: "/personalCenter",component: PersonalCenter,meta: { show: true }, children:[
                {path: "/personalCenter/accountSecurity",component: accountSecurity, meta:{ show:true},children:[
                    {path: '/personalCenter/accountSecurity/authForm',component: authForm, meta:{show: true}},
                    {path: '/personalCenter/accountSecurity/accountShow',component: accountShow, meta:{show: true}},
                    {path: '/personalCenter/accountSecurity/accountEdit',component: accountEdit, meta:{show: true}}
                ]},
                {path: "/personalCenter/myBid", component: myBid, meta:{show: true}},
                {path: "/personalCenter/attractManager", component: AttractManager, meta:{show: true}},
                {path: "/personalCenter/commission", component: Commission, meta:{show: true}},
                {path: "/personalCenter/orderInvoice", component: OrderInvoice, meta:{show: true}},
                {path: "/personalCenter/invoiceHistory", component: InvoiceHistory, meta:{show: true}},
                {path: "/personalCenter/platformPrice", component: PlatformPrice, meta:{show: true}},
                {path: "/personalCenter/deposit", component: Deposit, meta:{show: true}},
            ]},
        ]},
        {path: "/login",component: Login,meta: { show: false }},
        {path: "/helloWorld",component: HelloWorld,meta: { show: false }},
        
        {path: "/register",component: Register,meta: { show: false }},
        {path: "/findPassword",component: findPassword,meta: { show: false }},
        // 重定向,在项目跑起来时，访问/，定向到/home
        {path: '*',redirect: "/index"}
    ]
})
//5.导入路由实例
export default router