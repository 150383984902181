<template>
  <div class="home-div">
    <div class="home-head-position">
      <img class="home-logo" src="../../../public/img/logo.png" alt="">
      <img class="home-qlgs" src="../../../public/img/qlgsdzpmpt.png" alt="">
      <img class="home-jzjdsbgy" src="../../../public/img/jzjdsbgy.png" alt="">
      <div class="top-welcome">
        <span>欢迎登录</span>
      </div>
      <div class="top-button cursor-pointer-div" @click="backHome">
        <img class="button-img" src="../../../public/img/backhome.png" alt="">
        <span class="button-span">返回首页</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loginHeadView',
  methods: {
    backHome() {
      this.$router.push("/");
    }
  }
}
</script>

<style scoped>
.cursor-pointer-div:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
}
.button-img {
  margin-left: 16px;
  margin-top: 11px;
  width: 17.5px;
  height: 17.47px;
  opacity: 1;
}
.button-span {
  display: inline-block;
  position: relative;
  margin-left: 5px;
  margin-top: 8px;
  width: 64px;
  height: 24px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.top-button {
  position: relative;
  left: 76%;
  top: -90px;
  width: 120px;
  height: 40px;
  opacity: 1;
  border-radius: 6px;
  background: linear-gradient(
    135deg,
    rgba(0, 102, 166, 1) 0%,
    rgba(59, 133, 243, 1) 100%
  );
}
.top-welcome {
  position: relative;
  padding-left: 26px;
  left: 840px;
  top: -53px;
  width: 80px;
  height: 40px;
  opacity: 1;
  /** 文本1 */
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 40px;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  vertical-align: top;
  border-left: solid 3px rgba(102, 102, 102, 1);

  /* width:500px;
    height:20px" */
}
.home-jzjdsbgy {
  z-index: 0;
  position: relative;
  top: 59px;
  width: 211px;
  height: 79px;
  opacity: 1;
}
.home-qlgs {
  z-index: 1;
  position: relative;
  left: 311px;
  top: 6px;
  width: 400px;
  height: 40px;
  opacity: 1;
}
.home-logo {
  position: relative;
  left: 311px;
  top: 12px;
  width: 100px;
  height: 95.77px;
  opacity: 1;
}
.home-div {
  z-index: 0;
  background-color: rgb(255, 255, 255);
  /* border-bottom: solid 3px rgba(102, 102, 102, 1); */
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.35);
}
.home-head-position {
  /* position: relative; */
  height: 120px;
  z-index: -1;
}
</style>