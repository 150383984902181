<template>
  <div>
    <div class="company-tabs-div">
      <span :class="tabType==1?'tab-checked':''" class="company-tab-item"
        @click="changeTab(1)">拍卖会列表({{meetingData!=null?meetingPage.total:0}})</span>
      <span :class="tabType==2?'tab-checked':''" class="company-tab-item"
        @click="changeTab(2)">拍卖会公告({{bulletinData!=null?bulletinPage.total:0}})</span>
      <span :class="tabType==3?'tab-checked':''" class="company-tab-item"
        @click="changeTab(3)">累计标的({{bidCount}})</span>
      <span :class="tabType==4?'tab-checked':''" class="company-tab-item"
        @click="changeTab(4)">累计成交({{winCount}})</span>
    </div>
    <div class="tab-detail-div">
      <template v-if="tabType==1">
        <MeetingDiv style="width:1300px" :meetingData='meetingData' :showHall="true"></MeetingDiv>
        <div class="attract-page-class">
          <div class="attract-el-page-class">
            <el-pagination @size-change="meetingHandleSizeChange" @current-change="meetingHandleCurrentChange"
              :current-page="meetingPage.currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="meetingPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="meetingPage.total">
            </el-pagination>
          </div>
        </div>
      </template>
      <template v-if="tabType==2">
        <div class="attract-bulletin-item-div" v-for="b in bulletinData" :key="b.id">
          <img class="bulltein-left-item-img" :src="b.bulletinImg[0].link">
          <div class="bulletin-right-item-div">
            <div class="bulletin-item-title-div">{{b.title}}</div>
            <div class="bulletin-item-content-div">{{b.filterContent}}</div>
            <div class="bulletin-item-bottom-div">
              <span class="bulletin-item-company-span">{{b.companyName}}</span>
              <span class="bulletin-item-time-span">{{b.auctionTime}}</span>
            </div>
          </div>
        </div>
        <div class="bulletin-page-class">
          <div class="el-page-class">
            <el-pagination @size-change="bulletinHandleSizeChange" @current-change="bulletinHandleCurrentChange"
              :current-page="bulletinPage.currentPage" :page-sizes="[8, 16]" :page-size="bulletinPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="bulletinPage.total">
            </el-pagination>
          </div>
        </div>
      </template>
      <template v-if="tabType==3">
        <LotDiv :lotList='lotList' :meetingData="auctionMeeting"></LotDiv>
        <div class="attract-page-class">
          <div class="attract-el-page-class">
            <el-pagination @size-change="lotHandleSizeChange" @current-change="lotHandleCurrentChange"
              :current-page="lotPage.currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="lotPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="lotPage.total">
            </el-pagination>
          </div>
        </div>
      </template>
      <template v-if="tabType==4">
        <LotDiv :lotList='winList' :meetingData="auctionMeeting"></LotDiv>
        <div class="attract-page-class">
          <div class="attract-el-page-class">
            <el-pagination @size-change="winHandleSizeChange" @current-change="winHandleCurrentChange"
              :current-page="winPage.currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="winPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="winPage.total">
            </el-pagination>
          </div>
        </div>
      </template>
    </div>
    <div style="height:20px"></div>
  </div>
</template>

<script>
import MeetingDiv from "../meetings/MeetingDiv.vue";
import LotDiv from '../lots/LotDiv.vue'
import { getCmsMeeting } from "@/api/biz/auction/auctionMeeting";
import { getBizDictByCode } from '@/api/system/dict'
import { getCmsCompanyLotList } from '@/api/biz/auction/auctionLot'
import { getCmsList } from '@/api/biz/auction/auctionBulletin'
export default {
  name: 'CompanyTabView',
  props: ['companyId'],
  components: { MeetingDiv, LotDiv },
  data() {
    return {
      tabType: 1,
      bidCount: 0,
      winCount: 0,
      meetingData: [],
      bulletinTypeDict: [],
      meetingLoading: false,
      meetingPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      lotPage: {
        pageSize: 8,
        currentPage: 1,
        total: 0
      },
      winPage: {
        pageSize: 8,
        currentPage: 1,
        total: 0
      },
      bulletinPage: {
        pageSize: 8,
        currentPage: 1,
        total: 0
      },
      auctionMeeting: {},
      lotList: [],
      winList: [],
      bulletinData: [],
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.getBulletinType();
      this.queryMeetingData();
      await this.queryLotData();
      await this.queryWinLotData();
      this.queryBulletinData();
    },
    bulletinHandleSizeChange(val) {
      this.bulletinPage.pageSize = val;
      this.queryBulletinData();
    },
    bulletinHandleCurrentChange(val) {
      this.bulletinPage.currentPage = val;
      this.queryBulletinData();
    },
    queryBulletinData() {
      let page = this.bulletinPage;
      getCmsList(page.currentPage, page.pageSize, { companyId: this.companyId }).then(res => {
        const data = res.data.data;
        this.bulletinPage.total = data.total;
        this.bulletinData = data.records;
        for (let i = 0; i < this.bulletinData.length; i++) {
          this.bulletinTypeDict.forEach(b => { if (b.dictKey == this.bulletinData[i].type) this.bulletinData[i].typeName = b.dictValue })
        }
      });
    },
    winHandleSizeChange(val) {
      this.winPage.pageSize = val;
      this.queryWinLotData();
    },
    winHandleCurrentChange(val) {
      this.winPage.currentPage = val;
      this.queryWinLotData();
    },
    queryWinLotData() {
      getCmsCompanyLotList(this.winPage.currentPage, this.winPage.pageSize, { id: this.companyId, auctionStatus: 3 }).then(res => {
        const data = res.data.data;
        this.winList = data.records;
        this.auctionMeeting = this.winList[0].auctionMeeting
        this.winPage.total = data.total;
        this.winList.forEach(e => { this.meetingViewCount = this.meetingViewCount + e.viewCount });
        this.$nextTick(() => { this.winCount = (this.winList && this.winList.length > 0) ? this.winPage.total : 0; })
      })
    },
    lotHandleSizeChange(val) {
      this.lotPage.pageSize = val;
      this.queryLotData();
    },
    lotHandleCurrentChange(val) {
      this.lotPage.currentPage = val;
      this.queryLotData();
    },
    queryLotData() {
      getCmsCompanyLotList(this.lotPage.currentPage, this.lotPage.pageSize, { id: this.companyId }).then(res => {
        const data = res.data.data;
        this.lotList = data.records;
        this.auctionMeeting = this.lotList[0].auctionMeeting
        this.lotPage.total = data.total;
        this.lotList.forEach(e => { this.meetingViewCount = this.meetingViewCount + e.viewCount });
        this.$nextTick(() => { this.bidCount = (this.lotList && this.lotList.length > 0) ? this.lotPage.total : 0; })
      })
    },
    meetingHandleSizeChange(val) {
      this.meetingPage.pageSize = val;
      this.queryMeetingData();
    },
    meetingHandleCurrentChange(val) {
      this.meetingPage.currentPage = val;
      this.queryMeetingData();
    },
    queryMeetingData() {
      this.meetingLoading = true;
      getCmsMeeting(this.meetingPage.currentPage, this.meetingPage.pageSize, { companyId: this.companyId }).then(res => {
        const data = res.data.data;
        this.meetingPage.total = data.total;
        this.meetingData = data.records;
        for (let i = 0; i < this.meetingData.length; i++) {
          let d = this.meetingData[i];
          d.bulletinType = this.formatBulletinType(d.bulletin.type);
        }
        this.meetingLoading = false;
      });
    },
    formatBulletinType(type) {
      let value = '';
      this.bulletinTypeDict.forEach(t => { if (t.dictKey == type) { value = t.dictValue; } })
      return value;
    },
    getBulletinType() {
      getBizDictByCode('bulletin_type').then(res => {
        this.bulletinTypeDict = res.data.data;
      });
    },
    changeTab(type) {
      this.tabType = type;
    },
  }
}
</script>

<style scpoed>
.bulletin-item-time-span {
  width: 62px;
  height: 18px;
  opacity: 1;
  /** 文本1 */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  vertical-align: top;
  overflow: hidden;
}
.bulletin-item-company-span {
  display: inline-block;
  width: 260px;
  height: 21px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}
.bulletin-item-bottom-div {
  width: 335px;
  margin-top: 10px;
}
.bulletin-item-content-div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  margin-top: 10px;
  width: 335px;
  height: 70px;
  opacity: 1;
  /** 文本1 */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(102, 102, 102, 1);
}
.bulletin-item-title-div {
  width: 335px;
  height: 27px;
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}
.bulletin-right-item-div {
  margin-left: 20px;
  margin-top: 20px;
  display: inline-block;
  width: 330px;
  vertical-align: top;
  overflow: hidden;
  max-height: 140px;
}
.bulltein-left-item-img {
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  width: 240px;
  height: 140px;
  opacity: 1;
  border-radius: 2px;
  vertical-align: top;
}
.attract-bulletin-item-div {
  display: inline-block;
  width: 635px;
  height: 180px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  margin-right: 30px;
  margin-bottom: 20px;
  vertical-align: top;
}
.bulletin-page-class {
  /* width: 1300px; */
}
.attract-el-page-class {
  margin-right: 45px;
  float: right;
  padding-top: 45px;
  height: 50px;
  line-height: 50px;
}
.attract-page-class {
  margin-top: 20px;
  padding-bottom: 18px;
  width: 1300px;
  height: 100px;
  background-color: rgb(255, 255, 255);
}
.tab-detail-div {
  margin-top: 20px;
  width: 1330px;
}
.tab-checked {
  color: rgba(0, 102, 166, 1) !important;
  cursor: auto !important;
  border-bottom: 1.5px solid rgba(0, 102, 166, 1);
}
.company-tab-item:hover {
  cursor: pointer;
}
.company-tab-item {
  margin-top: 13px;
  margin-left: 30px;
  display: inline-block;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}
.company-tabs-div {
  margin-top: 20px;
  width: 1300px;
  height: 50px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
</style>