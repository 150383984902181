<template>
  <div>
    <el-tabs type="border-card" :stretch='true' class="lot-info-div" @tab-click="tabClick">
      <!-- <el-tab-pane label="重要提示">{{outData.importantNote}}</el-tab-pane> -->
      <el-tab-pane label="重要提示">
        <div v-html="outData.importantNote" class="contyern">
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="拍卖公告">{{bulletinData.content}}</el-tab-pane> -->
      <el-tab-pane label="拍卖公告">
        <div v-html="bulletinData.content" class="contyern"></div>
      </el-tab-pane>
      <!-- <el-tab-pane label="竞买须知">{{bulletinData.notice}}</el-tab-pane> -->
      <el-tab-pane label="竞买须知">
        <div v-html="bulletinData.notice" class="contyern"></div>
      </el-tab-pane>
      <!-- <el-tab-pane label="标的介绍">{{outData.lotIntro}}</el-tab-pane> -->
      <el-tab-pane label="标的介绍">
        <div v-html="outData.lotIntro" class="contyern"></div>
      </el-tab-pane>
      <el-tab-pane label="竞价记录">
        <template>
          <el-table :data="priceDataList" style="width: 100%" :cell-style='styleCenter'
            :header-cell-style='styleCenter'>
            <el-table-column prop="priceState" label="状态" align="center">
              <template slot-scope="scope">
                {{(scope.row.quoted==outData.currentQuoted&&scope.row.priceState==1)?
                (outData.auctionStatus==3?'成交':'领先')
                :(scope.row.priceState==-1?'回退':'出局')}}
              </template>
            </el-table-column>
            <el-table-column prop="bidCode" label="竞买号" align="center"></el-table-column>
            <el-table-column prop="quoted" label="价格" align="center"></el-table-column>
            <el-table-column prop="createTime" label="时间" align="center"></el-table-column>
          </el-table>
          <el-pagination align="right" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            style="margin-top: 10px;" :current-page.sync="page.currentPage" :page-size="page.pageSize"
            layout="total, prev, pager, next" :total="page.total">
          </el-pagination>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getDetail } from '@/api/biz/auction/auctionBulletin';
import { priceList } from '@/api/biz/auction/lotQuoted';
export default {
  props: ['meetingData', 'outData'],
  data() {
    return {
      styleCenter: { 'text-align': 'center' },
      bulletinData: {},
      priceDataList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
    }
  },
  created() {
    setTimeout(() => { this.getBulletinById() }, 1000)
    this.data = `<input type="text" nameFlag="usageScenario" class="msgTeleScreen"
              v-model="usageScenario" placeholder="请输入场景">`
  },
  methods: {
    tabClick(tab) {
      if (tab.index == 4) {
        this.queryPirceList();
      }
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.queryPirceList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.queryPirceList();
    },
    getBulletinById() {
      let bulletinTimer = setInterval(()=>{
        if(this.meetingData.bulletinId){
          getDetail(this.meetingData.bulletinId).then(res => {
            this.bulletinData = res.data.data;
          })
          clearInterval(bulletinTimer);
        }
      },3000)      
    },
    queryPirceList() {
      let page = this.page;
      priceList(page.currentPage, page.pageSize, { lotId: this.outData.id }).then(res => {
        this.priceDataList = res.data.data.records;
        this.page.total = res.data.data.total;
      })
    }
  }

}
</script>

<style>
.table-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.lot-info-div {
  min-height: 700px;
  min-width: 1200px;
}
.contyern {
  padding: 10px;
  box-sizing: border-box;
}
.contyern > p {
  line-height: 2;
}
</style>