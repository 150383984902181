<template>
  <div>
    <div class="company-out-div">
      <div v-for="c in companyList" :key="c.id" class="company-item-div cursor-pointer-div" @click="jumpDetail(c.id)">
        <img class="company-item-img">
        <div class="company-item-info-div">
          <div class="company-item-name-span">{{c.name}}</div>
          <div class="company-item-count-span">{{c.meetingCount}}场拍卖会</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCmsCompanyList} from '@/api/biz/auction/auctionMeeting'
export default {
  name: 'AuctionEnterpriseView',
  data() {
    return {
      companyList: [],
    }
  },  
  created() {
    this.init();
  },
  methods: {
    jumpDetail(id){
      this.$router.push({path: '/companyDetail', query: {id: id}})
    },
    init(){
      this.queryCompanyData();
    },
    queryCompanyData(){
      getCmsCompanyList().then(res=>{
        this.companyList = res.data.data;
      })
    },
  }
}
</script>

<style scoped>
  .cursor-pointer-div:hover{
    cursor: pointer;
    box-shadow: 0px 4px 10px  rgba(0, 0, 0, 0.35);
  }
  .company-item-count-span{
    margin-top: 5px;
    height: 18px;
    opacity: 1;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: rgba(102, 102, 102, 1);
    text-align: left;
    vertical-align: top;
  }
  .company-item-info-div{
    display: inline-block;
    margin-left: 10px;
    margin-top: 18px;
    vertical-align: top;
  }
  .company-item-name-span{
    display: block;
    height: 21px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
  }
  .company-item-img{
    display: inline-block;
    margin-left: 20px;
    margin-top: 15px;
    width: 50px;
    height: 50px;
    opacity: 1;
    border-radius: 2px;
    background: url(https://img.js.design/assets/smartFill/img238164da709e38.jpg), rgba(204, 204, 204, 1);
    vertical-align: top;
  }
  .company-out-div{
    width: 1320px;
  }
  .company-item-div{
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 310px;
    height: 80px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
  }
</style>