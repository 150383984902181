<template>
  <div>
    <div class="top-name-div">
      {{auctionMeeting.meetingName}}
    </div>
    <div>
      <div class="top-button-div">
        <img class="top-left-button cursor-pointer" @click="changeScrollLeft(1)" src="../../../public/img/change-button.png">
      </div>      
      <div id="lotScroll" class="top-lot-list-div">
        <div id="lotListDiv"  class="lot-item-out-div" :style="'width:'+lotsOutClass+'px'">
          <div class="lot-item-img" v-for="m in lotsList" :key="m.id">
            <img :id="m.id" class="lot-img-class" :src="m.lotImg[0].link" @click="changeLot(m.id)">
            <p class="li-name">{{m.lotName}}</p>
          </div>
        </div>
      </div>
      <div class="top-button-div">
        <img class="top-right-button cursor-pointer" @click="changeScrollLeft(2)" src="../../../public/img/change-button.png">
      </div>
    </div>    
  </div>
</template>

<script>
import {listByMeetingId} from '@/api/biz/auction/auctionLot'
export default {
  name: "HallLotList",
  props: ['meetingId','outLotId'],
  data(){
    return {
      letQueryName: '',
      lotsList: [],
      activeLotId: null,
      lotInfo: {
        meetingType: null,
      },
      auctionMeeting: {},
      lotsOutClass: 1030,
    }
  },
  watch:{
    activeLotId(val){
      this.$router.push({path: "/lotDetail", query: {lotId: val}})
      this.$emit('changeLotId',val)
    }
  },
  methods: {
    changeScrollLeft(type){
      let dom = document.getElementById("lotScroll");
      if(type==1){
        dom.scrollLeft = dom.scrollLeft -228;
      }else if(type==2){
        dom.scrollLeft = dom.scrollLeft + 228;
      }
    },
    changeLot(lotId){        
      this.activeLotId = lotId;
      this.lotsList.forEach(e=>{
        document.getElementById(e.id).style.borderColor = 'transparent'        
      })
      document.getElementById(lotId).style.borderColor = 'red'      
    },
    queryLotsByMeetingId(id){
      listByMeetingId(id).then(res=>{
        this.lotsList = res.data.data;
        this.lotsOutClass = 228 * this.lotsList.length
        console.log(this.lotsOutClass)
        this.lotInfo = this.lotsList[0];
        this.auctionMeeting = this.lotsList[0].auctionMeeting;
        this.$emit('getMeetingData',this.auctionMeeting)
        if(this.outLotId){
          this.$nextTick(()=>{document.getElementById(this.outLotId).style.borderColor = 'red'})
        }
      })
    }
  },
  created(){
    if(this.meetingId){
      this.queryLotsByMeetingId(this.meetingId)
    }
  }

}
</script>

<style scoped>
  .lot-item-out-div{
    min-width:1030px;
    display: inline-block;
  }
  .lot-img-class{
    width: 200px;
    height: 145px;
    border: 2px solid #fff;
  }
  .lot-item-img{
    margin-left: 14px;
    margin-right: 14px;
    display: inline-block;
    width: 200px;
    height: 150px;
    opacity: 1;
  }
  .cursor-pointer:hover{
    cursor: pointer;
    box-shadow: 1px 0px 3px  rgba(0, 0, 0, 0.35);
  }
  .top-button-div{
    width:80px;
    display: inline-block;
    line-height: 180px;
    vertical-align: bottom;
    height:180px;
  }
  .top-lot-list-div{
    display: inline-block;
    overflow: hidden;
    width: 1130px;
    margin-top: 30px;
    height: 150px;
  }
  .top-left-button{
    margin-left: 35px;
    margin-top: 15px;
    transform: rotate(180deg);
    border-radius: 20px;
  }
  .top-right-button{    
    margin-left: 20px;
    margin-top: 15px;
    border-radius: 21px;
  }
  .top-name-div{
    padding-left: 30px;
    height: 60px;
    /** 文本1 */
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 60px;
    color: rgba(0, 0, 0, 1);
    border-bottom: 1px solid rgba(233, 237, 242, 1);
  }
  .pic-top{
    z-index: 0;
    text-align: left;
    margin-left: 5px;
  }
  .li-name{
    position: relative;
    z-index: 1;
    margin-left: 2px;
    margin-top: -32px;
    width: 200px;
    height: 30px;
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);

    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }
  .li-type{
    color: #fff;
    margin-top: -32px;
  }
  .li-content{
    display: grid;
    text-align: center;
  }
  .list-li{
    float: left;
    margin-right: 20px;
  }
  .meeting-title{
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
  }
  .meeting-info{
    color: rgb(102,102,102);
    margin-top: 20px;
    margin-left: 10px;
  }
  .meeting-img-list{
    margin-left: 10px;
    display:inline-block;
    margin-top: 20px;
  }
</style>