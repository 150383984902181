<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  margin: auto;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
.popoverBackB {
  background: linear-gradient(
    180deg,
    rgba(0, 102, 166, 1) 0%,
    rgba(59, 133, 243, 1) 100%
  ) !important;
  color: white !important;
}
.popoverBackB .popper__arrow::after {
  border-left-color: rgba(0, 102, 166, 1) !important;
}
</style>
