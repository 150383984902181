<template>
    <div class="sifa-cont-right">
        <div class="personbox">
            <h2>软件使用费</h2>
            <div class="tabDiv" style="margin-top: 20px;">
            <div class="tableDiv" v-for="(item,index) in data" :key="index">
                <h3>
                    <span class="auctionCompany">拍卖企业: {{ item.companyName }}</span>
                    <!-- <span class="counselor">联系人: {{ item.personName }}</span> -->
                    <!-- <span class="phone">联系电话: {{ item.phone }}</span> -->
                </h3>
                <table class="pp-table" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td class="tab_img">
                                <img :src="item.lotImg[0].link" alt="">
                                <span></span>
                                <span>{{ item.lotName }}</span>
                            </td>
                            <td class="tab_time">
                                <p class="time_box">
                                    <span>成交时间</span>
                                    <br>
                                    <span>{{ item.createTime }}</span>
                                </p>
                            </td>
                            <td class="tab_price">
                                <p class="time_box">
                                    <span>成交价</span>
                                    <br>
                                    <span>{{ item.transactionPrice }}元</span>
                                </p>
                            </td>
                            <td class="tab_status">
                                <p class="time_box">
                                    <span>使用费金额</span>
                                    <br>
                                    <span>{{ item.platformCost }}</span>
                                </p>
                            </td>
                            <td class="tab-button">
                                <div v-if="platformStatus == 0">
                                    <el-button type="primary" plain class="viewButton" @click="pay(item.id)">缴纳服务费</el-button>
                                </div>
                                <div v-else>
                                    <p class="time_box">
                                        <span>缴纳状态</span>
                                        <br>
                                        <span>已缴纳</span>
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
            <div class="block">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10,20,30,40,50,100]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
            </el-pagination>
            </div>
        </div>
    </div>
</template>
  
<script>
import {mapGetters} from "vuex";
import {cmsPagePlatformPrice as getList, payPlatformPrice} from '@/api/biz/auction/winLot'

export default {
    name: 'platformPrice',
    computed: {
        ...mapGetters(["userInfo"]),
        platformStatus(){
            return this.$route.query.platformStatus;
        }
    },
    watch:{
        platformStatus(val){
            if(val){
                this.onLoad(this.page);
            }
        }
    },
    data(){
        return {
            page: {
            pageSize: 10,
            currentPage: 1,
            total: 0
            },
            data: [],
        }
    },
    created(){
        if(this.userInfo&&this.userInfo.user_name){
            this.init();
        }
    },
    methods:{
        init(){
            this.onLoad(this.page);
        },
        pay(id){
            payPlatformPrice(id).then(() => {
                this.$message({
                type: "success",
                message: "支付成功!"
                });
                this.onLoad(this.page);
            })
        },
        handleSizeChange(val) {
            this.page.pageSize = val;
            this.onLoad(this.page);
        },
        handleCurrentChange(val) {
            this.page.currentPage = val;
            this.onLoad(this.page);
        },
        onLoad(page) {
            let params = {
                platformStatus:this.platformStatus,
            }
            getList(page.currentPage, page.pageSize,params).then((res) => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                console.log('data',this.data)
            })
        }
    }
}
</script>

<style>
.tableDiv h3 {
    background: #f3f5f7;
    border-bottom: 0;
    line-height: 40px;
    font-size: 14px;
    color: #666;
    font-weight: 400;
    overflow: hidden;
}
.auctionCompany {
    width: 263px;
    float: left;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding-left: 50px;
    text-align: left;
    white-space: nowrap;
    word-break: keep-all;
    word-wrap: normal;
}
.counselor, .phone {
    width: 320px;
    float: left;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pp-table {
    width: 100%;
    margin: 10px auto 0;
}
.pp-table .tab_img {
    width: 294px;
}
.pp-table td {
    float: left;
    border-right: 1px solid #d7d7d7;
    line-height: 22px;
    height: 80px;
    font-size: 12px;
    width: 165px;
    text-align: center;
}
.pp-table td:last-child {
    line-height: normal;
    border-right: none;
}
.tableDiv {
    width: 96%;
    margin: 18px auto 0;
    border: 1px solid #d7d7d7;
}
.block{
    margin-left: 50%;
    margin-bottom: 2%;
    margin-top: 2%;
}
.viewButton{
    margin-left: 20%;
    margin-top: 10%;
}
.tab_img img {
    vertical-align: middle;
    width: 120px;
    height: 80px;
    float: left;
    margin-right: 10px;
    margin-left: 5%;
}
.tab_price .price-box {
    /* padding: 22px 0 0; */
    line-height: 18px;
}
.time_box{
    line-height: 22px;
    padding-top: 22px;
}
.tab_time em {
    color: #999;
}
.personbox {
  margin: 0 0 40px;
  display: none;
  background-color: #fff;
  display: block;
}
.personbox h2 {
  height: 30px;
  line-height: 30px;
  background: #e9e9e9;
  color: #424242;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
</style>