<template>
  <div>
    <div class="left-type-div">
      <div class="left-top-div">
        <img class="left-top-img" src="../../../public/img/list-icon.png"/>
        <span class="left-top-span">需求类型</span>
      </div>
      <div>
        <span class="left-item-span" :class="t.dictKey==searchType?'left-item-span-check':''" v-for="t in bulletinTypeDict" :key="t.id"
            @click="checkSearchType(t.dictKey)">{{t.dictValue}}</span>
      </div>
    </div>
    <div class="right-list-div">
      <div class="right-top-div">
        <span class="list-tab-span">买盘列表</span>
        <span class="top-button-span" @click="openAddDialog">+ 添加需求</span>
      </div>
      <div>
        <el-table :data="attractList" style="width: 100%" :cell-style='styleCenter' :header-cell-style='styleCenter' @row-click='jumpDetail'>
          <el-table-column prop="companyName" label="发布单位" width="200"></el-table-column>
          <el-table-column prop="type" label="标的类型" width="140">
            <template slot-scope="scope">
              {{formatDataType(scope.row.type)}}
            </template>
          </el-table-column>
          <el-table-column prop="title" label="标的名称" width="200"></el-table-column>
          <el-table-column prop="price" label="价格" width="140">
            <template slot-scope="scope">
              {{scope.row.price}}{{scope.row.priceUnit}}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="有效时间" width="140">
            <template slot-scope="scope">
              {{scope.row.endTime.slice(0,10)}}
            </template>
          </el-table-column>
          <el-table-column prop="publishArea" label="所在地" width="180"></el-table-column>
        </el-table>
        <div class="bottom-page-div">
            <el-pagination  @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.currentPage"
            :page-sizes="[10, 20, 30, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
            </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="新增" :visible.sync="dialogVisible">
      <el-form :model="addForm" :rules="rules" status-icon ref="ruleForm" label-width="100px">
        <el-form-item label="标的类型：" prop="type">
          <el-select v-model="addForm.type" placeholder="请选择">
            <el-option v-for="item in bulletinTypeDict" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效时间" prop="endTime">
          <el-date-picker v-model="addForm.endTime" value-format='yyyy-MM-dd hh:mm:ss' type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="标的名称" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="价格(万元)" prop="price">
          <el-input v-model.number="addForm.price"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input v-model.number="addForm.num"></el-input>
        </el-form-item>
        <el-form-item label="数量单位" prop="numUnit">
          <el-input v-model="addForm.numUnit"></el-input>
        </el-form-item>
        <el-form-item label="所在地" prop="publishArea">
          <el-input v-model="addForm.publishArea"></el-input>
        </el-form-item>
        <el-form-item label="交收方式" prop="deliveryMethod">
          <el-input v-model="addForm.deliveryMethod"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button @click="dialogVisible=false">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {getList, cmsSave} from '@/api/biz/columnBulletin/attractInvestment'
import {getBizDictByCode} from '@/api/system/dict'
import {mapGetters} from "vuex";
export default {
  name: 'attractInvestmentView',
  data() {
    return {
      attractList: [],
      searchType: 1,
      styleCenter: {'text-align':'center'},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      dialogVisible: false,
      addForm: {},
      bulletinTypeDict: [],
      rules: {
        type: [{ required: true, message: '请选择活动性质', trigger: 'change' }],
        title: [{ required: true, message: '请输入标的名称', trigger: 'change' }],
        price: [{ type: 'number',required: true, message: '请输入价格', trigger: 'change' },],
        num: [{ required: true, message: '数量不能为空'},
        { type: 'number', message: '数量必须为数字值'}],
        numUnit: [{ required: true, message: '请输入数量单位', trigger: 'change' }],
        endTime: [{ required: true, message: '请输入有效时间', trigger: 'change' }],
        publishArea: [{ required: true, message: '请输入所在地', trigger: 'change' }],
        deliveryMethod: [{ required: true, message: '请输入交收方式', trigger: 'change' }],
      }
    }
  },
  computed: {...mapGetters(["userInfo"])},
  created(){
    this.getDict();
    this.queryData(this.page);
  },
  methods: {
    jumpDetail(row){
      this.$router.push({path: '/attractDetail', query: {id: row.id}})
    },
    handleSizeChange(val){
      this.page.pageSize = val;
      this.queryData(this.page);
    },
    handleCurrentChange(val){
      this.page.currentPage = val;
      this.queryData(this.page);
    },
    checkSearchType(type){
      let queryType = type==this.searchType
      this.searchType = type
      if(!queryType)
        this.queryData(this.page);
    },
    formatDataType(type){
      let data = '';
      this.bulletinTypeDict.forEach(ele=>{if(ele.dictKey==type)data = ele.dictValue;})
      return data;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          cmsSave(this.addForm).then(res=>{
            if(res.data.data){
              this.dialogVisible = false;              
              this.$message({type: 'success',message: '操作成功！'})
            }else{
              this.$message({type: 'error',message: '操作失败！'})
            }
          })
        } else {
          return this.$message({type: 'warning',message: '请完成表单填写'})
        }
      });
    },
    getDict(){
      getBizDictByCode('bulletin_type').then(res=>{
        this.bulletinTypeDict = res.data.data;
      });
    },
    queryData(page){
      let param = {type: this.searchType, publishStatus: 2};
      getList(page.currentPage, page.pageSize, param).then(res=>{
        const data = res.data.data;
        this.page.total = data.total;
        this.attractList = data.records;
      })
    },
    openAddDialog(){
      if(this.userInfo.account){
        if(this.userInfo.role_name.indexOf('bidAgency')==-1){
          return this.$message({type:'warning', message: '本功能仅企业可用！'})
        }
        if(this.userInfo.isAuth!=2){
          return this.$message({type:'warning', message: '请先完善实名认证！'})
        }
        this.addForm = {};
        this.dialogVisible = true;
      }else{
        this.$message({type:'warning', message: '请登录后再使用当前功能！'})
      }
    },
  }
}
</script>

<style scoped>
  .bottom-page-div{
    text-align:right;
    margin-right:50px;
    height: 60px;
    margin-top: 20px;
  }
  .left-item-span-check{
    width: 240px !important;
    background: linear-gradient(180deg, rgba(0, 102, 166, 1) 0%, rgba(59, 133, 243, 1) 100%), rgba(204, 204, 204, 1);
    color: rgba(255, 255, 255, 1);
  }
  .left-item-span:hover{
    cursor: pointer;
    border: solid 1px rgb(220, 220, 220);
  }
  .left-item-span{
    display: inline-block;
    width: 238px;
    height: 50px;
    opacity: 1;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 50px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
  }
  .left-top-span{
    display: inline-block;
    margin-left: 15px;
    width: 130px;
    height: 40px;
    opacity: 1;
    /** 文本1 */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 48px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .left-top-img{
    display: inline-block;
    margin-left: 30px;
    margin-top: 20px;
    width: 14.99px;
    height: 11.32px;
  }
  .left-top-div{
    height: 40px;
    opacity: 1;
    /** 文本1 */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: top;
    border-bottom: solid 1px gray;;
  }
  .top-button-span:hover{
    cursor: pointer;
    box-shadow: 0px 4px 10px  rgba(0, 0, 0, 0.35);
  }
  .top-button-span{
    display: inline-block;
    margin-left: 769px;
    margin-top: 5px;
    width: 100px;
    height: 40px;
    opacity: 1;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(246, 193, 101, 1) 0%, rgba(224, 168, 50, 1) 100%);

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
  }
  .list-tab-span{
    display: inline-block;
    width: 140px;
    height: 50px;
    border-radius: 4px 4px, 0px, 0px;
    background: linear-gradient(180deg, rgba(0, 102, 166, 1) 0%, rgba(59, 133, 243, 1) 100%), rgba(0, 102, 166, 1);

    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 50px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
  }
  .right-top-div{
    width: 1040px;
    height: 50px;
    opacity: 1;
    border-radius: 4px 4px, 0px, 0px;
    background: rgba(248, 248, 248, 1);
  }
  .left-type-div{
    display: inline-block;
    width: 240px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    vertical-align: top;
  }
  .right-list-div{
    display: inline-block;
    margin-left: 20px;
    width: 1040px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    vertical-align: top;
  }
</style>