import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sino-user/user/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sino-user/getCompanyUserById',
    method: 'get',
    params: {
      id
    }
  })
}

export const getAuctioneerApi = (userId) => {
  return request({
    url: '/api/sino-user/auctioneer-info',
    method: 'get',
    params: {
      userId
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sino-user/user/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sino-user/addAuctionCompanyUser',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sino-user/user/submit',
    method: 'post',
    data: row
  })
}

export const pageAuctioneerByCompanyId = (current, size, params) => {
  return request({
    url: '/api/sino-user/pageAuctioneerByCompanyId',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
