import request from '@/router/axios';

export const getList = (current, size) => {
  return request({
    url: '/api/sino-winLot/winLot/cmsPageCommission',
    method: 'get',
    params: {
      current,
      size,
    }
  })
}

export const detailCommission = (id) => {
  return request({
    url: '/api/sino-winLot/winLot/detailCommission',
    method: 'get',
    params: {
      id
    }
  })
}

export const payCommission = (row) => {
  return request({
    url: '/api/sino-winLot/winLot/payCommission',
    method: 'post',
    data: row
  })
}

export const getCommissionRecord = (params) => {
  return request({
    url: '/api/sino-winLot/winLot/getCommissionRecord',
    method: 'get',
    params: params
  })
}

export const getCmsPlatformPage = (current, size, params) => {
  return request({
    url: '/api/sino-winLot/winLot/cmsPlatformPage',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const cmcGetCommission = (id) => {
  return request({
    url: '/api/sino-winLot/winLot/cmcGetCommission',
    method: 'get',
    params: {
      id
    }
  })
}

export const cmsPagePlatformPrice = (current, size, params) => {
  return request({
    url: '/api/sino-winLot/winLot/cmsPagePlatformPrice',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const payPlatformPrice = (id) => {
  return request({
    url: '/api/sino-winLot/winLot/payPlatformPrice',
    method: 'post',
    params: {
      id
    }
  })
}
