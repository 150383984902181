<template>
  <div>
    <div class="company-detail-div">
      <div class="detail-top-info-div">
        <img class="company-detail-img">
        <div class="company-detail-info-div">
          <div class="company-detail-name-div">{{company.name}}</div>
          <div class="company-detail-item">所在地：{{company.address}}</div>
          <div class="company-detail-item">联系电话：{{company.contractPersonPhone}}</div>
        </div>
        <div class="company-count-div">
          <div class="company-count-item-div">
            <span class="count-item-span">{{company.successCount}}</span><br>
            <span class="count-item-label-span">累计成交</span>
          </div>
          <div class="count-line-border"></div>
          <div class="company-count-item-div">
            <span class="count-item-span">{{company.bidCount}}</span><br>
            <span class="count-item-label-span">累计标的</span>
          </div>
          <div class="count-line-border"></div>
          <div class="company-count-item-div">
            <span class="count-item-span">{{company.meetingCount}}</span><br>
            <span class="count-item-label-span">累计拍卖会</span>
          </div>
        </div>
      </div>
      <div class="detail-centent-div">
        <div class="company-info-title-div">企业简介</div>
        <div class="company-info-intro-div">
          <div v-html="company.intro"></div>
        </div>
      </div>
    </div>
    <CompanyTab v-if="companyId" :companyId="companyId"></CompanyTab>
  </div>
</template>

<script>
import {getCmsCompanyDetail} from '@/api/biz/auction/auctionLot';
import CompanyTab from './companyTab.vue'
export default {
  name: 'AuctionEnterpriseDetailView',
  data() {
    return {
      company: {},
    }
  },
  components: {CompanyTab},
  computed: {
    companyId() {
      return this.$route.query.id;
    },
  },
  created() {
    this.queryData();
  },
  methods: {
    queryData(){
      getCmsCompanyDetail(this.companyId).then(res=>{
        this.company = res.data.data;
      })
    },
  }
}
</script>

<style scoped>
  .company-info-intro-div{
    margin-top: 10px;
    margin-bottom: 20px;
    width: 1100px;
    min-height: 190px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .detail-centent-div{
    display: block;
    margin-top: 30px;
    margin-left: 100px;
  }
  .company-info-title-div{
    width: 200px;
    height: 27px;
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(0, 102, 166, 1);
    text-align: left;
    vertical-align: top;
  }
  .count-item-label-span{
    display: inline-block;
    margin-top: 5px;
    height: 27px;
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(102, 102, 102, 1);
    text-align: left;
    vertical-align: top;
  }
  .count-line-border{
    display: inline-block;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 35px;
    width: 0px;
    height: 20px;
    opacity: 1;
    border-left: 2px solid rgba(215, 215, 215, 1);
  }
  .count-item-span{
    display: inline-block;
    width: 32px;
    height: 27px;
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .company-count-item-div{
    display: inline-block;
    margin-top: 20px;
    width: 135px;
    height: 70px;
    vertical-align: top;
    text-align: center;
  }
  .company-count-div{
    display: inline-block;
    width: 640px;
    height: 100px;
    vertical-align: top;
  }
  .company-detail-item{
    display: inline-block;
    margin-top: 5px;
    width: 200px;
    height: 18px;
    opacity: 1;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: rgba(102, 102, 102, 1);
    text-align: left;
    vertical-align: top;
  }
  .company-detail-name-div{
    display: block;
    height: 40px;
    width: 300px;
    /** 文本1 */
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
  }
  .company-detail-info-div{
    display: inline-block;
    margin-left: 20px;
    width: 350px;
    height: 90px;
    vertical-align: top;
  }
  .company-detail-img{
    display: inline-block;
    width: 90px;
    height: 90px;
    opacity: 1;
    border-radius: 4px;
    background: url(https://img.js.design/assets/smartFill/img349164da74c4b8.jpg), rgba(0, 102, 166, 1);
  }
  .detail-top-info-div{
    margin-top: 30px;
    display: block;
    margin-left: 100px;
    height: 120px;
    width: 1100px;
    opacity: 1;
    border-bottom: 1px solid rgba(215, 215, 215, 1);
  }
  .company-detail-div{
    display: inline-block;
    width: 1300px;
    min-height: 300px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
  }
</style>