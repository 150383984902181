<template>
  <div>
    <div>

      <Head ref="head"></Head>
    </div>
    <div class="route-body">
      <div class="center-size">
        <router-view @headSerach='headSerach'></router-view>
      </div>
    </div>
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import { getStore } from "@/util/store.js";
import { calcDate } from "@/util/date.js";
import { validatenull } from "@/util/validate";
export default {
  name: "HomeView",
  components: { Head, Footer },
  data() {
    return {
      //搜索控制
      isSearch: false,
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: ""
    };
  },
  created() {
    //实时检测刷新token
    this.refreshToken();
  },
  methods: {
    headSerach(val) {
      this.$refs.head.searchAllByKey(val)
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: "token",
          debug: true
        }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch("refreshToken")
            .then(() => {
              this.refreshLock = false;
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
      }, 10000);
    }
  }
}
</script>

<style>
.center-size {
  /* margin-left: 310px; */
  margin: 0 auto;
  padding-top: 20px;
  width: 1300px;
}
.route-body {
  min-height: 720px;
  background-color: rgb(238, 242, 245);
}
</style>