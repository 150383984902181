<template>
  <div>
    {{this.$route}}
  </div>
</template>
<script>
export default {
  name: "BulletinDetailView",
  created(){
    console.log(this.$route)
  }
}
</script>

<style>

</style>