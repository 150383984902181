<template>
  <div>
    <div>

      <el-divider></el-divider>
      <div>
        <el-row :gutter="20">
          <el-col :span="2"><span>标的类型</span></el-col>
          <el-col :span="18">
            <ul class="search-ul">
              <li v-for="t in bulletinTypeDict" :key="t.id" class="search-li">
                <span @click="changeBulletinType(t.dictKey)">{{t.dictValue}}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <el-divider></el-divider>
    </div>
    
    <div>
      <ul>
        <li v-for="b in noticeData" :key="b.id" @click="bulletinJump(b)">
          <div>
            <el-tag type="primary" effect="dark">拍卖公告</el-tag>
            <span class="bulletin-title">{{b.title}}</span>
            <!-- 后边需要改成发布时间 -->
            <span class="publish-time">{{b.createTime}}</span>
          </div>
          <div>            
            <div class="bulletin-content">
              <span style="color:rgb(27,7,34)">公告详情：</span>
              {{b.content}}
            </div>
          </div>
          <div class="bulletin-bottom">
            <el-row :gutter="24">
              <el-col :span="10"><div class="grid-content bg-purple">项目类型：<span class="bottom-value">{{b.typeName}}</span></div></el-col>
              <el-col :span="10"><div class="grid-content bg-purple">项目所在地：<span class="bottom-value">北京</span></div></el-col>
              <el-col :span="4"><div class="grid-content bg-purple">项目来源：<span class="bottom-value">{{b.companyName}}</span></div></el-col>
            </el-row>
          </div>
          <el-divider></el-divider>
        </li>
      </ul>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="noticePage.currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="noticePage.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="noticePage.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getCmsList} from '@/api/biz/auction/auctionBulletin'
import {getBizDictByCode} from '@/api/system/dict'
export default {
  name: "NoticesList",
  data(){
    return {
      noticeData: [],
      searchParam: {
        type: null,
      },
      noticePage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      meetingLoading: false,
      bulletinTypeDict: [],
    }
  },
  async created(){
    await this.getBulletinType();
    this.onLoad(this.noticePage);
  },
  methods: {
    bulletinJump(bull){
      this.$router.push({path:"/lots", query: {bulletinId: bull.id}})
    },
    changeBulletinType(type){
      this.searchParam.type = type;
      this.onLoad(this.noticePage);
    },
    getBulletinType(){
      getBizDictByCode('bulletin_type').then(res=>{
        this.bulletinTypeDict = res.data.data;
        this.bulletinTypeDict.unshift({id: 111,dictValue: '全部',dictKey: null, sort: 0})
      });
    },
    formatPlace(type){
      this.bulletinTypeDict.forEach(ele=>{if(ele.dictKey==type){        
        return ele.dictValue}});
    },
    handleSizeChange(val){
      this.noticePage.pageSize = val;
      this.onLoad(this.noticePage);
    },
    handleCurrentChange(val){
      this.noticePage.currentPage = val;
      this.onLoad(this.noticePage);
    },
    onLoad(page){
      this.meetingLoading = true;
      getCmsList(page.currentPage, page.pageSize, Object.assign({},this.searchParam)).then(res => {
        const data = res.data.data;
        this.noticePage.total = data.total;
        this.noticeData = data.records;        
        for(let i=0;i<this.noticeData.length;i++){
          this.bulletinTypeDict.forEach(b=>{if(b.dictKey==this.noticeData[i].type) this.noticeData[i].typeName=b.dictValue})
        }
        this.meetingLoading = false;
      });
    }
  }
}
</script>

<style>
  .search-ul{
    width: 1150px;
    overflow: hidden;
    white-space:nowrap;
  }
  .search-li{
    margin-left:40px;
    float: left;
  }
  .bottom-value{
    color: red;
  }
  .bulletin-bottom{
    margin-top: 12px;
  }
  .bulletin-content{
    margin-top: 12px;
    color: rgb(155,155,155);
    width: 1200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .publish-time{
    float: right;
    font-size: 14px;
  }
  .bulletin-title{
    font-size: 16px;
    margin-left: 20px;
    font-weight: 550;  
  }
</style>