<template>
    <div>
        <div class="renzheng">
            <span v-if="type == 'view' && form.isAuth == 1" class="renzheng_zhong">审核中</span>
            <span v-else-if="type == 'view' && form.isAuth == 2" class="renzheng_pass">认证成功</span>
            <span v-else-if="type == 'view' && form.isAuth == -1" class="renzheng_fail">认证失败</span>
        </div>
        <el-form ref="form" :model="form" label-width="140px" label-position="right" class="authForm" size="small" :rules="rules">
            <div v-if="role == 'bidder'">
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="用户姓名:" prop="realName" >
                            <el-input v-model="form.realName"  placeholder="请输入您的真实姓名" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="身份证号:" prop="idCard">
                            <el-input v-model="form.idCard"  placeholder="请输入身份证证件号码" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div v-else>
                <el-row class="authRow">
                    <h3 class="group">机构基本信息</h3>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="机构名称:" prop="name">
                            <el-input v-model="form.name"  placeholder="请输入机构名称" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="证件类型:" prop="certificateType">
                                <el-select v-model="form.certificateType" placeholder="请选择证件类型" :disabled="editDisabled">
                                    <el-option
                                    v-for="item in certificateTypeOptions" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey">
                                    </el-option>
                                </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="统一社会信用代码:" prop="creditCode">
                            <el-input v-model="form.creditCode"  placeholder="请输入统一社会信用代码" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="authRow">
                    <h3 class="group">法人基本信息</h3>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="法人名称:" prop="legalName">
                            <el-input v-model="form.legalName"  placeholder="请输入法人名称" :disabled="editDisabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="法人证件类型:" prop="legalCertificateType">
                                <el-select v-model="form.legalCertificateType" placeholder="请选择法人证件类型" :disabled="editDisabled">
                                    <el-option
                                    v-for="item in legalCertificateTypeOptions" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey">
                                    </el-option>
                                </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="法人证件号:" prop="legalCertificateCode">
                            <el-input v-model="form.legalCertificateCode"  placeholder="请输入法人证件号" :disabled="editDisabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="电话:">
                            <el-input v-model="form.contractPersonPhone"  placeholder="请输入联系人电话" :disabled="editDisabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <el-row class="authRow">
                    <el-col :span="12">
                        <el-form-item label="地址:" prop="address">
                            <el-input v-model="form.address"  placeholder="请输入地址" :disabled="editDisabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            <el-row class="authRow">
                <el-col :span="12">
                    <el-form-item label="开户银行:" prop="bankName">
                        <el-input v-model="form.bankName"  placeholder="请输入开户银行" maxlength="200" :disabled="editDisabled"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="authRow">
                <el-col :span="12">
                    <el-form-item label="基本账户账号:" prop="bankAccount">
                        <el-input v-model="form.bankAccount"  placeholder="请输入基本账户账号" maxlength="200" :disabled="editDisabled"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row  class="authRow"> 
                    <span style="font-size: 14px;color: #606266; margin-left: 7%;line-height: 32px;">请按照以下要求上传证件照片（格式要求：jpg、jpeg、png，小于2M）</span>
                </el-row>
                <el-row class="uplpadRow">
                      <el-col :span="8" v-if="role == 'bidAgency'">
                        <el-form-item label="" prop="certificateDetails" v-if="role == 'bidAgency'">
                        <el-upload
                            :limit="1"
                            :class="{hide: hideUploadInfo}"
                            list-type="picture-card"
                            :action="`/api/sino-resource/oss/endpoint/put-file?${this.website.tokenHeader}=${this.token}`"
                            :on-success="handleCertificateDetails"
                            :on-remove="removeCertificateDetails"
                            :disabled="editDisabled"
                            :file-list="ceFileList">
                            <i class="el-icon-plus"></i>
                            <div slot="tip" style="margin-top: 4%; margin-left: 12%;"><span style="color: red;">*</span>上传证件详细资料页</div>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="" prop="idCardFace">
                        <el-upload
                            :limit="1"
                            :class="{hide: hideUploadFace}"
                            list-type="picture-card"
                            :action="`/api/sino-resource/oss/endpoint/put-file?${this.website.tokenHeader}=${this.token}`"
                            :on-success="handleIdCardFace"
                            :on-remove="removeIdCardFace"
                            :before-upload="beforeAvatarUpload"
                            :disabled="editDisabled"
                            :file-list="faceFileList" >
                            <i class="el-icon-plus"></i>
                            <div slot="tip" style="margin-top: 4%; margin-left: 12%;"><span style="color: red;">*</span>上传身份证人像面</div>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="" prop="idCardEmblem">
                        <el-upload
                            :limit="1"
                            :class="{hide: hideUploadEmblem}"
                            list-type="picture-card"
                            :action="`/api/sino-resource/oss/endpoint/put-file?${this.website.tokenHeader}=${this.token}`"
                            :on-success="handleIdCardEmblem"
                            :on-remove="removeIdCardEmblem"
                            :before-upload="beforeAvatarUpload"
                            :disabled="editDisabled"
                            :file-list="emblemFileList" >
                            <i class="el-icon-plus"></i>
                            <div slot="tip" style="margin-top: 4%; margin-left: 12%;"><span style="color: red;">*</span>上传身份证国徽面</div>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-top: 5%;">
                <el-form-item v-if="flag">
                    <el-button type="primary" @click="submitForm" style="width: 15%;" >提交</el-button>
                </el-form-item>
                <el-form-item v-if="type == 'view' && form.isAuth == 2 && !flag" >
                    <span style="color: #666;">您的实名信息已认证成功，现可享受更多服务。如需变更，请点击</span>
                    <el-button type="text" style="font-size: 1em;" @click="editClick">更新认证信息</el-button>
                </el-form-item> 
            </el-row>
        </el-form>
    </div>
</template>
  
  <script>
    import {getDictionary} from "@/api/system/dictbiz"
    import {add,getDetail,update} from "@/api/biz/user/company"
    import {getToken} from "@/util/auth";

  export default {
    name: 'authForm',
    computed: {
        type(){
            return this.$route.query.type;
        },
        role(){
            return this.$route.query.role;
        },
        token() {
             return getToken();
        },
    },
    created(){
        this.init();
    },
    data(){
        var checkIdCard = (rule, value, callback) => {
            const idCardRegex = /^[1-9]\d{5}(19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}[0-9Xx]$/;
        if (!value) {
            callback(new Error('请输入证件号码'));
        } else if(!idCardRegex.test(value)) {
            callback(new Error('请输入正确的证件号码'));
        } else {
            callback();
        }
    };
      return {
        form: {},
        data: {},
        // 身份证人像面
        idCardFaceAttach:{},
        // 身份证国徽面
        idCardEmblemAttach:{},
        // 证件详细资料页
        certificateDetailsAttach:{},
        // 企业证件类型
        certificateTypeOptions:[],
        // 法人证件类型
        legalCertificateTypeOptions:[],
        disabled: false,
        flag: true,
        editDisabled: false,
        emblemFileList:[],
        faceFileList: [],
        ceFileList: [],
        rules: {
            realName:[{ required: true, message: '请输入您的真实姓名', trigger: 'blur' }],
            idCard:[{ required: true,  validator: checkIdCard, trigger: 'blur' }],
            name:[{ required: true, message: '请输入机构名称', trigger: 'blur' }],
            certificateType:[{ required: true, message: '请选择证件类型', trigger: 'blur' }],
            creditCode:[{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
            legalName:[{ required: true, message: '请输入法人名称', trigger: 'blur' }],
            legalCertificateType:[{ required: true, message: '请选择法人证件类型', trigger: 'blur' }],
            legalCertificateCode:[{ required: true, message: '请输入法人证件号', trigger: 'blur' }],
            address:[{required: true, message: '请输入地址',trigger: 'blur'}],
            bankName:[{ required: false, message: '请输入开户银行', trigger: 'blur' }],
            bankAccount:[{ required: false, message: '请输入基本账户账号', trigger: 'blur' }],
        },
        hideUploadInfo: false,
        hideUploadFace: false,
        hideUploadEmblem: false,
      }
    },
    methods:{
        // 初始化信息
        init(){
            if(this.role == 'bidAgency') {
                getDictionary({ code: 'certificate_type' }).then((res) => {
                    this.certificateTypeOptions = res.data.data;
                })
                getDictionary({ code: 'legal_certificate_type' }).then((res) => {
                    this.legalCertificateTypeOptions = res.data.data;
                })
            }
            if(this.type == 'view'){
                getDetail().then((res) => {
                    this.form = res.data.data;
                    this.form.certificateType = ''+this.form.certificateType;
                    // 图片回显
                    if(this.role == 'bidAgency'){
                        this.ceFileList.push({name: this.form.certificateDetails.originalName, url:  this.form.certificateDetails.link});
                        this.certificateDetailsAttach =  this.form.certificateDetails;
                    }
                    this.faceFileList.push({name :this.form.idCardFace.originalName, url:this.form.idCardFace.link})
                    this.idCardFaceAttach = this.form.idCardFace;
                    this.emblemFileList.push({name :this.form.idCardEmblem.originalName, url:this.form.idCardEmblem.link})
                    this.idCardEmblemAttach = this.form.idCardEmblem;
                    if(this.form.isAuth == 2 || this.form.isAuth == 1){
                        this.disabled = true;
                        this.editDisabled = true;
                        this.flag = false;
                    }
                    if(this.role == 'bidder') {
                        this.$set(this.form,'realName',this.form.legalName);
                        this.$set(this.form,'idCard',this.form.legalCertificateCode);
                    }
                    console.log('form',this.form)
                })
            }
        },
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if(this.role == 'bidAgency' && Object.keys(this.certificateDetailsAttach).length === 0) {
                        return this.$message.error('请上传证件详细资料页照片！')
                    }
                    if(Object.keys(this.idCardFaceAttach).length === 0) {
                        return this.$message.error('请上传身份证人像面照片！')
                    }
                    if(Object.keys(this.idCardEmblemAttach).length === 0) {
                        return this.$message.error('请上传身份证国徽面照片！')
                    }
                    this.form.certificateDetails = this.certificateDetailsAttach;
                    this.form.idCardFace = this.idCardFaceAttach;
                    this.form.idCardEmblem = this.idCardEmblemAttach;
                if (valid) {
                    if(this.type == 'add'){
                        add(this.form).then(
                        () => {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.$router.push('/personalCenter/accountSecurity/accountShow');
                        },
                        (error) => {
                        window.console.log(error);
                        }
                        );
                    } else {
                        update(this.form).then(
                        () => {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.$router.push('/personalCenter/accountSecurity/accountShow');
                        },
                        (error) => {
                        window.console.log(error);
                        }
                        );
                    }
                } else {
                console.log("error submit!!");
                return false;
                }
            });
    },
    editClick(){
        this.editDisabled = false;
        this.flag = true;
    },
    removeCertificateDetails(){
        this.hideUploadInfo = false;
    },
    handleCertificateDetails(res){
        this.certificateDetailsAttach = res.data;
        this.hideUploadInfo = true;
    },
    removeIdCardFace(){
        this.hideUploadFace = false;
    },
    handleIdCardFace(res){
        this.idCardFaceAttach = res.data;
        this.hideUploadFace = true;
    },
    removeIdCardEmblem(){
        this.hideUploadEmblem = false;
    },
    handleIdCardEmblem(res){
        this.idCardEmblemAttach = res.data;
        this.hideUploadEmblem = true;
    },
    beforeAvatarUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJpgOrPng) {
          this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }
    }
  }
  </script>
  
  <style>
.hide .el-upload--picture-card {
  display: none;
}
.authForm{
    margin-top: 3%;
}
.authRow {
    margin-left: 7%;
}
.uplpadRow{
    margin-left: -6%;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .group{
    font-size: 1.5em;
    margin-bottom: 3%;
  }
  .renzheng{
    line-height: 3em;
    font-size: 2em;
    margin-left: 8%;
  }
  .renzheng_zhong{
    color: #ffab40;
  }
  .renzheng_fail{
    color: #c93e45;
  }
  .renzheng_pass{
    color: #0ab86c;
  }
  </style>