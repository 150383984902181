<template>
  <div>
    <div class="active-lot-detail cursor-pointer-div" v-for="lot in lotList" :key="lot.id" @click="toLotDetail(lot)">
      <span class="active-lot-sort">
        {{lot.sort}}
      </span>
      <img class="active-lot-img" :src="lot.lotImg[0].link">
      <span class="active-lot-name">{{lot.lotName}}</span>
      <div>
        <span v-if="lot.auctionStatus!=null" class="active-lot-label-status">{{lot.auctionStatus==0?'未开始':(lot.auctionStatus==1||lot.auctionStatus==2?'进行中':
          (lot.auctionStatus==3?'已成交':(lot.backOutStatus==1?'已撤拍':'已流拍')))}}&nbsp;</span>
        <span v-if="lot.auctionStatus!=-1" :style="lot.auctionStatus==0?'color: rgba(30, 169, 106, 1);':'color:rgba(214, 54, 59, 1)'" 
          class="acitve-lot-price" >{{lot.auctionStatus==0?parseFloat(lot.startPrice).toFixed(2):parseFloat(lot.currentQuoted).toFixed(2)}}元</span>
      </div>
      <div class="active-lot-time-div">
        <span class="active-lot-time-left">
          <span class="active-lot-time-plan" v-if="lot.auctionStatus==0">预计&nbsp;</span>
          <span class="active-lot-time-status">{{lot.auctionStatus==-1||lot.auctionStatus==3?lot.endTime+'结束':meetingData.startAuctionTime+'开始'}}</span>
        </span>
        <span v-if="lot.auctionStatus!=null" class="active-lot-time-right" :style="lot.auctionStatus==0?'background: rgba(30, 169, 106, 1);':
          (lot.auctionStatus==3||lot.auctionStatus==-1?'background: rgba(176, 176, 176, 1);':'')">
          {{lot.auctionStatus==0?'未开始':(lot.auctionStatus==1||lot.auctionStatus==2?'进行中':(lot.auctionStatus==3?'已成交':(lot.backOutStatus==1?'已撤拍':'已流拍')))}}</span>
      </div>
      <div>
        <span class="active-lot-bottom-span">{{lot.viewCount}}次围观</span>
        <span class="active-lot-bottom-span" v-if="lot.auctionStatus!=0">{{lot.bidCount}}次出价</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LotDivView',
  props: ['lotList', 'meetingData'],
    methods: {    
    toLotDetail(lot){
      this.$router.push({path: "/lotDetail", query: {lotId: lot.id}})
    },
  }
}
</script>

<style>
  .active-lot-bottom-span{
    display: inline-block;
    margin: 20px 10px 10px 10px;
    width: 66px;
    height: 18px;
    opacity: 1;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
  }
  .active-lot-time-right{
    display: inline-block;
    width: 60px;
    height: 24px;
    opacity: 1;
    border-radius: 20px 0px 0px 20px;
    background: rgba(214, 54, 59, 1);

    opacity: 1;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  .active-lot-time-status{
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: NaNpx;
    color: rgba(0, 0, 0, 1);
  }
  .active-lot-time-plan{
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);
  }
  .active-lot-time-left{
    display: inline-block;
    width: 240px;
  }
  .active-lot-time-div{
    margin-top: 5px;
    margin-left: 10px;
    height: 24px;
  }
  .acitve-lot-price{
    font-size: 18px;
    font-weight: 400;
  }
  .active-lot-label-status{
    display: inline-block;
    margin-left: 10px;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(0, 0, 0, 1);
  }
  .active-lot-name{
    margin: 10px 10px 30px;
    display: inline-block;
    width: 198px;
    height: 21px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);
    vertical-align: top;
  }
  .active-lot-img{
    width: 310px;
    height: 190px;
    opacity: 1;
    margin-top: -24px;
  }
  .active-lot-sort{
    position: relative;
    display: inline-block;
    width: 36px;
    height: 24px;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;

    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  .cursor-pointer-div:hover{
    cursor: pointer;
    box-shadow: 0px 4px 10px  rgba(0, 0, 0, 0.35);
  }
  .active-lot-detail{
    display: inline-block;
    width: 310px;
    height: 352px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    margin-right: 20px;
    margin-top: 20px;
  }
</style>