<template>
  <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
    <el-form-item prop="phone">
      <el-input size="small" @keyup.enter.native="handleLogin" v-model="loginForm.phone" auto-complete="off"
        :placeholder="'请输入手机号'">
        <i slot="prefix" class="icon-shouji" />
      </el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input size="small" @keyup.enter.native="handleLogin" v-model="loginForm.code" auto-complete="off"
        :placeholder="'请输入验证码'">
        <i slot="prefix" class="icon-yanzhengma" style="margin-top:6px;" />
        <template slot="append">
          <span @click="handleSend" class="msg-text" :class="[{display:msgKey}]">{{msgText}}</span>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button size="small" type="primary" style="    width: 100%;" @click.native.prevent="handleLogin"
        class="login-submit">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { isvalidatemobile } from "@/util/validate";
import { mapGetters } from "vuex";
import { sendPhoneLoginValidate } from "@/api/user";
import AuditStateEnum from "@/enum/AuditStateEnum";
export default {
  name: "codeLogin",
  data() {
    const validatePhone = (rule, value, callback) => {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error("请输入6位数的验证码"));
      } else {
        callback();
      }
    };
    return {
      msgText: "",
      msgTime: "",
      msgKey: false,
      loginForm: {
        loginType: "cms",
        phone: "",
        code: "",
        //租户ID
        tenantId: "000000",
        //部门ID
        deptId: "",
        //角色ID
        roleId: "",
        //验证码的索引
        key: "",
        //预加载白色背景
        // image: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
      },
      loginRules: {
        phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        code: [{ required: true, trigger: "blur", validator: validateCode }]
      }
    };
  },
  created() {
    this.msgText = this.config.MSGINIT;
    this.msgTime = this.config.MSGTIME;
  },
  mounted() { },
  computed: {
    ...mapGetters(["tagWel", "userInfo"]),
    config() {
      return {
        MSGINIT: '发送验证码',
        MSGSCUCCESS: '秒后重发',
        MSGTIME: 60
      };
    }
  },
  props: [],
  methods: {
    handleSend() {
      if (this.msgKey) return;
      this.msgText = this.msgTime + this.config.MSGSCUCCESS;
      this.msgKey = true;
      const time = setInterval(() => {
        this.msgTime--;
        this.msgText = this.msgTime + this.config.MSGSCUCCESS;
        if (this.msgTime === 0) {
          this.msgTime = this.config.MSGTIME;
          this.msgText = this.config.MSGINIT;
          this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
      sendPhoneLoginValidate(this.loginForm.phone).then(res => {
        const data = res.data.data;
        if (data.success) {
          this.loginForm.key = data.id;
          this.$message.success(res.data.msg);
        }
      }).catch((err) => {
        this.$message.error(err.msg || err.message);
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$store.dispatch("LoginByPhone", this.loginForm).then(() => {
            const auditState = this.userInfo.auditState;
            //  如果未提交或未通过跳转实名认证页面
            if (auditState === AuditStateEnum.NOT_SUBMIT.value || auditState === AuditStateEnum.FAIL.value) {
              this.$router.push({ path: "/biz/user/company" })
            } else {
              this.$router.push({ path: this.tagWel.value });
            }
          });
        }
      });
    }
  }
};
</script>

<style>
.msg-text {
  display: block;
  width: 60px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.msg-text.display {
  color: #ccc;
}
</style>
