<template>
  <div>
    <div>
        <template>
            <el-table :data="allData" style="width: 100%" @row-click="jumpDetail">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="type" label="类型" width="180">
                    <template slot-scope="scope">
                        {{scope.row.type==1?'标的':scope.row.type==2?'拍卖会':'拍卖公告'}}
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题" width="180"></el-table-column>
                <el-table-column prop="content" label="内容">
                    <template slot-scope="scope">
                        <span class="tab-content">{{scope.row.content}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </div>
    <div style="margin-right:100px">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="allPage.currentPage"
            :page-sizes="[10, 20, 30, 50]" :page-size="allPage.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="allPage.total">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import {getAllList} from '@/api/biz/auction/auctionLot'
export default {
  name: "TopView",
  data() {
    return {
      allPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      allData: [],
    }
  },
  computed: {
    queryParam() {
      return this.$route.query;
    }
  },
  watch: {
    queryParam(){
      this.queryAllData();
    },
  },
  methods: {
    jumpDetail(row){
      if(row.type==1){
        this.$router.push({path:'/lotDetail',query:{lotId:row.id}});
      }else if(row.type==2){
        this.$router.push({path:'/meetingDetial',query:{meetingId:row.id,activeType:1}});        
      }else if(row.type==3){
        this.$router.push({path:'/bulletinDetail',query:{bulletinId:row.id}});        
      }
    },
    handleSizeChange(val){
      this.allPage.pageSize = val;
      this.queryAllData();
    },
    handleCurrentChange(val){
      this.allPage.currentPage = val;
      this.queryAllData();
    },
    queryAllData(){
      let page = this.allPage;
      getAllList(page.currentPage, page.pageSize, Object.assign({},this.queryParam)).then(res => {
        const data = res.data.data;
        this.allPage.total = data.total;
        this.allData = data.records;
      });
    }
  },
  created(){
    this.queryAllData();
  }
}
</script>

<style>
  .tab-content{
    margin-top: 12px;
    color: rgb(155,155,155);
    width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>