<template>
  <div>
    <rightFixed></rightFixed>
    <div style="    display: flex;
    flex-direction: row;">
      <div>
        <div class="quick-key-span">
          <div class="main-type-div">
            <img class="main-type-img" src="../../public/img/type.png">
            <span class="main-type-title">标的类型</span>
          </div>
          <div>
            <ul class="main-type-ul">
              <li v-for="(t, index) in bidTypeKeyDict" :key="index" class="main-type-li">
                <span class="main-type-li-span cursor-pointer-text" @click="quickSearchBulletin(t.dictKey, 2)">{{ t.dictValue }}</span>
              </li>
            </ul>
          </div>
          <div class="main-type-div">
            <img class="main-type-img" src="../../public/img/place.png">
            <span class="main-type-title">标的所在地</span>
          </div>
          <div>
            <ul class="main-type-ul">
              <li v-for="(t, index) in bidPlaceKeyDict" :key="index" class="main-type-li">
                <span class="main-type-li-span cursor-pointer-text" @click="quickSearchBulletin(t.key, 1)">{{ t.title }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="slide-show-div">
        <img class="slide-show-img" :src="activeSlideShowImage">
        <div class="slide-show-bottom-div">
          <span class="slide-show-text-span">热烈庆祝山东英大入驻齐鲁观水电子商务平台</span>
          <ul class="slide-show-click-ul">
            <li class="slide-show-click-li cursor-pointer-text" v-for="p in slideshowList" :key="p.id">
              <div class="slide-show-circle" v-on:mouseover='changeSlideImg(p)'></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="slide-show-right">
        <div v-if="token&&userInfo.account" class="bidder-login-div cursor-pointer-div" @click="toPersonal">
          <img class="bidder-login-img" src="../../public/img/bidderlogin.png">
          <span class="bidder-login-span">个人中心</span>
        </div>
        <div v-else class="bidder-login-div cursor-pointer-div" @click="bidderLogin">
          <img class="bidder-login-img" src="../../public/img/bidderlogin.png">
          <span class="bidder-login-span">竞买人登录</span>
        </div>
        <div class="company-login-div cursor-pointer-div" @click="companyLogin">
          <img class="bidder-login-img" src="../../public/img/companylogin.png">
          <span class="bidder-login-span">拍卖人登录</span>
        </div>
        <div class="help-div">
          <div class="first-help-div">
            <span class="help-top-text">帮助 / 下载中心</span>
            <span class="help-top-button cursor-pointer-div" @click="jumpHelp('')">更多 ></span>
          </div>
          <div class="list-help-div">
            <img src="../../public/img/jplc.png">
            <span class="list-help-div-text cursor-pointer-text" @click="jumpHelp('竞拍流程')">竞拍流程</span>
            <img src="../../public/img/sfgz.png">
            <span class="list-help-div-text cursor-pointer-text" @click="jumpHelp('收费规则')">收费规则</span>
          </div>
          <div class="list-help-div">
            <img src="../../public/img/chujiaguize.png">
            <span class="list-help-div-text cursor-pointer-text" @click="jumpHelp('出价规则')">出价规则</span>
            <img src="../../public/img/chengjiaoguize.png">
            <span class="list-help-div-text cursor-pointer-text" @click="jumpHelp('成交规则')">成交规则</span>
          </div>
          <div class="list-help-div">
            <img src="../../public/img/zlxz.png">
            <span class="list-help-div-text cursor-pointer-text" @click="jumpHelp('资料下载')">资料下载</span>
          </div>
        </div>
        <div class="contant-phone-div">
          <div class="first-contant-phone-div">
            <img class="contant-phone-div-img" src="../../public/img/zxkf.png">
            <span class="first-contant-phone-span">联系客服</span>
          </div>
          <div class="first-contant-phone-div">
            <span class="second-contant-phone-span">0531-67897879</span>
          </div>
          <!-- <div class="first-contant-phone-div">
            <img class="contant-phone-div-img" src="../../public/img/zxkf.png">
            <span class="third-contant-phone-span cursor-pointer-text" @click="developing">在线客服</span>
          </div> -->
        </div>
      </div>
    </div>
    <div>
      <img class="liucheng-img" src="../../public/img/liucheng.png">
    </div>
    <div class="meeting-list-div">
      <div class="meeting-list-div-title">
        <img src="../../public/img/loneline.png">
        <img class="list-div-short-line" src="../../public/img/shortline.png">
        <span class="div-list-title cursor-pointer-text" @click="toMeetingList()">拍卖会列表</span>
      </div>
      <div>
        <img class="meeting-list-button cursor-pointer-text" src="../../public/img/jiantou.png" @click="meetingListTimeComputed(1)">
        <div class="meeting-list-time">
          <div class="meeting-list-time-span cursor-pointer-text" @click="toMeetingList(-1)">
            <div class="meeting-list-span-timediv"><span>已结束场次</span></div>
            <div class="meeting-list-span-countdiv"><span>{{ meetingCountArray[0] }}场</span></div>
          </div>
          <div class="meeting-list-time-span meeting-list-time-border cursor-pointer-text" v-for="m in 6" :key="m" @click="toMeetingList(m + meetingListTimeCount)">
            <div class="meeting-list-span-timediv"><span>{{ formatMeetingListTime(meetingListTimeCount + (m - 1)) }}</span>
            </div>
            <div class="meeting-list-span-countdiv"><span>{{ meetingCountArray[m] }}场</span></div>
          </div>
        </div>
        <img class="meeting-list-button meeting-list-right-button cursor-pointer-text" src="../../public/img/jiantou.png" @click="meetingListTimeComputed(2)">
      </div>
      <div>
        <ul>
          <li v-for="n in meetingData" :key='n._id' class="meeting-list-detail-li" @click="meetingJump(n)">
            <div class="meeting-list-detail-div cursor-pointer-div">
              <div class="meeting-detail-img-div">
                <img class="meeting-detail-img" :src="n.meetingCover[0].link" :alt="n.meetingName">
              </div>
              <div class="meeting-detail-name">
                <span class="meeting-detail-name-span">{{ n.meetingName }}</span>
              </div>
              <div class="meeting-detail-time-main">
                <img class="meeting-time-img" src="../../public/img/time.png">
                <span class="meeting-detail-start-time">{{ n.startAuctionTime }} <span style="color:rgba(214,54,59,1)">{{ n.meetingStatus == 10 ? '未开始' : (n.meetingStatus == 20 ? '进行中' : '已结束')
                }}</span></span>
              </div>
              <div>
                <span class="meeting-detail-type">{{ n.meetingType == 1 ? '同步拍' : '网络拍' }}</span>
                <span class="meeting-detail-company show-ellipsis" :title="n.companyName">{{ n.companyName }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="head_msg_s">
      <div class="announcement">招采公告</div>
      <div class="content_msg">
        <div class="msg_l">
          <div class="msg_text" :class="index == activeIndex ? 'active' : ''" v-for="(i, index)  in allEngineer" :key="index" @click="chooseClick(index, i.arrtibutes)">{{ i.label }}</div>
        </div>
        <div class="msg_r">
          <div class="more_m">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                所有地区<i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-cascader-panel :options="region" @change="changes1"></el-cascader-panel>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="more" @click="jump">更多></div>
          <el-tabs v-model="activeName" @tab-click="handleClick1" type="border-card">
            <el-tab-pane :label="i.name" :name="index + 1 + ''" v-for="(i, index) in region_bottom" :key="index">
              <div style="width: 100%;height: 100%;display: flex;justify-content: space-around;">
                <div style="width: 49%;height: 100%;cursor: pointer;">
                  <el-table :data="planPurchases" @row-click="table1">
                    <el-table-column prop="bulletinName" label="项目名称" :show-overflow-tooltip="true" width="250" align="center">
                    </el-table-column>
                    <el-table-column prop="regionId" label="所在地" align="center">
                      <template v-slot="scope">
                        <div>{{ getArrName(scope.row.regionId, region) }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="publishTime" label="发布时间" align="center">
                    </el-table-column>
                    <el-table-column prop="address" label="预算" align="center">
                      <template v-slot="scope">
                        <div>{{ scope.row.budget ? scope.row.budget + '万元' : '暂无数据' }}</div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div style="width: 2px;height:auto;background:rgba(212,48,48,1)"></div>
                <div style="width: 49%;height: 100%;cursor: pointer;">
                  <el-table :data="planPurchases1" @row-click="table1">
                    <el-table-column prop="bulletinName" label="项目名称" :show-overflow-tooltip="true" width="250" align="center">
                    </el-table-column>
                    <el-table-column prop="regionId" label="所在地" align="center">
                      <template v-slot="scope">
                        <div>{{ getArrName(scope.row.regionId, region) }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="publishTime" label="发布时间" align="center">
                    </el-table-column>
                    <el-table-column prop="address" label="预算" align="center">
                      <template v-slot="scope">
                        <div>{{ scope.row.budget ? scope.row.budget + '万元' : '暂无数据' }}</div>
                      </template>
                    </el-table-column>
                  </el-table>

                </div>
              </div>
            </el-tab-pane>
            <el-pagination align="right" style="margin-top: 20px;" background @size-change="sizeChange" @current-change="currentChange" :current-page="page.currentPage" :page-sizes="[10, 20, 40]"
              :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
            </el-pagination>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="advertising-div">
      <div class="advertising-item-div">
        <AdvertisintView :showType='2' :titleName='"平台客户"' :jumpUrl='"/"'></AdvertisintView>
      </div>
      <div class="advertising-item-div" style="margin-left:28px;">
        <AdvertisintView :showType='3' :titleName='"友情链接"' :jumpUrl='"/"'></AdvertisintView>
      </div>
    </div>
  </div>
</template>

<script>
import { getCmsMeeting, cmsQueryMeetingCount } from "@/api/biz/auction/auctionMeeting";
import { mapGetters } from "vuex";
import inspect from '@/api/validate.js'
import { cmsGetUrl } from '@/api/system/param';
import { getBizDictByCode } from '@/api/system/dict'
import { getQuickTree } from "@/api/base/region";
import { findByAtiId, findTenderBulletinType, pageFindTenderBulletin, findByAzcId } from "@/api/user";
import { cmsListByType } from "@/api/biz/columnBulletin/cmsContentad"
import AdvertisintView from "@/components/advertising/index.vue"
import rightFixed from '@/components/rightFixd'
import { getToken } from "@/util/auth";

export default {
  name: "MainView",
  computed: { 
    token() {
      return getToken();
    },
    ...mapGetters(["userInfo"]) 
  },
  components: { AdvertisintView, rightFixed },
  data() {
    return {
      meetingPage: {
        pageSize: 6,
        currentPage: 1,
        total: 0
      },
      meetingData: [],
      meetingLoading: false,
      bidTypeKeyDict: [],
      bidPlaceKeyDict: [],
      meetingListTimeCount: 0,
      slideshowList: [],
      activeSlideShowImage: '',
      meetingCountArray: [0, 0, 0, 0, 0, 0, 0],
      allEngineer: [],
      activeIndex: '0',
      activeName: '1',
      planPurchases: [],
      planPurchases1: [],
      region_bottom: [],
      region: [],
      regionID1: '',
      tab1: 3,
      pictureId: '',
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
    };
  },
  created() {
    this.getSlideData();
    this.getDict();
    this.onLoad(this.meetingPage);
    this.queryMeetingCount();
    // 获取招标项目种类
    findByAtiId().then(res => {
      res.data.data.unshift({ label: '全部公告', value: '', arrtibutes: { flagCode: '' } })
      this.allEngineer = res.data.data
    })
    findTenderBulletinType().then(res => {
      this.region_bottom = res.data.filter(item => item.type === 'B')
    })
    // 获得所有地区
    findByAzcId().then(res => {
      this.region = res.data.data
    })
    this.listFindTenderBulletin()

  },
  methods: {
    listFindTenderBulletin() {
      pageFindTenderBulletin({
        bulletinType: this.tab1,
        tdrPrjClassifyCode: this.pictureId,
        regionId: this.regionID1,
        page: this.page.currentPage,
        rows: this.page.pageSize
      }).then(res => {
        this.planPurchases = []
        this.planPurchases1 = []
        this.page.total = 0
        if (res.data.rows != null) {
          for (let i = 0; i < res.data.rows.length; i++) {
            if (res.data.rows[i].publishTime != null) {
              res.data.rows[i].publishTime = res.data.rows[i].publishTime.slice(0, 10).replace(/-/g, "/")
            }
          }
          this.page.total = res.data.total
          this.planPurchases = res.data.rows.slice(0, Math.ceil(res.data.rows.length / 2))
          this.planPurchases1 = res.data.rows.slice(Math.ceil(res.data.rows.length / 2, 0))
        }
      })
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.listFindTenderBulletin()
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage
      this.listFindTenderBulletin()
    },
    // 地区处理
    getArrName(val, child) {
      const resp = inspect.getFullTreeName(val, child)
      if (resp.valArray) {
        return resp.valArray[1]
      }
    },
    table1(row) {
      cmsGetUrl({ paramKey: 'bidCmsUrl' }).then(res => {
        let url = res.data.data.paramValue
        window.open(url + `detail/procurementDetail?bulletinType=${this.tab1}&id=${row.id}`, '_blank');
      })
    },
    handleClick1(tab) {
      console.log(this.tab1);
      this.tab1 = +tab.index + 3
      this.listFindTenderBulletin()
    },
    // 跳转招采公告
    jump() {
      localStorage.setItem('isactive', 1)
      cmsGetUrl({ paramKey: 'bidCmsUrl' }).then(res => {
        let url = res.data.data.paramValue
        window.open(url + `procurementAnnouncement`, '_blank');
      })
    },
    changes1(n) {
      this.regionID1 = n[n.length - 1]
      this.listFindTenderBulletin()
    },
    chooseClick(index, id) {
      this.activeIndex = index
      this.pictureId = id.flagCode
      this.listFindTenderBulletin()
    },
    handleClick() { },
    getSlideData() {
      cmsListByType({ type: 1 }).then(res => {
        this.slideshowList = res.data.data;
        this.activeSlideShowImage = this.slideshowList[0].coverImg
      });
    },
    quickSearchBulletin(value, type) {
      let param = {
        region: type == 1 ? value : null,
        type: type == 2 ? value : null
      }
      this.$router.push({ path: '/bulletin', query: param })
    },
    getDict() {
      getBizDictByCode('bulletin_type').then(res => {
        this.bidTypeKeyDict = [];
        let data = res.data.data;
        for (let i = 0; i < 8; i++) {
          this.bidTypeKeyDict.push(data[i])
        }
      });
      getQuickTree(this.topCode).then(res => {
        let treeData = res.data.data;
        this.bidPlaceKeyDict = [];
        for (let i = 0; i < 31; i++) {
          this.bidPlaceKeyDict.push(treeData[i]);
        }
      });
    },
    queryMeetingCount() {
      cmsQueryMeetingCount(this.meetingListTimeCount).then(res => {
        this.meetingCountArray = res.data.data;
      });
    },
    toPersonal() {
      this.$router.push('/personalCenter/accountSecurity/accountShow');
    },
    changeSlideImg(p) {
      this.activeSlideShowImage = p.coverImg;
    },
    jumpHelp(type) {
      this.$router.push({ path: "/help",query: {type} })
    },
    developing() {
      this.$router.push({ path: "/helloWorld" })
    },
    companyLogin() {
      cmsGetUrl({ paramKey: 'bizLoginUrl' }).then(res => {
        let url = res.data.data.paramValue
        window.open(url)
      })
    },
    bidderLogin() {
      this.$router.push({ path: "/login" })
    },
    headSerach(val) {
      this.$emit('headSerach', val)
    },
    toMeetingList(timeCount) {
      this.$router.push({ path: "/meeting", query: { timeCount: timeCount } })
    },
    formatMeetingListTime(val) {
      let today = new Date().getTime();
      let show = new Date(today + val * (24 * 60 * 60 * 1000));
      return (show.getMonth() + 1) + '月' + show.getDate() + '日';
    },
    meetingListTimeComputed(type) {
      if (type == 1) {
        if (this.meetingListTimeCount > 0) {
          this.meetingListTimeCount--
          this.queryMeetingCount();
        }
      } else if (type == 2) {
        this.meetingListTimeCount++;
        this.queryMeetingCount();
      }
    },
    meetingJump(meeting) {
      this.$router.push({ path: "/meetingDetial", query: { meetingId: meeting.id, activeType: 1 } })
      // this.$router.push({path:"/lots", query: {meetingId: meeting.id}})
    },
    onLoad(page) {
      this.meetingLoading = true;
      getCmsMeeting(page.currentPage, page.pageSize).then(res => {
        const data = res.data.data;
        this.meetingPage.total = data.total;
        this.meetingData = data.records;
        this.meetingLoading = false;
      });
    }
  }
}
</script>
<style scoped>
::v-deep .el-tabs__nav-scroll {
  margin-top: 0;
  margin-left: 0;
}

::v-deep .el-table__row {
  font-size: 16px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

::v-deep .el-cascader-panel {
  z-index: 999;
  border-radius: 4px;
}

::v-deep .el-cascader-menu {
  border-right: none;
  background: #fff;
  z-index: 999;
}

::v-deep .el-cascader-panel.is-bordered {
  border: none;
}

::v-deep .el-carousel__button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

::v-deep.el-menu--horizontal>.el-menu-item.is-active {
  color: #fff !important;
  border-bottom-color: #fff !important;
}

::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: #014f80 !important;
  color: #fff !important;
  border-bottom-color: #fff !important;
}

::v-deep.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title:hover {
  background-color: #014f80 !important;
  color: #fff !important;
}

::v-deep.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #014f80 !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background: linear-gradient(180deg,
      rgba(0, 102, 166, 1) 0%,
      rgba(59, 133, 243, 1) 100%),
    rgba(0, 102, 166, 1);
  color: #fff;
  border: none;
  border-radius: 4px;
}

::v-deep.el-tabs--border-card {
  height: 100%;
}

::v-deep.el-tabs--border-card>.el-tabs__content {
  padding: 0;
}

::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #303133;
  color: #fff !important;
  background-color: #014f80 !important;
}

::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff;
  color: #303133;
  border-bottom-color: #fff !important;
  background-color: #014f80 !important;
  color: #fff !important;
}

::v-deep .el-table .el-table__cell {
  color: #303133;
}
</style>

<style scoped lang="scss">
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

.contenr_foot,
.head_msg,
.head_msg_s {
  ::v-deep .is-active::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.active {
  background: linear-gradient(180deg,
      rgba(0, 102, 166, 1) 0%,
      rgba(59, 133, 243, 1) 100%),
    rgba(204, 204, 204, 1);
  color: #fff !important;
  border-radius: 4px;
}

.content_msg {
  padding: 10px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.head_msg_s {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #eef2f5;
  padding: 10px 0;
}

.content_msg_t {
  width: 1300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.announcement {
  width: 100%;
  height: 70px;
  background: url(../assets/bgc.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
  font-size: 24px;
  line-height: 70px;
  color: rgba(245, 197, 139, 1);
  text-align: center;
}

.msg_r {
  width: 92%;
  height: 100%;
  position: relative;
}

.msg_l {
  width: 7%;
  height: 100%;
}

.more_m {
  font-size: 14px;
  position: absolute;
  z-index: 99;
  right: 80px;
  top: 10px;
  color: rgba(0, 102, 166, 1);
  cursor: pointer;
}

::v-deep.el-dropdown {
  color: rgba(0, 102, 166, 1);
}

.content_msg_pay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.more {
  width: 56px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(246, 193, 101, 1) 0%,
      rgba(224, 168, 50, 1) 100%);
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 20px;
  border-radius: 5px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.msg_text {
  width: 100px;
  height: 50px;
  border-radius: 4px;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.25);
  text-align: center;
  line-height: 50px;
  margin-bottom: 20px;
  cursor: pointer;
}

.advertising-item-div {
  display: inline-block;
  width: 636px;
}

.advertising-div {
  width: 1300px;
  height: 220px;
  padding-bottom: 30px;
  display: flex;
}

.meeting-detail-name-span {
  display: inline-block;
  width: 360px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slide-show-text-span {
  display: inline-block;
  margin-left: 30px;
  margin-top: 26px;
  width: 500px;
  height: 28px;
  opacity: 1;
  /** 文本1 */
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(212, 48, 48, 1);
}

.slide-show-bottom-div {
  height: 70px;
  position: relative;
  z-index: 2;
  width: 680px;
  opacity: 1;
  background: rgba(214, 214, 214, 0.55);
}

.slide-show-click-li {
  margin-top: 30px;
  margin-left: 20px;
  float: left;
}

.slide-show-click-ul {
  margin-right: 30px;
  float: right;
}

.slide-show-img {
  opacity: 1;
  width: 680px;
  height: 364px;
}

.slide-show-circle {
  width: 20px;
  height: 20px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
}

.meeting-detail-company {
  margin-top: 13px;
  margin-right: 15px;
  text-align: right;
  float: right;
  width: 60%;
  height: 18px;
  opacity: 1;
  /** 文本1 */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(153, 153, 153, 1);
}

.meeting-detail-type {
  display: inline-block;
  margin-left: 15px;
  margin-top: 10px;
  /* width: 48px; */
  height: 24px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(0, 0, 0, 1);
}

.meeting-detail-time-main {
  margin-top: 17px;
}

.meeting-detail-start-time {
  margin-left: 5px;
  width: 275px;
  height: 24px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
}

.meeting-time-img {
  margin-top: -5px;
  height: 20px;
  margin-left: 15px;
}

.meeting-list-detail-li {
  float: left;
  margin-top: 20px;
  margin-left: 25px;
}

.meeting-detail-name {
  margin-left: 15px;
  margin-top: 15px;
  /* width: 240px; */
  height: 27px;
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(0, 0, 0, 1);
}

.meeting-detail-img-div {
  /* border-radius: 10px 10px, 0px, 0px; */
}

.meeting-detail-img {
  width: 400px;
  height: 180px;
  opacity: 1;
  border-radius: 10px 10px 0px 0px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
}

.meeting-list-detail-div {
  width: 400px;
  height: 307px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
}

.meeting-list-span-timediv {
  margin-top: -5px;
  text-align: center;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(0, 0, 0, 1);
}

.meeting-list-span-countdiv {
  text-align: center;
  height: 21px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(102, 102, 102, 1);
}

.cursor-pointer-div:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
}

.cursor-pointer-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.meeting-list-right-button {
  transform: rotate(180deg);
  margin-left: 20px;
}

.meeting-list-time-border {
  border-left: 1px solid rgba(215, 215, 215, 1);
}

.meeting-list-time-span {
  text-align: center;
  float: left;
  width: 164px;
  height: 30px;
  margin-top: 15px;
}

.meeting-list-time {
  float: left;
  margin-left: 20px;
  margin-top: 18px;
  width: 1160px;
  height: 60px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
}

.meeting-list-button {
  float: left;
  margin-top: 23px;
  width: 50px;
  height: 50px;
}

.div-list-title {
  margin-left: 11px;
  width: 110px;
  height: 32px;
  opacity: 1;
  /** 文本1 */
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 31.86px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.list-div-short-line {
  margin-top: 10px;
  margin-left: 5px;
}

.meeting-list-div-title {
  height: 30px;
}

.meeting-list-div {
  margin-top: 30px;
  height: 800px;
}

.liucheng-img {
  margin-top: 20px;
  width: 1300px;
  height: 60px;
}

.contant-phone-div-img {
  /* margin-left: 90px; */
  width: 25px;
  margin-bottom: 8px;
  margin-right: 10px;
}

.third-contant-phone-span {
  /* margin-left: 6px;
  width: 57px; */
  height: 20px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(0, 0, 0, 1);
}

.second-contant-phone-span {
  display: inline-block;
  margin-top: 3px;
  /* margin-left: 70px;
  width: 134px; */
  height: 22px;
  opacity: 1;
  /** 文本1 */
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: rgba(0, 102, 166, 1);
}

.first-contant-phone-span {
  display: inline-block;
  margin-top: 25px;
  /* margin-left: 100px;
  width: 64px; */
  height: 22px;
  opacity: 1;
  /** 文本1 */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(0, 0, 0, 1);
}

.first-contant-phone-div {
  text-align: center;
  height: 60px;
}

.list-help-div-text {
  display: inline-block;
  margin-left: 5px;
  width: 100px;
  height: 17px;
  opacity: 1;
  /** 文本1 */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.38px;
  color: rgba(102, 102, 102, 1);
  text-align: left;
}

.list-help-div {
  margin-top: 11px;
  margin-left: 23px;
}

.help-top-button {
  display: inline-block;
  margin-left: 50px;
  text-align: center;
  width: 56px;
  height: 30px;
  opacity: 1;
  border-radius: 4px;
  background: linear-gradient(180deg,
      rgba(246, 193, 101, 1) 0%,
      rgba(224, 168, 50, 1) 100%);
  line-height: 30px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}

.help-top-text {
  margin-top: 20px;
  width: 114px;
  height: 22px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 66px;
  color: rgba(0, 0, 0, 1);
}

.first-help-div {
  margin-left: 23px;
  height: 60px;
  width: 220px;
  border-bottom: solid 1px rgba(233, 237, 242, 1);
}

.bidder-login-span {
  line-height: 60px;
  margin-left: 10px;
  width: 110px;
  height: 60px;
  opacity: 1;
  /** 文本1 */
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}

.bidder-login-img {
  margin-top: -8px;
  margin-left: 58px;
  width: 28px;
  height: 28px;
}

.bidder-login-div {
  margin-bottom: 10px;
  height: 60px;
  opacity: 1;
  border-radius: 4px;
  background: linear-gradient(90deg,
      rgba(0, 158, 197, 1) 0%,
      rgba(62, 196, 233, 1) 100%);
}

.company-login-div {
  margin-bottom: 15px;
  height: 60px;
  opacity: 1;
  border-radius: 4px;
  background: linear-gradient(90deg,
      rgba(0, 158, 197, 1) 0%,
      rgba(62, 196, 233, 1) 100%);
}

.help-div {
  height: 160px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
}

.contant-phone-div {
  height: 114px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
}

.slide-show-right {
  margin-left: 20px;
  width: 266px;
  height: 434px;
  float: left;
}

.slide-show-div {
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  float: left;
  width: 680px;
  height: 434px;
  opacity: 1;
}

.main-type-place {
  width: 24px;
  height: 24px;
  opacity: 1;
}

.main-type-li-span {
  white-space: nowrap;
  /*强制span不换行*/
  text-overflow: ellipsis;
  /*超出部分以点号代替*/
  overflow: hidden;
  width: 42px;
  height: 21px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  vertical-align: top;
}

.main-type-ul {
  /* margin-top: 12px; */
  margin-left: 25px;
}

.main-type-li {
  white-space: nowrap;
  /*强制span不换行*/
  text-overflow: ellipsis;
  /*超出部分以点号代替*/
  overflow: hidden;
  margin-bottom: 10px;
  width: 65px;
  max-width: 65px;
  display: block;
  float: left;
}

.main-type-div {
  margin: 15px 20px 15px 20px;
}

.main-type-title {
  margin-left: 5px;
  width: 72px;
  height: 27px;
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28.06px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.main-type-img {
  width: 30px;
  height: 30px;
  opacity: 1;
}

.quick-key-span {
  float: left;
  left: 310px;
  top: 230px;
  width: 310px;
  height: 434px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(0, 102, 166, 1);
}

.home-li {
  float: left;
  margin-left: 25px;
  background-color: rgb(255, 255, 255);
}

.home-ul {
  background-color: rgb(245, 245, 245);
}
</style>