<template>
    <div class="sifa-cont-right">
      <div class="personbox">
        <h2>我的参拍</h2>
        <div class="sub-page"></div>
        <div>
            <el-select v-model="value" clearable placeholder="请选择委托方式" class="selectWay" @change="entrustWayChange">
              <el-option
                v-for="item in options"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
                class="selectSearch">
              </el-option>
            </el-select>
            <el-tabs v-if="token&&this.userInfo&&this.userInfo.access_token" v-model="activeName">
                <el-tab-pane label="进行中" name="1">
                  <my-bid-table ref="childRef" v-if="activeName == 1" :entrustWay="value" :auctionStatus="activeName"></my-bid-table>
                </el-tab-pane>
                <el-tab-pane label="已结束" name="3">
                  <my-bid-table ref="childRef" v-if="activeName == 3" :entrustWay="value" :auctionStatus="activeName"></my-bid-table>
                </el-tab-pane>
                <el-tab-pane label="已拍下" name="99">
                  <my-bid-table ref="childRef" v-if="activeName == 99" :entrustWay="value" :auctionStatus="activeName"></my-bid-table>
                </el-tab-pane>
        </el-tabs>
        </div>
      </div>
    </div>
</template>
  
<script>
  import {getDictionary} from "@/api/system/dictbiz";
  import {mapGetters} from "vuex";
  import myBidTable from "./myBidTable.vue";
  import {getToken} from '@/util/auth'
  

export default {
  name: 'myBid',
  computed: { 
    token() {
      return getToken();
    },
    ...mapGetters(["userInfo"]) 
  },
  components: {
    myBidTable
    },
  data(){
    return {
      activeName: '1',
      options: [],
      value: '',
      childPage: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
    }
  },
  created(){
    if(getToken()&&this.userInfo&&this.userInfo.user_id){
      this.init();
    }else{
      this.$router.push("/login")
    }    
  },
  methods:{
    init(){
      getDictionary({ code: 'entrust_way' }).then((res) => {
        this.options = res.data.data;
      })
    },
    entrustWayChange(entrustWay){
      console.log(1111,entrustWay)
      let params = {
        entrustWay: entrustWay,
        auctionStatus: this.activeName
      }
      this.$refs.childRef.onLoad(this.childPage,params);
    },
  }
}
</script>

<style>
.personbox {
  margin: 0 0 40px;
  display: none;
  background-color: #fff;
  display: block;
}
.personbox h2 {
  height: 30px;
  line-height: 30px;
  background: #e9e9e9;
  color: #424242;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.tabDiv {
  margin-top: 20px;
}
.el-tabs__nav-scroll {
  margin-top: 1%;
  margin-left: 5%;
}
.selectWay{
  margin-top: 2%;
  margin-left: 60%;
}
</style>