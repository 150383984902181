<template>
        <div class="show" >
            <div class="user-info">
                <div class="information">
                    <h3 class="subtitle">个人信息</h3>
                </div>
                <ul class="info-wrap">
                    <li class="infoList">
                        <label class="subinfo-label">用户名:</label>
                        <span class="subinfo-value">{{ data.account }}</span>
                        <span>（用户名即为登录账号，请牢记～）</span>
                    </li>
                    <li class="infoList">
                        <label class="subinfo-label" v-if="userInfo.role_name == 'bidder'">姓名:</label>
                        <label class="subinfo-label" v-else>机构名称:</label>
                        <span class="subinfo-value">{{ userInfo.role_name == 'bidder'?data.realName:companyData.name }}</span>
                    </li>
                    <li class="infoList">
                        <label class="subinfo-label">联系电话:</label>
                        <span class="subinfo-value">{{ data.phone?data.phone:companyData.contractPersonPhone }}</span>
                    </li>
                    <li class="infoList" v-if="userInfo.role_name != 'bidder'">
                        <label class="subinfo-label">邮箱:</label>
                        <span class="subinfo-value">{{ data.email }}</span>
                    </li>
                </ul>
            </div>
            <div class="account-auth">
                <h3 class="subtitle">安全服务</h3>
                <ul class="security-wrap">
                    <li class="securitylist">
                        <label class="security-label">实名认证</label>
                        <span class="security-tip">用于提升账号的安全性和信任级别。实名认证通过后，方可进行报名参拍。</span>
                        <span class="security-btn" v-if="data.isAuth == 0 " @click="nowAuth('add')">立即实名</span>
                        <span class="security-btn" v-else @click="nowAuth('view')">查看</span>
                    </li>
                    <li class="securitylist">
                        <label class="security-label">登录密码</label>
                        <span class="security-tip">互联网账号存在被盗风险，建议您定期更改密码以保护账户安全。</span>
                        <span class="security-btn" @click="edit(1)">修改</span>
                    </li>
                    <li class="securitylist">
                        <label class="security-label">手机绑定</label>
                        <span class="security-tip">可用于接收短信提醒，快速找回密码等。</span>
                        <span class="security-btn" v-if="data.phone" @click="edit(2)">修改</span>
                        <span class="security-btn" v-else @click="edit(2)">立即绑定</span>
                    </li>
                    <li class="securitylist">
                        <label class="security-label">邮箱绑定</label>
                        <span class="security-tip">可作为登录账号，可用于快速找回登录密码。</span>
                        <span class="security-btn" v-if="data.email" @click="edit(3)">修改</span>
                        <span class="security-btn" v-else @click="edit(3)">立即绑定</span>
                    </li>
                </ul>
            </div>
        </div>

</template>
  
<script>
import {mapGetters} from "vuex";
import {getUserInfo} from '@/api/system/user';
import {getDetail} from '@/api/biz/user/company'
import {getToken} from '@/util/auth'
  export default {
    name: 'accountShow',
    computed: {...mapGetters(["userInfo"])},
    created(){
        if(getToken()){
            this.getUserInfo();
        }else{
            this.$router.push("/login")
        }
    },
    data(){
      return {
        password: true,
        data: {},
        companyData: {},
      }
    },
    methods:{
        getUserInfo(){
            getUserInfo().then((res) => {
                this.data = res.data.data;
                console.log('data---->', this.data)
            })
            if(this.userInfo.isAuth!=0){
                getDetail().then(res=>{
                    this.companyData = res.data.data;
                })
            }
        },
        nowAuth(type){
            console.log('实名认证方法', type)
            this.$router.push({
                path: "/personalCenter/accountSecurity/authForm",
                query: {
                    type: type,
                    role: this.userInfo.role_name
                }
            })
        },
        edit(type){
            console.log('进入编辑页面')
            this.$router.push({path: "/personalCenter/accountSecurity/accountEdit",query: {type: type}})
        }
    }
  }
</script>
  
<style>
.information {
    width: 100%;
    overflow: hidden;
}
.subtitle {
    padding-top: 20px;
    font-size: 16px;
    color: #333;
    padding-left: 40px;
    float: left;
}
.info-wrap {
    padding: 20px 0;
    border-bottom: 1px solid #d7d7d7;
}
.infoList {
    padding: 10px 0;
    font-size: 12px;
    color: #666;
    line-height: 16px;
}
.subinfo-label {
    width: 120px;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    font-size: 14px;
}
.subinfo-value {
    font-size: 14px;
    color: #333;
    margin-left: 10px;
}
.infolist span {
    margin-left: 10px;
}
.security-wrap {
    padding: 30px 30px 1px;
}
.securitylist {
    line-height: 70px;
    border-bottom: 1px dotted #d7d7d7;
    padding-left: 100px;
    font-size: 12px;
}
.security-label {
    font-size: 14px;
    color: #333;
}
.security-tip {
    color: #666;
    margin-left: 30px;
}
.security-btn {
    float: right;
    margin-right: 164px;
    color:#66b1ff;
    width: 50px;
    cursor: pointer;
}
</style>