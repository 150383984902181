<template>
  <div class="fixed">
    <a href="javascript:void(0);" target="_blank" @click.prevent="toRegister">
      <div class="right">
        <div><img src="../assets/right/ico4.png" alt=""></div>
        <div class="text">用户注册</div>
      </div>
    </a>
    <a href="javascript:void(0);" target="_blank" @click.prevent="companyLogin">
      <div class="right">
        <div><img src="../assets/right/ico3.png" alt=""></div>
        <div class="text">系统登录</div>
      </div>
    </a>
    <div class="right">
      <el-popover placement="left" width="200" trigger="hover" content="咨询电话：0531-67894776" popper-class="popoverBackB">
        <div slot="reference" class="right">
          <div><img src="../assets/right/ico1.png" alt=""></div>
          <div class="text">咨询电话</div>
        </div>
      </el-popover>
    </div>
    <div class="right">
      <el-popover placement="left" width="200" trigger="hover" popper-class="popoverBackB" content="QQ:99513863">
        <div slot="reference" class="right">
          <div><img src="../assets/right/ico2.png" alt=""></div>
          <div class="text">在线客服</div>
        </div>
        <!-- <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=12345678&site=qq&menu=yes">点击我开始会话</a> -->
      </el-popover>
    </div>
  </div>
</template>
<script>
import { cmsGetUrl } from '@/api/system/param';
export default {
  name: 'rightFixed',
  created() {
  },
  methods: {
    toRegister() {
      cmsGetUrl({ paramKey: 'registerUrl' }).then(res => {
        let url = res.data.data.paramValue
        window.open(url)
      })
    },
    companyLogin() {
      cmsGetUrl({ paramKey: 'bizLoginUrl' }).then(res => {
        let url = res.data.data.paramValue
        window.open(url)
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  text-decoration: none;
}
a {
  color: white;
}
.fixed {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  .right {
    width: 74px;
    height: 74px;
    border-radius: 4px;
    cursor: pointer;
    background: linear-gradient(
      180deg,
      rgba(0, 102, 166, 1) 0%,
      rgba(59, 133, 243, 1) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
