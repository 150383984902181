<template>
  <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
    <el-form-item prop="username">
      <el-input size="small" @keyup.enter.native="handleLogin" v-model="loginForm.username" auto-complete="off"
        placeholder="请输入用户名">
        <i slot="prefix" class="el-icon-user" />
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input size="small" @keyup.enter.native="handleLogin" :type="passwordType" v-model="loginForm.password"
        auto-complete="off" placeholder="请输入密码">
        <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword" />
        <i slot="prefix" class="el-icon-lock" />
      </el-input>
    </el-form-item>
    <el-form-item v-if="this.website.captchaMode" prop="code">
      <el-row :span="24" style="display: flex;
    align-items: center;">
        <el-col :span="16">
          <el-input size="small" @keyup.enter.native="handleLogin" v-model="loginForm.code" auto-complete="off"
            placeholder="请输入验证码">
            <i slot="prefix" class="el-icon-check" />
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="login-code">
            <img style="width: 100%;!important" :src="loginForm.image" class="login-code-img" @click="refreshCode" />
          </div>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="rememberMe">记住密码</el-checkbox>
      <span class="forget-password" @click="findPassword">忘记密码</span>
      <i class="el-icon-warning-outline"></i>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" style="width: 100%;" @click.native.prevent="handleLogin"
        class="login-submit">登录
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from "vuex";
import { info } from "@/api/system/tenant";
import { getCaptcha } from "@/api/user";
import { getTopUrl } from "@/util/util";
import AuditStateEnum from "@/enum/AuditStateEnum";
import { getToken } from '@/util/auth'

export default {
  name: "UserLogin",
  data() {
    return {
      // tenantMode: this.website.tenantMode,
      loginForm: {
        loginType: "cms",
        //租户ID
        tenantId: "000000",
        //部门ID
        deptId: "",
        //角色ID
        roleId: "",
        //用户名
        username: "",
        //密码
        password: "",
        //账号类型
        type: "account",
        //验证码的值
        code: "",
        //验证码的索引
        key: "",
        //预加载白色背景
        image: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
      },
      loginRules: {
        tenantId: [
          { required: false, message: "请输入租户ID", trigger: "blur" }
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 1, message: "密码长度最少为6位", trigger: "blur" }
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]
      },
      passwordType: "password",
      userBox: false,
      userForm: {
        deptId: '',
        roleId: ''
      },
      rememberMe: true,
    };
  },
  created() {
    this.getTenant();
    this.refreshCode();
  },
  mounted() {
  },
  watch: {
    'loginForm.deptId'() {
      const column = this.findObject(this.userOption.column, "deptId");
      if (this.loginForm.deptId.includes(",")) {
        column.dicUrl = `/api/sino-system/dept/select?deptId=${this.loginForm.deptId}`;
        column.display = true;
      } else {
        column.dicUrl = '';
      }
    },
    'loginForm.roleId'() {
      const column = this.findObject(this.userOption.column, "roleId");
      if (this.loginForm.roleId.includes(",")) {
        column.dicUrl = `/api/sino-system/role/select?roleId=${this.loginForm.roleId}`;
        column.display = true;
      } else {
        column.dicUrl = '';
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  props: [],
  methods: {
    refreshCode() {
      if (this.website.captchaMode) {
        getCaptcha().then(res => {
          const data = res.data;
          this.loginForm.key = data.key;
          this.loginForm.image = data.image;
        })
      }
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    submitLogin(form, done) {
      if (form.deptId !== '') {
        this.loginForm.deptId = form.deptId;
      }
      if (form.roleId !== '') {
        this.loginForm.roleId = form.roleId;
      }
      this.handleLogin();
      done();
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '登录中,请稍后。。。',
            spinner: "el-icon-loading"
          });
          this.$store.dispatch("LoginByUsername", this.loginForm).then(() => {
            // if((this.userInfo.length==0)||(this.userInfo&&this.userInfo.role_name.indexOf('bid')==-1)){//非竞买登录
            //   this.$message({type: 'error', message: '账号或密码错误！'})
            //   this.$store.dispatch("LogOut").then(() => {
            //     loading.close();
            //   });
            //   return;
            // }
            if (this.website.switchMode) {
              const deptId = this.userInfo.dept_id;
              const roleId = this.userInfo.role_id;
              if (deptId.includes(",") || roleId.includes(",")) {
                this.loginForm.deptId = deptId;
                this.loginForm.roleId = roleId;
                this.userBox = true;
                this.$store.dispatch("LogOut").then(() => {
                  loading.close();
                });
                return false;
              }
            }
            console.log(this.userInfo)
            if (getToken()&&this.userInfo&&this.userInfo.user_id) {
              const auditState = this.userInfo.auditState;
              //  如果未提交或未通过跳转实名认证页面
              if (auditState === AuditStateEnum.NOT_SUBMIT.value || auditState === AuditStateEnum.FAIL.value) {
                this.$router.push({ path: "/biz/user/company" })
              } else {
                let activeLotId = sessionStorage.getItem('activeLotId');
                if (activeLotId > 0) {
                  sessionStorage.removeItem('activeLotId');
                  this.$router.push({ path: "/lotDetail", query: { lotId: activeLotId } })
                } else {
                  this.$router.push({ path: "/" });
                }
              }
            } else {
              this.refreshCode();
            }
            loading.close();
          }).catch(() => {
            loading.close();
            this.refreshCode();
          });
        }
      });
    },
    getTenant() {
      let domain = getTopUrl();
      info(domain).then(res => {
        const data = res.data;
        if (data.success && data.data.tenantId) {
          this.tenantMode = false;
          this.loginForm.tenantId = data.data.tenantId;
          this.$parent.$refs.login.style.backgroundImage = `url(${data.data.backgroundUrl})`;
        }
      })
    },
    findPassword() {
      this.$router.push({ path: "/findPassword" });
    }
  }
};
</script>

<style>
.forget-password {
  display: inline-block;
  margin-top: 9px;
  margin-left: 240px;
  width: 56px;
  height: 20px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(96, 98, 102, 1);
  text-align: left;
  vertical-align: top;
}
</style>
