<template>
  <div>
    <div class="bulletin-search-div">
      <div class="bulletin-search-row-div bulletin-search-bottom-border">
        <div class="bulletin-search-left-region-div">
            <span class="bulletin-search-label-span">标的所在地：</span>
            <span :class="!searchParam.region?'selected':'un-selected'" class="bulletin-search-item-all" @click="changeSearchParam(null, 1)">全部</span>
        </div>
        <div class="bulletin-search-region-div">
            <span :class="searchParam.region==t.id?'selected':'un-selected'" class="bulletin-serach-region-item"
            v-for="t in regionDict" :key="t.id" @click="changeSearchParam(t.id, 1)">{{t.title}}</span>
        </div>      
      </div>
      <div class="bulletin-search-row-div bulletin-search-bottom-border bulletin-search-bottom-un-border">
        <span class="bulletin-search-label-span">标的类型：</span>
        <span :class="!searchParam.type?'selected':'un-selected'" class="bulletin-search-item-all" @click="changeSearchParam(null, 2)">全部</span>
        <span :class="searchParam.type==t.dictKey?'selected':'un-selected'" class="bulletin-serach-type-item" 
            v-for="t in bulletinTypeDict" :key="t.id" @click="changeSearchParam(t.dictKey, 2)">{{t.dictValue}}</span>
      </div>
      <div class="bulletin-search-row-div bulletin-search-bottom-un-border">
        <span class="bulletin-search-label-span">拍卖会阶段：</span>
        <span :class="!searchParam.meetingStatus?'selected':'un-selected'" class="bulletin-search-item-all" @click="changeSearchParam(null, 3)">全部</span>
        <span :class="searchParam.meetingStatus==20?'selected':'un-selected'" class="bulletin-serach-type-item" @click="changeSearchParam(20, 3)">正在进行</span>
        <span :class="searchParam.meetingStatus==10?'selected':'un-selected'" class="bulletin-serach-type-item" @click="changeSearchParam(10, 3)">即将开始</span>
        <span :class="searchParam.meetingStatus==30?'selected':'un-selected'" class="bulletin-serach-type-item" @click="changeSearchParam(30, 3)">已结束</span>
        <span class="bulletin-search-input-span">
          <input class="search-title-input" v-model="searchParam.searchText" placeholder="请输入搜索关键字"/>
          <span class="search-title-button" @click="onLoad(page)">搜索</span>
        </span>
      </div>
    </div>
    <div class="bulletin-data-div">
      <div class="bulletin-data-item" v-for="b in bulletinData" :key="b.id" @click="jumpDetail(b)">
        <div class="bulletin-data-item-left">
          <div class="bulletin-data-item-top">
            <span class="item-top-type">拍卖公告</span>
            <span class="item-top-title">{{b.title}}</span>
            <span v-if="getStartOfDistance(b.auctionTime)>0" class="item-top-start">【距离开始{{getStartOfDistance(b.auctionTime)}}天】</span>
          </div>
          <div class="item-content-div">            
            <span class="item-content-value">
              <span class="item-content-lable">公告详情：</span>{{b.filterContent}}
            </span>
          </div>
          <div class="bulletin-data-item-bottom">
            <span class="item-bottom-out-span">
              <span class="item-bottom-label">公告类型：</span>
              <span class="item-bottom-value">{{b.typeName}}</span>
            </span>
            <span class="item-bottom-out-span">
              <!-- <span class="item-bottom-label">标的所在地：</span>
              <span class="item-bottom-value">山东省-青岛市</span> -->
            </span>
            <span class="item-bottom-out-span">
              <span class="item-bottom-label">公告来源：</span>
              <span class="item-bottom-value">齐鲁观水电子拍卖平台</span>
            </span>
            <span class="item-bottom-out-span">
              <span class="item-bottom-time">{{b.publishMediaTime}}</span>
            </span>
          </div>
        </div>
        <img class="bulletin-data-item-right" :src="b.coverImg">
      </div>
      <div class="bulletin-page-class">
        <div class="el-page-class">
          <el-pagination   @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.currentPage"
          :page-sizes="[10, 20, 30, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div style="height: 20px;"></div>
  </div>
</template>

<script>
import {getBizDictByCode} from '@/api/system/dict'
import {getLazyTree} from "@/api/base/region";
import {getCmsList} from '@/api/biz/auction/auctionBulletin'
export default {
  name: 'BulletinListView',
  data() {
    return {
      topCode: '00',
      bulletinData: [],
      bulletinTypeDict: [],
      meetingLoading: false,
      regionDict: [],
      searchParam: {
        searchText: null,
        region: null,
        type: null,
        meetingStatus: null,
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
    }
  },
  created(){
    this.getDict();
    this.searchParam.type = this.$route.query.type
    this.searchParam.region = this.$route.query.region
    this.onLoad(this.page);
  },
  methods: {
    jumpDetail(b){
      this.$router.push({path:"/bulletinDetail", query: {bulletinId: b.id}})
    },
    changeSearchParam(value, type){
      if(type==1 && value!=this.searchParam.region){        
        this.searchParam.region = value;    
      }else if(type==2 && value!=this.searchParam.type){        
        this.searchParam.type = value;  
      }else if(type==3 && value!=this.searchParam.meetingStatus){        
        this.searchParam.meetingStatus = value;       
      }else{
        return;
      }
      this.onLoad(this.page);
    },
    getStartOfDistance(date){
      return parseInt(((new Date(date).getTime()/(24*60*60*1000))-(new Date().getTime()/(24*60*60*1000))))
    },
    getDict(){
      getBizDictByCode('bulletin_type').then(res=>{
        this.bulletinTypeDict = res.data.data;
      });
      let treeData = [];
      getLazyTree(this.topCode).then(res => {
        treeData = res.data.data.map(item => {
          return {
            ...item,
            leaf: !item.hasChildren
          }
        })
        this.regionDict = treeData;
      });
    },
    handleSizeChange(val){
      this.page.pageSize = val;
      this.onLoad(this.page);
    },
    handleCurrentChange(val){
      this.page.currentPage = val;
      this.onLoad(this.page);
    },
    onLoad(page){
      this.meetingLoading = true;
      getCmsList(page.currentPage, page.pageSize, Object.assign({},this.searchParam)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.bulletinData = data.records;        
        for(let i=0;i<this.bulletinData.length;i++){
          this.bulletinTypeDict.forEach(b=>{if(b.dictKey==this.bulletinData[i].type) this.bulletinData[i].typeName=b.dictValue})
        }
        this.meetingLoading = false;
      });
    }
  }
}
</script>

<style>
  .bulletin-data-item:hover{
    cursor: pointer;
  }
  .search-title-button:hover{
    cursor: pointer;
    box-shadow: 0px 4px 10px  rgba(0, 0, 0, 0.35);
  }
  .search-title-button{
    display: inline-block;
    margin-top: 1.5px;
    margin-left: 10px;
    width: 60px;
    height: 30px;
    border-radius: 4px;
    background: rgba(0, 102, 166, 1);
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
  }
  .bulletin-search-input-span{
    display: inline-block;
    margin-left: 320px;
  }
  .search-title-input{
    width: 250px;
    height: 30px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(214, 214, 214, 1);
  }
  .bulletin-page-class{
    padding-bottom: 18px;
    height: 50px;
    background-color: rgb(255,255,255);
  }
  .el-page-class{
    margin-right: 45px;
    float: right;
    padding-top: 10px;
    height: 50px;
    line-height: 50px;
  }
  .item-bottom-value{
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 102, 166, 1);
  }
  .item-bottom-time{
    float: right;
    width: 75px;
    height: 30px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(102, 102, 102, 1);
  }
  .item-bottom-label{
    display: inline-block;
    margin-right: 5px;
    /** 文本1 */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);
  }
  .item-bottom-out-span{
    display: inline-block;
    overflow:hidden;
    margin-right: 25px;
    width: 200px;
    height: 21px;
    opacity: 1;
  }
  .bulletin-data-item-bottom{
    margin-top: 22px;
    height: 21px;
    padding-bottom: 20px;
  }
  .item-content-value{
    width: 900px;
    height: 52px;
    /** 文本3 */
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-overflow: ellipsis;
    letter-spacing: 0px;
    color: rgba(102, 102, 102, 1);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .item-content-lable{
    width: 65px;
    /** 文本1 */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26px;
    color: rgba(0, 0, 0, 1);
  }
  .item-content-div{
    margin-top: 20px;
    width: 900px;
    height: 52px;
    opacity: 1;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .item-top-start{
    margin-left: 10px;
    margin-top: 3px;
    display: inline-block;
    /** 文本2 */
    font-size: 16px;
    font-weight: 400;
    color: rgba(212, 48, 48, 1);
  }
  .item-top-title{
    margin-left: 10px;
    margin-top: 3px;
    display: inline-block;
    max-width: 650px;
    height: 27px;
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(0, 0, 0, 1);
  }
  .bulletin-data-item-top{
    height: 30px;
  }
  .item-top-type{
    display: inline-block;
    width: 70px;
    height: 30px;
    background: rgba(0, 102, 166, 1);

    /** 文本1 */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
  }
  .bulletin-data-item-right{
    margin-top: -130px;
    display: inline-block;
    margin-left: 50px;
    width: 250px;
    height: 150px;
  }
  .bulletin-data-item-left{
    display: inline-block;
    width: 900px;
    height: 170px;
    opacity: 1;
  }
  .bulletin-data-item{
    height: 170px;
    width: 1200px;
    border-bottom: 1px solid rgba(233, 237, 242, 1);
    margin-bottom: 20px;
  }
  .bulletin-data-div{
    padding-top: 30px;
    padding-left: 50px;
    margin-top: 20px;
    width: 1250px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
  }
  .un-selected{
    color: rgba(0, 0, 0, 1);
  }
  .un-selected:hover{
    color: rgba(49, 94, 251, 1);
    cursor: pointer;
    text-decoration:underline;
  }
  .selected{
    color: rgba(0, 102, 166, 1);
  }
  .bulletin-search-left-region-div{
    display: inline-block;
    vertical-align: top;
  }
  .bulletin-search-region-div{
    display: inline-block;
    width: 1000px;
  }
  .bulletin-serach-type-item{
    display: inline-block;
    margin-right: 40px;
    /* width: 100px; */
    height: 19px;
    opacity: 1;
    /** 文本1 */
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20px;
  }
  .bulletin-serach-region-item{
    padding-top: 5px;
    margin-bottom: 10px;
    width: 130px;
    margin-left: 20px;
    display: inline-block;
    height: 19px;
    opacity: 1;
    /** 文本1 */
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 18.82px;
  }
  .bulletin-search-item-all{
    width: 80px;
    margin-left: 20px;
    display: inline-block;

    height: 19px;
    /** 文本1 */
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 18.82px;
  }
  .bulletin-search-bottom-un-border{
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .bulletin-search-bottom-border{
    border-bottom: 1px solid rgba(233, 237, 242, 1);
    padding-top: 12px;
  }
  .bulletin-search-row-div{
    display: table;
    margin-left: 50px;
    width: 1200px;
    height: 0px;
    opacity: 1;
    margin-top: 5px;
  }
  .bulletin-search-label-span{
    display: inline-block;
    width: 100px;
    height: 30px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(51, 51, 51, 1);
    text-align: right;
  }
  .bulletin-search-div{
    width: 1300px;
    min-height: 336px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
  }
</style>