import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sino-biz/lotApply/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sino-biz/lotApply/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sino-biz/lotApply/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sino-biz/lotApply/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sino-biz/lotApply/submit',
    method: 'post',
    data: row
  })
}

export const queryApplyLots = (params) => {
  return request({
    url: '/api/sino-biz/lotApply/queryApplyLots',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const toApply = (data) => {
  return request({
    url: '/api/sino-biz/lotApply/cmsApply',
    method: 'post',
    data: data
  })
}

export const cmsGetApplyState = (data) => {
  return request({
    url: '/api/sino-biz/lotApply/cmsGetApplyState',
    method: 'post',
    data: data
  })
}

export const cmsGetBidCode = (data) => {
  return request({
    url: '/api/sino-biz/lotApply/cmsGetBidCode',
    method: 'post',
    data: data
  })
}

export const cmsPageMyAuction = (current,size,params) => {
  return request({
    url: '/api/sino-biz/lotApply/cmsPageMyAuction',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDepositeRecord = (params) => {
  return request({
    url: '/api/sino-biz/lotApply/getDepositeRecord',
    method: 'get',
    params: params
  })
}

export const cmsAllApply = (data) => {
  return request({
    url: '/api/sino-biz/lotApply/cmsAllApply',
    method: 'post',
    data: data
  })
}

export const cmsPageDeposit = (current,size) => {
  return request({
    url: '/api/sino-biz/lotApply/cmsPageDeposit',
    method: 'get',
    params: {
      current,
      size
    }
  })
}


