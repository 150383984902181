<template>
  <div>
    <div class="auctioneer-info-div">
      <img class="auctioneer-img-class" :src="imgLink">
      <div class="auctioneer-info-detail">
        <span class="auctioneer-info-span show-ellipsis">拍卖师：<span
            :title="auctioneerData.name">{{auctioneerData.name}}</span> </span><br>
        <span class="auctioneer-info-span show-ellipsis">拍卖师证号：<span
            :title="auctioneerData.idNumber">{{auctioneerData.idNumber}}</span> </span>
      </div>
    </div>
    <div class="message-box-div">
      <ul v-if="hallMessageData.length>0" id="messageBox" class="send_msg_list" style="height: 120px;">
        <li v-for="m in hallMessageData" :key="m.id" :class="m.msgType==3?'stype3':(m.msgType==2?'stype1':'stype2')">
          <p class="speaktit lineh16">
            <span class="system-info-type"
              :style="m.msgType==3?'color: rgba(214, 54, 59, 1);':''">{{m.msgType==3?'系统消息':(m.msgType==2?'拍卖会':'标的')}}</span>
            <span class="system-info-time">{{m.createTime}}</span>
          </p>
          <p class="speak-txt-class">{{m.content}}</p>
        </li>
      </ul>
      <div v-else class="no-message-div">暂无竞价消息</div>
    </div>
    <div style="height: 215px;">
      <el-table :data="priceDataList" style="width: 99% ;height: 100%;
    overflow-y: scroll;">
        <el-table-column label="状态" align="center" show-overflow-tooltip  width="60px">
          <template slot-scope="scope">
            <!-- TODO 根据状态判断priceState  的值为'回退':'出局'或者'成交':'领先' -->
            <span
              class="tab-content">{{(scope.row.quoted==outLotData.currentQuoted&&scope.row.priceState==1)?'领先':(scope.row.priceState==-1?'回退':'出局')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bidCode" label="竞买号牌" align="center" width="78px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="content" label="竞拍价" align="center" width="150px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="tab-content">￥{{parseFloat(scope.row.quoted).toFixed(2)}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { priceList } from '@/api/biz/auction/lotQuoted';
import { hallMessage } from '@/api/biz/auction/auctionMessage';
export default {
  name: "lotTalKView",
  props: ['outLotId', 'meetingId', 'outLotData', 'auctioneerData'],
  data() {
    return {
      messageType: 1,
      messageContent: '',
      priceDataList: [],
      page: {
        pageSize: 1000,
        currentPage: 1,
        total: 0
      },
      hallMessageData: [],
    };
  },
  computed: {
    imgLink() {
      if (this.auctioneerData && this.auctioneerData.attachList)
        return this.auctioneerData.attachList[0].link;
      return null;
    },
    // currentPrice() {
    //   if (this.priceDataList && this.priceDataList.length > 0) {
    //     return this.priceDataList[0];
    //   }
    //   return { quoted: '', priceState: null, bidCode: null };
    // }
  },
  methods: {
    changeMessageType(type) {
      this.messageType = type;
    },
    flushCurrentlotData() {
      this.getPriceList();
      this.getMessageList();
    },
    getPriceList() {
      let page = this.page;
      priceList(page.currentPage, page.pageSize, { lotId: this.outLotId }).then(res => {
        this.priceDataList = res.data.data.records;
        this.page.total = res.data.data.total;
      })
    },
    getMessageList() {
      let param = {
        meetingId: this.meetingId,
        lotId: this.outLotId,
      }
      hallMessage(param).then(res => {
        this.hallMessageData = res.data.data;
        if (this.hallMessageData.length > 0) {
          this.$nextTick(() => {
            document.getElementById('messageBox').scrollTop = document.getElementById('messageBox').scrollHeight;
          })
        }
      })
    }
  },
  created() {
    this.flushCurrentlotData();
  }
}
</script>

<style scoped>
.no-message-div {
  margin-left: 10px;
  height: 50px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 50px;
  color: rgba(102, 102, 102, 1);
}
.price-type-value {
  display: inline-block;
  width: 40px;
  height: 20px;
  opacity: 1;
  border-radius: 2px;
  background: rgba(225, 243, 216, 1);
  font-size: 13px;
  font-weight: 500;
  line-height: 18.82px;
  color: rgba(30, 169, 106, 1);
  text-align: center;
}
.price-item-div {
  width: 90px;
  text-align: center;
  display: inline-block;
}
.price-list-value {
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(102, 102, 102, 1);
}
.price-list-label {
  display: inline-block;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 40px;
  color: rgba(51, 51, 51, 1);
}
.price-list-div {
  height: 80px;
}
.speak-txt-class {
  padding: 10px;
  width: 210px;
  opacity: 1;
  border-radius: 5px;
  background: rgba(243, 245, 247, 1);
  /** 文本1 */
  font-size: 13px;
  font-weight: 400;
  line-height: 18.82px;
  color: rgba(0, 0, 0, 1);
}
.system-info-time {
  margin-left: 8px;
  display: inline-block;
  height: 21px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  color: rgba(0, 0, 0, 1);
}
.system-info-type {
  display: inline-block;
  /* width: 56px; */
  height: 21px;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
}
.message-box-div {
  overflow: hidden;
  /* height: 270px; */
  width: 100%;
  /* width: 285px; */
  margin-left: 3px;
}
.auctioneer-info-detail {
  display: inline-block;
  vertical-align: bottom;
  /* overflow: hidden; */
  max-width: 200px;
}
.auctioneer-info-span {
  display: inline-block;
  /* overflow: hidden; */
  width: 100%;
  height: 32px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 32px;
  color: rgba(0, 0, 0, 1);
}
.auctioneer-img-class {
  border-radius: 32px;
  margin-left: 10px;
  width: 64px;
  height: 64px;
  margin-right: 10px;
}
.auctioneer-info-div {
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 74px;
  overflow: hidden;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}
:deep(.el-table__header-wrapper) {
  position: sticky;
  top: 1px;
  z-index: 10;
}
</style>