<template>
  <div>
    <div class="lot-list">
      <LotList v-if="meetingId" @changeLotId='listChangeLot' @getMeetingData='getMeetingData' :meetingId="meetingId"
        :outLotId='activeLotId'></LotList>
    </div>
    <div class="lot-center">
      <div class="lot-conter-left">
        <LotContent ref="content" :meetingData='meetingData' :outLotId='activeLotId' :outData='lotData'></LotContent>
      </div>
      <div class="lot-conter-right">
        <LotTalk ref="lotTalk" v-if="activeLotId && meetingId " :auctioneerData='auctioneerData' :outLotData='lotData'
          :outLotId='activeLotId' :meetingId="meetingId"></LotTalk>
      </div>
    </div>
    <div class="lot-bottom-div">
      <el-row :gutter="24">
        <el-col :span="24">
          <LotInfo v-if="meetingData&&lotData" :meetingData='meetingData' :outData='lotData'></LotInfo>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import LotList from '@/components/hall/LotList.vue'
import LotContent from '@/components/hall/LotContent.vue'
import LotTalk from '@/components/hall/LotTalk.vue'
import LotInfo from '@/components/hall/LotInfo.vue'
import { getDetail, updateViewCount } from '@/api/biz/auction/auctionLot'
import { getAuctioneerApi } from '@/api/biz/user/auctioneer'
import { mapGetters } from "vuex";
export default {
  name: "LotDetailView",
  components: {
    LotList,
    LotContent,
    LotTalk,
    LotInfo
  },
  computed: {
    isLogin() {
      return this.userInfo.user_name;
    },
    userId() {
      return this.isLogin ? this.userInfo.user_id : parseInt(Math.random() * 999999);
    },
    userType() {
      if (this.isLogin) {//登录则为竞拍人、不登录为游客
        return 1;
      }
      return 4;
    },
    ...mapGetters(["userInfo"])
  },

  data() {
    return {
      activeLotId: null,
      lotData: {},
      meetingId: null,
      meetingData: {},
      auctioneerData: {},
    }
  },
  methods: {
    getAuctioneerInfo() {
      getAuctioneerApi(this.meetingData.auctioneerId).then(res => {
        this.auctioneerData = res.data.data;
      })
    },
    /**
    * 初始化websocket
    */
    initWebSocket() {
      const ws = sessionStorage.getItem('wsUrl');
      this.websock = new WebSocket(ws);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    /**
     * weosocket数据接收
     */
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("websocketData", data)
      if (data.msgType) {
        if (data.msgType != 301) {//刷新标的信息            
          this.getLotInfo();
        }
        if (data.msgType != 201) {//除了更新报价——刷新消息通知            
          this.$refs.lotTalk.getMessageList();
        }
        if (data.msgType == 104 || data.msgType == 201 || data.msgType == 202) {//报价、结束、回退——刷新报价列表            
          this.$refs.lotTalk.getPriceList();
        }
      }
    },
    /**
     * websocket数据发送,切换到对应的标的频道
     */
    websocketsend() {
      let dataStr = JSON.stringify({
        userId: this.userId,
        userType: this.userType,
        lotId: this.activeLotId
      });
      let obj = JSON.stringify({
        msgType: 11,
        data: dataStr
      });
      if (this.websock.readyState == 1) {
        this.websock.send(obj);
      }
    },
    /**
     * 连接建立失败重连
     */
    websocketonerror(e) {
        console.log('websocket通信连接异常',e)
    },
    websocketclose(e) {
      console.log(e,new Date())
      if (e && e.code !== 3333) {
        console.log('5s后开始重连......')
        setTimeout(() => {
          this.initWebSocket();
          setTimeout(()=>{
            if(this.websock.readyState==1){
              this.getLotInfo()
              console.log('已重新连接')
            }
          },3000)
          ;
        }, 5000)
      }
    },
    listChangeLot(lotId) {
      this.activeLotId = lotId;
      this.getLotInfo();
      this.$nextTick(() => {
        this.$refs.content.getApplyState();
        this.$refs.lotTalk.flushCurrentlotData();
      });
    },
    init() {
      this.getLotInfo();
    },
    getLotInfo() {
      getDetail(this.activeLotId).then(res => {
        this.lotData = res.data.data;
        this.meetingId = this.lotData.meetingId;
        this.$nextTick(() => {
          this.websocketsend();
          this.$refs.content.queryInfoByLotId(this.activeLotId);
        })
      });
      //更新围观次数      
      updateViewCount({ id: this.activeLotId });
    },
    getMeetingData(data) {
      this.meetingData = data;
      this.getAuctioneerInfo();
    },
  },
  created() {
    this.initWebSocket();
    this.activeLotId = this.$route.query.lotId;
    if (this.activeLotId) {
      this.init();
    }
  }
}
</script>

<style scoped>
.lot-bottom-div {
  margin-top: 20px;
}
.lot-conter-right {
  display: inline-block;
  width: 299px;
  height: 440px;
  vertical-align: bottom;
  border-left: 1px solid rgba(215, 215, 215, 1);
}
.lot-conter-left {
  display: inline-block;
  width: 1000px;
  height: 440px;
  vertical-align: bottom;
}
.lot-info-div {
  min-height: 700px;
}
.lot-center {
  display: flex;
  margin-top: 20px;
  width: 1300px;
  height: 442px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.lot-list {
  width: 1300px;
  height: 270px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
</style>