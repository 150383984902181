import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsList = (current, size, params) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/cmsList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/save',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/update',
    method: 'post',
    data: row
  })
}

export const submitAudit = (row) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/submitAudit',
    method: 'post',
    data: row
  })
}

export const saveSubmit = (row) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/saveSubmit',
    method: 'post',
    data: row
  })
}

export const updateSubmit = (row) => {
  return request({
    url: '/api/sino-biz/auctionBulletin/updateSubmit',
    method: 'post',
    data: row
  })
}
