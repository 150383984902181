<template>
  <div>
    <div class="meeting-list-div-title bt-top-div">
      <div style="display: flex;
    justify-content: center;
    align-items: flex-end;"> <img src="../../../public/img/loneline.png">
        <img style="    margin-left: 2px;" class="list-div-short-line" src="../../../public/img/shortline.png">
        <span class="div-list-title bt-top-title">公告详情</span>
      </div>
      <div style="margin-right: 20px;">
        <span class="bt-top-button" @click='toApply'>我要参拍</span>
      </div>
    </div>
    <div class="bt-content-div">
      <div class="bt-content-info">
        <span class="bt-content-info-title">
          {{bulletinData.title}}
        </span>
        <div>
          <span class="item-bottom-out-span bt-content-span">
            <span class="item-bottom-label">公告类型：</span>
            <span class="item-bottom-value">{{formatDataType(bulletinData.type)}}</span>
          </span>
          <span class="item-bottom-out-span bt-content-span">
            <span class="item-bottom-label">公告来源：</span>
            <span class="item-bottom-value">齐鲁观水电子拍卖平台</span>
          </span>
          <span class="item-bottom-out-span">
            <span class="item-bottom-time">{{bulletinData.publishMediaTime}}</span>
          </span>
        </div>
      </div>
      <div class="bt-content-name">详情介绍：</div>
      <div class="bt-content-detail">
        <span v-html="bulletinData.content"></span>
      </div>
    </div>
    <div class="bt-select-type">
      <span :class="viewType==1?'bt-selected-item':'bt-un-selected'" class="bt-select-type-item"
        @click="changeViewType(1)">所含拍卖会</span>
      <span :class="viewType==2?'bt-selected-item':'bt-un-selected'" class="bt-select-type-item"
        @click="changeViewType(2)">所含标的</span>
    </div>
    <div v-if="viewType==1" class="bt-view-meeting-div">
      <MeetingDiv ref="meeting" :meetingData='meetingData' :showHall="false"></MeetingDiv>
    </div>
    <div class="bt-view-lot-div" v-if="viewType==2">
      <LotDiv :lotList='lotList' :meetingData="meetingData[0]"></LotDiv>
    </div>

    <div style="height: 20px;"></div>
  </div>
</template>

<script>
import { getDetail } from '@/api/biz/auction/auctionBulletin'
import { getCmsMeeting } from "@/api/biz/auction/auctionMeeting";
import { getBizDictByCode } from '@/api/system/dict'
import MeetingDiv from "../meetings/MeetingDiv.vue"
import LotDiv from '../lots/LotDiv.vue'
import { listByMeetingId } from '@/api/biz/auction/auctionLot';
export default {
  name: 'BulletinDetailView',
  components: { MeetingDiv, LotDiv },
  data() {
    return {
      bulletinData: {},
      viewType: 1,
      page: {
        pageSize: 100,
        currentPage: 1,
        total: 0
      },
      lotList: [],
      meetingData: [],
      bulletinTypeDict: [],
    }
  },
  computed: {
    bulletinId() {
      return this.$route.query.bulletinId;
    }
  },
  async created() {
    document.documentElement.scrollTop = 0;
    await this.getBulletinType();
    this.getBulletinInfo();
    this.queryData();
  },
  methods: {
    formatDataType(type) {
      let data = '';
      this.bulletinTypeDict.forEach(ele => { if (ele.dictKey == type) data = ele.dictValue; })
      return data;
    },
    toApply() {
      this.$refs.meeting.meetingJump(this.meetingData[0], 2);
    },
    changeViewType(val) {
      this.viewType = val;
    },
    formatBulletinType(type) {
      let value = '';
      this.bulletinTypeDict.forEach(t => { if (t.dictKey == type) { value = t.dictValue; } })
      return value;
    },
    getBulletinType() {
      getBizDictByCode('bulletin_type').then(res => {
        this.bulletinTypeDict = res.data.data;
        this.bulletinTypeDict.unshift({ id: 111, dictValue: '全部', dictKey: null, sort: 0 })
      });
    },
    async queryData() {
      this.meetingLoading = true;
      await getCmsMeeting(this.page.currentPage, this.page.pageSize, { bulletinId: this.bulletinId }).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.meetingData = data.records;
        for (let i = 0; i < this.meetingData.length; i++) {
          let d = this.meetingData[i];
          d.bulletinType = this.formatBulletinType(d.bulletin.type);
        }
        listByMeetingId(this.meetingData[0].id).then(res => {
          this.lotList = res.data.data;
        })
        this.meetingLoading = false;
      });
    },
    getBulletinInfo() {
      getDetail(this.bulletinId).then(res => {
        this.bulletinData = res.data.data;
      })
    }
  }
}
</script>

<style>
.bt-view-lot-div {
  width: 1320px;
}
.bt-view-meeting-div {
  margin-top: 20px;
}
.bt-un-selected:hover {
  cursor: pointer;
  color: rgb(49, 94, 251);
}
.bt-selected-item {
  color: rgba(0, 102, 166, 1);
  border-bottom: 1px solid rgba(0, 102, 166, 1);
}
.bt-select-type-item {
  display: inline-block;
  margin-left: 30px;
  margin-top: 15px;
  height: 34px;
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  text-align: left;
  vertical-align: top;
}
.bt-select-type {
  margin-top: 20px;
  width: 1300px;
  height: 50px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.bt-top-div {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.bt-content-detail {
  padding-top: 30px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-top: 5px;
  margin-left: 30px;
  width: 1180px;
  min-height: 70px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 215, 215, 1);
  overflow: hidden;
}
.bt-content-name {
  margin-top: 20px;
  margin-left: 30px;
  width: 200px;
  height: 21px;
  opacity: 1;
  /** 文本1 */
  font-size: 14px;
  font-weight: 500;
  line-height: 20.27px;
  color: rgba(0, 102, 166, 1);
}
.bt-content-span {
  margin-left: 20px;
  width: 460px;
  margin-top: 18px;
}
.bt-content-info-title {
  display: inline-block;
  margin-top: 23px;
  margin-left: 350px;
  width: 540px;
  height: 27px;
  opacity: 1;
  /** 文本1 */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}
.bt-content-info {
  margin-left: 30px;
  width: 1240px;
  height: 106px;
  opacity: 1;
  border-radius: 5px;
  background: rgba(243, 245, 247, 1);
}
.bt-content-div {
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 10px;
  width: 1300px;
  min-height: 300px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.bt-top-title {
  margin-top: 10px;
  display: inline-block;
  font-size: 22px;
  margin-left: 4px;
}
.bt-top-button:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
}
.bt-top-button {
  /* margin-left: 1058px; */
  display: inline-block;
  width: 100px;
  height: 36px;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(0, 102, 166, 1) 0%,
    rgba(59, 133, 243, 1) 100%
  );
  /** 文本1 */
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  color: rgba(255, 255, 255, 1);
}
</style>