import { render, staticRenderFns } from "./rightFixd.vue?vue&type=template&id=54eb8662&scoped=true&"
import script from "./rightFixd.vue?vue&type=script&lang=js&"
export * from "./rightFixd.vue?vue&type=script&lang=js&"
import style0 from "./rightFixd.vue?vue&type=style&index=0&id=54eb8662&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54eb8662",
  null
  
)

export default component.exports