<template>
    <div>
        <div class="tabDiv" style="margin-top: 20px;">
            <div class="tableDiv" v-for="(item,index) in data" :key="index">
                <h3>
                    <span class="auctionCompany">拍卖企业: {{ item.companyName }}</span>
                    <span class="counselor">联系人: {{ item.personName }}</span>
                    <span class="phone">联系电话: {{ item.phone }}</span>
                </h3>
                <table class="lot-table" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td class="tab-img">
                                <img :src="item.lotImg[0].link" alt="">
                                <el-tooltip class="item" effect="dark" :content="item.lotName" placement="bottom-start">
                                    <span class="lot-name-span">{{item.lotName}}</span>
                                </el-tooltip>
                            </td>
                            <td class="tab-price">
                                <span class="money">￥{{parseFloat(item.price?item.price:item.startPrice).toFixed(2)}}元</span>
                            </td>
                            <td class="tab-status">
                                <span class="auction-status">{{ formatEnum(item.auctionStatus, auctionStatusOptions) }}</span>
                            </td>
                            <td class="tab-time">
                                <p class="time-box">
                                    <em v-if="auctionStatus == 1">开始时间</em>
                                    <em v-else>结束时间</em>
                                    <br>
                                    {{ auctionStatus == 1?item.startAuctionTime:item.endTime }}
                                </p>
                            </td>
                            <td class="tab-button">
                                <el-button type="primary" plain class="viewButton" @click="viewLot(item.lotId)">查看标的</el-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="block">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10,20,30,40,50,100]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
            </el-pagination>
        </div>
        </div>
</template>
  
<script>
  import {mapGetters} from "vuex";
  import {cmsPageMyAuction as getList} from '@/api/biz/auction/lotApply'
  import AuctionStatusOptions from "@/enum/AuctionStatusOptions";

export default {
  name: 'myBidTable',
  computed: {...mapGetters(["userInfo"])},
  props:{
    entrustWay: String,
    auctionStatus: String
  },
  data(){
    return {
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        data: [],
        auctionStatusOptions:[]
    }
  },
  created(){
    if(this.userInfo&&this.userInfo.user_name){
        this.init();
    }
  },
  methods:{
    // 格式化数据
    formatEnum(key, child){
        for(var index in child) {
          if(child[index].key == key) {
            return child[index].value;
          }
        }
        return "";
      },
    viewLot(lotId) {
        this.$router.push({path: "/lotDetail", query: {lotId: lotId}})
    },
    init(){
        let params = {
            entrustWay:this.entrustWay,
            auctionStatus: this.auctionStatus
        };
        this.onLoad(this.page,params);
        this.auctionStatusOptions = AuctionStatusOptions;
    },
    handleSizeChange(val) {
        this.page.pageSize = val;
        this.onLoad(this.page);
    },
      handleCurrentChange(val) {
        this.page.currentPage = val;
        this.onLoad(this.page);
    },
    onLoad(page, params) {
        // this.data = [];
        getList(page.currentPage, page.pageSize, params).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
        });
      }
  }
}
</script>

<style>
.lot-name-span{
    width: 140px;
    display: inline-block;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
}
.tableDiv h3 {
    background: #f3f5f7;
    border-bottom: 0;
    line-height: 40px;
    font-size: 14px;
    color: #666;
    font-weight: 400;
    overflow: hidden;
}
.auctionCompany {
    width: 263px;
    float: left;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding-left: 50px;
    text-align: left;
    white-space: nowrap;
    word-break: keep-all;
    word-wrap: normal;
}
.counselor, .phone {
    width: 320px;
    float: left;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.lot-table {
    width: 100%;
    height: 90px;
    margin: 10px auto 0;
}
.lot-table .tab-img {
    width: 294px;
}
.lot-table td {
    width: 165px;
}
.lot-table td {
    float: left;
    border-right: 1px solid #d7d7d7;
    line-height: 80px;
    height: 80px;
    font-size: 12px;
    text-align: center;
}
.lot-table td:last-child {
    line-height: normal;
    border-right: none;
}
.tableDiv {
    width: 96%;
    margin: 18px auto 0;
    border: 1px solid #d7d7d7;
}
.block{
    margin-left: 50%;
    margin-bottom: 2%;
    margin-top: 2%;
}
.viewButton{
    margin-left: 20%;
    margin-top: 10%;
}
.tab-img img {
    vertical-align: middle;
    width: 120px;
    height: 80px;
    float: left;
    margin-right: 10px;
    margin-left: 5%;
}
.tab-price .price-box {
    /* padding: 22px 0 0; */
    line-height: 18px;
}
.time-box{
    line-height: 22px;
    padding-top: 22px;
}
.tab-time em {
    color: #999;
}
</style>