<template>
  <div class="home-div">
    <div class="home-head-position" style="z-index: 0;">
      <div class="omg" style="display: flex; justify-content: flex-start;align-items: center;">
        <div style="display: flex;justify-content: flex-start; align-items: center;">
          <img class="home-logo" src="../../public/img/logo.png" alt="">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <img class="home-qlgs" src="../../public/img/qlgs.png" alt="">
            <img class="home-jzjdsbgy" src="../../public/img/jzjdsbgy.png" alt="">
          </div>
        </div>
        <div class="home-dzpmpt">电子拍卖平台</div>
        <div style="    margin-left: 142px;">
          <div class="home-right-user" v-if="token&&userInfo.account">
            <el-dropdown>
              <span>
                <span class="user-name">{{userInfo.account}}</span>
                <span class="user-welcome"> ，欢迎您登录齐鲁观水电子拍卖平台！</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toPersonal">个人中心</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="home-search">
            <el-input placeholder="根据关键字帮您搜索标的、拍卖会、公告内容..." v-model="searchParam.value" icon="el-icon-search">
              <el-button slot="append"
                style="background: rgba(0, 102, 166, 1);border-top-left-radius: 0; border-bottom-left-radius: 0;height: 40px;"
                @click="searchAll">
                搜索
              </el-button>
            </el-input>
            <!-- <input type="text" v-model="searchParam.value" class="search-input" placeholder="根据关键字帮您搜索标的、拍卖会、公告内容...">
            <button class="search-button" @click="searchAll"><span class="search-button-text">搜索</span></button> -->
          </div>
          <div class="home-search-key">
            <ul>
              <li v-for="(k,index) in quickSearchKey" :key="index" class="home-search-key-item cursor-pointer-text"
                @click="searchAllByKey(k)">{{k}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="home-bottom">
      <div class="home-list">
        <ul>
          <li v-for="(m,index) in menuList" :key="index" class="menu-li" :style='"width:"+menuWidth+"px"'>
            <a :href="m.url" class="menu-li-text cursor-pointer-text">{{m.name}}</a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cmsList as getMenuListApi } from '@/api/biz/columnBulletin/columnConfig'
import { getToken } from "@/util/auth";
export default {
  name: "HeadVue",
  data() {
    return {
      searchParam: {
        type: null,
        value: null,
        count: 0,
      },
      quickSearchKey: ["股权", "债权", "知识产权", "房地产", "动产", "艺术品"],
      menuWidth: 135,
      menuList: [],
    }
  },
  computed: { 
    token() {
      return getToken();
    },
    ...mapGetters(["userInfo", "tagWel"]) 
  },
  created() {
    // console.log(111,this.userInfo)
    this.init();
  },
  methods: {
    searchAllByKey(key) {
      this.searchParam.value = key;
      this.searchAll();
    },
    searchAll() {
      if (!this.searchParam.value) {
        this.$message('请输入要搜索的关键字');
        return;
      }
      this.$router.push({
        path: "/topSearch", query: {
          type: this.searchParam.type,
          value: this.searchParam.value,
          count: ++this.searchParam.count
        }
      })
    },
    toSystemLogin() {
      // 跳转管理系统登录页
      //   this.$router.push("/login")
    },
    toLogin() {
      this.$router.push("/login")
    },
    toRegister() {
      //后期改跳转地址
      this.$router.push("/register")
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        this.$router.push("/");
      });
    },
    toPersonal() {
      console.log("个人中心跳转")
      this.$router.push('/personalCenter/accountSecurity/accountShow');
    },
    queryMenuList() {
      getMenuListApi().then(res => {
        this.menuList = res.data.data;
        this.menuWidth = 1280 / this.menuList.length;
      })
    },
    init() {
      this.queryMenuList();
    }
  }
}
</script>

<style scoped>
.omg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1300px;
  height: 120px;
}
.search-input {
  margin-top: -2px;
  padding: 0 auto;
  width: 420px;
  height: 40px;
  opacity: 1;
  border-radius: 7px 0px 0px 7px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(214, 214, 214, 1);
}
.search-button {
  margin: 0 auto;
  padding: 0 auto;
  position: relative;
  top: -1px;
  width: 90px;
  height: 42px;
  opacity: 1;
  border-radius: 0px 4px 4px 0px;
  background: rgba(0, 102, 166, 1);
}
.search-button-text {
  border-radius: 0px 4px 4px 0px;
  margin: 0 auto;
  padding: 0 auto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.menu-li-text {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 65px;
  color: rgba(255, 255, 255, 1);
  vertical-align: top;
}
.menu-li {
  text-align: center;
  float: left;
  margin-left: 0px;
}
.home-search-key-item {
  display: block;
  float: left;
  margin-right: 20px;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(0, 102, 166, 1);
  text-align: left;
  vertical-align: top;
}
.home-search-key {
  /* position: relative; */
  /* display: block;
  left: 1095px;
  top: -40px; */
  width: 419px;
  height: 21px;
  /* opacity: 1; */
  margin-top: 10px;
}
.home-search {
  /* position: relative; */
  display: block;
  left: 1095px;
  top: -56px;
  width: 510px;
  height: 40px;
  opacity: 1;
  border-radius: 8px 8px 8px 8px;
  background: rgba(255, 255, 255, 1);

  /* border: 1px solid rgba(214, 214, 214, 1); */
}
.user-welcome {
  /** 文本2 */
  font-size: 13px;
  font-weight: 400;
}
.user-name {
  /** 文本1 */
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18.82px;
  color: rgba(0, 102, 166, 1);
}
.home-right-user {
  /* position: relative; */
  /* left: 480px;
  top: -30px;
  width: 271px;
  height: 19px;
  opacity: 1; */
  margin-bottom: 24px;
  height: 19px;
  opacity: 1;
  text-align: right;
}
.home-dzpmpt {
  margin-left: 35px;
  height: 49px;
  opacity: 1;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 5px;
  line-height: 37.65px;
  color: rgba(0, 102, 166, 1);
  text-align: left;
}
.home-div {
  z-index: 0;
  background-color: rgb(255, 255, 255);
}
.home-jzjdsbgy {
  z-index: 0;
  position: relative;
  top: -5px;
  width: 198px;
}
.home-qlgs {
  z-index: 1;
  position: relative;
  top: 20px;
  width: 310px;
}
.home-head-position {
  position: relative;
  height: 140px;
  z-index: 0;
}
.home-logo {
  width: 100px;
  height: 95.77px;
}
.top-select {
  width: 110px;
}
.caa-logo {
  line-height: 100px;
}
.top-search {
  line-height: 100px;
}
.clearfix {
  zoom: 1;
}
.nav-wrap {
  padding: 20px 0;
  position: relative;
  height: 29px;
  overflow: hidden;
  width: 983px;
}
.main-nav {
  margin-right: 250px;
}
.head-img {
  text-align: left;
  float: left;
  padding: 7px 0 0;
  width: 217px;
}
.home-bottom {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  height: 70px;
  background: rgba(238, 242, 245, 1);
}
.home-list {
  z-index: 2;
  /* margin-left: 310px; */
  margin: 0 auto;
  width: 1300px;
  height: 70px;
  opacity: 1;
  background: rgba(0, 102, 166, 1);
}
.home-head {
  width: 70%;
  margin: auto;
  height: 100px;
}
.home-divider {
  width: 100%;
  border-bottom: 1px solid rgb(193, 57, 60);
}
.dropdown {
  margin-left: 13%;
}
.cursor-pointer-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
::v-deep .el-input-group__append {
  background: rgba(0, 102, 166, 1);
  color: #fff;
}
</style>