<template>
  <div>
    <div class="meeting-detail-div" v-for="m in meetingData" :key="m.id">
        <span class="bulltein-type-span">{{m.bulletinType}}</span>
        <div class="detail-center-div">
          <img class="detail-img" :src="m.meetingCover[0].link">
          <div class="detail-right">
            <div class="right-center">
              <div>
                <span :class="m.meetingType==0?'detail-network-type':'detail-sync-type'">{{m.meetingType==1?'同步拍':'网络拍'}}</span>
              </div>
              <p class="detail-name">
                {{m.meetingName}}
              </p>
              <p class="detail-company">
                拍卖企业：{{m.companyName}}
              </p>
              <div class="meeting-detail-time">
                <img class="detail-time-img" src="../../../public/img/time.png">
                <span class="meeting-detail-start-time">{{m.startAuctionTime}}</span>            
              </div>
              <div class="start-of-distance">
                <span v-if="m.meetingStatus==10" style="color:rgba(30, 169, 106, 1)">距离开始 {{getStartOfDistance(m.startAuctionTime)}} 天</span>
                <span v-if="m.meetingStatus==20" style="color:rgba(214,54,59,1)">进行中</span>
                <span v-if="m.meetingStatus==30" >已结束</span>
              </div>
              <div class="button-div">
                <span class="jump-button" @click="meetingJump(m,1)">标的目录</span>
                <span v-if="showHall" class="jump-button" @click="meetingJump(m,2)">拍卖大厅</span>
              </div>
            </div> 
            <div class="right-bottom">
              <span class="bottom-span">标的数量：{{m.bidCount}}个</span>
              <span class="bottom-span">围观次数：{{m.pageView}}次</span>
            </div>      
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingDivView',
  props: ['meetingData','showHall'],
  methods: {
    meetingJump(meeting, type){
      this.$router.push({path:"/meetingDetial", query: {meetingId: meeting.id, activeType: type}})
    },
    getStartOfDistance(date){
      return parseInt(((new Date(date).getTime()/(24*60*60*1000))-(new Date().getTime()/(24*60*60*1000))))
    },
  }
}
</script>

<style>
  .bottom-span{
    display: inline-block;
    margin-top: 5px;
    margin-left: 40px;
    width: 106px;
    height: 18px;
    opacity: 1;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .right-bottom{
    width: 490px;
    height: 30px;
    opacity: 1;
    background: rgba(229, 229, 229, 1);
  }
  .jump-button{
    display: inline-block;
    text-align: center;
    line-height: 42px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    margin-right: 20px;
    width: 140px;
    height: 42px;
    opacity: 1;
    background: rgba(0, 102, 166, 1);
  }
  .button-div{
    display: inline-block;
    margin-left:41px;
    margin-top: 20px;
  }
  .start-of-distance{
    position: relative;
    margin-top: 12px;
    width: 320px;
    margin-left: 56px;
    min-height: 24px;
    height: 24px;
    opacity: 1;
    /** 文本1 */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
  }
  .meeting-detail-time{
    margin-top: 25px;
    margin-left: 41px;    
    min-height: 27px;
  }
  .detail-time-img{
    margin-top: -5px;
    height: 20px;
  }
  .detail-company{
    margin-top: 10px;
    margin-left: 40px;
    width: 430px;
    height: 18px;
    opacity: 1;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: rgba(153, 153, 153, 1);
    text-align: left;
    vertical-align: top;
  }
  .detail-name{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 12px;
    margin-left: 40px;
    width: 430px;
    /* min-height: 53px; */
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .detail-network-type{
    display: inline-block;
    margin-left: 430px;
    width: 60px;
    height: 28px;
    opacity: 1;
    border-radius: 2px 0px, 2px, 2px;
    background: rgba(42, 130, 228, 1);
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);

  }
  .detail-sync-type{
    display: inline-block;
    margin-left: 430px;
    width: 60px;
    height: 28px;
    opacity: 1;
    border-radius: 2px 0px, 2px, 2px;
    background: rgba(253, 167, 0, 1);
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  .right-center{
    width: 490px;
    height: 270px;
  }
  .detail-right{
    float: right;
    width: 490px;
    height: 300px;
    box-shadow: 1px -2px 4px rgba(0, 0, 0, 0.25);
  }
  .detail-img{
    width: 810px;
    height: 300px;
  }
  .detail-center-div{
    margin-top: -70px;
  }
  .meeting-detail-div{
    margin-top: 20px;
    background-color: rgb(255,255,255);
    height: 300px;
  }
  .bulltein-type-span{
    position: relative;
    z-index: 1;
    top: 10px;
    margin-left: 30px;
    width: 96px;
    height: 70px;
    opacity: 1;
    /** 文本1 */
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 69.5px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
  }
</style>