<template>
  <div>
    <div class="meeting-list-div-title">
      <img src="../../../public/img/loneline.png">
      <img style="margin-top: 10px;
    margin-left: 5px;" class="list-div-short-line" src="../../../public/img/shortline.png">
      <span class="div-list-title cursor-pointer-text" @click="jump()">{{titleName}}</span>
    </div>
    <div style="margin-top:6px;">
      <div class="ad-div-item cursor-pointer-text" v-for="i in showData" :key="i.id" @click="jumpDetail(i)">
        <img class="ad-img-item" :src="i.coverImg">
      </div>
    </div>
  </div>
</template>

<script>
import { cmsListByType } from "@/api/biz/columnBulletin/cmsContentad"
export default {
  name: "AdvertisintView",
  props: ['showType', 'titleName', 'jumpUrl'],
  created() {
    this.queryData();
  },
  data() {
    return {
      showData: [],
    }
  },
  methods: {
    queryData() {
      cmsListByType({ type: this.showType }).then(res => {
        this.showData = res.data.data;
      })
    },
    jump() {
      this.$router.push(this.jumpUrl)
    },
    jumpDetail(i) {
      if (i.jumpType == 1) {
        this.$router.push(i.jumpUrl)
      } else {
        window.open(i.jumpUrl)
      }
    }
  }
}
</script>

<style>
.ad-img-item {
  width: 175px;
  height: 65.02px;
  opacity: 1;
  /* margin-top: 11px;
    margin-left: 18px; */
}
.ad-div-item {
  display: inline-block;
  padding: 5px;
  box-sizing: border-box;
  width: 203px;
  height: 87.5px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 215, 215, 1);
}
.div-list-title {
  margin-left: 11px;
  width: 110px;
  height: 32px;
  opacity: 1;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 31.86px;
  color: black;
  text-align: left;
  vertical-align: top;
}
</style>