<template>
  <div>
    <div class="lot-title-div">
      <em style="color:#007AFF;">【{{formatEnum(outData.auctionModality, auctionModalityOptions)}}】</em>
      {{outData.lotName}}
    </div>
    <div class="lot-content-div">
      <div class="lot-content-left">
        <div class="big-img-div">
          <imgZoom :width="400" :height="220" :minIMGsrc="activeLotImgLink" :scale="3" />
        </div>
        <div class="small-img-div">
          <img v-for="i in outData.lotImg" :key="i.id" :id="`content`+i.id" :src="i.link" @click="changeShowImg(i.id)"
            class="content-img-dom">
        </div>
        <div class="view-count-div">
          <img src="../../../public/img/eye.png" class="eye-img-class">
          <span class="view-count-label">围观次数：</span>
          <span class="view-count-value">{{outData.viewCount}}次</span>
          <template v-if="activeApplyState">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="view-count-label">我的竞买号：</span>
            <span class="view-count-value">{{bidCode}}</span>
          </template>
        </div>
      </div>
      <div class="lot-content-right">
        <div class="time-type-div">
          <template v-if="outData.auctionStatus!=1&&outData.auctionStatus!=2">
            <template v-if="outData.auctionStatus==0">
              <span class="ready-type-span-class">即将开始</span>
              <span style="color:#000;margin-left:10px;font-size:15px;">拍卖会预计{{meetingData.startAuctionTime}}开始</span>
            </template>
            <template v-else>
              <span v-if="outData.auctionStatus!=null"
                class="end-type-span-class">{{outData.auctionStatus==3?'已成交':(outData.backOutStatus==1?'已撤拍':'已流拍')}}</span>
              <span v-if="outData.auctionStatus!=null" style="color:#000;margin-left:10px;font-size:15px;">{{outData.endTime}}&nbsp;&nbsp;结束</span>
            </template>
          </template>
          <template v-if="outData.auctionStatus==1||outData.auctionStatus==2">
            <countDowm class="timetxt" v-if="outData.auctionStatus==1" :key="outData.round+executeTime" v-slot="timeObj" :end="executeTime" :isMilliSecond="false">            
              <el-button size="mini" type="danger" round>
                {{outData.auctionStatus==1?'正在进行':'请等待拍卖师操作'}}
              </el-button>
              <span class="timetxt" style="margin-left: 10px;">{{outData.auctionModality==2?'距下次降价':(outData.auctionProgress==1?'自由竞价倒计时：':'限时竞价倒计时：')}}
              </span>
              <span class="countdown J_TimeLeft" style="margin-left: 10px;">
                <var class="day">{{timeObj.d}}</var>
                <em>天 </em>
                <var class="hour">{{timeObj.hh}}</var>
                <em>时 </em>
                <var class="minute">{{timeObj.mm}}</var>
                <em>分 </em>
                <var class="second">{{timeObj.ss}}</var>
                <em>秒 </em>
              </span>
            </countDowm>
            <template v-else>
              <el-button size="mini" type="danger" round>
                {{outData.auctionStatus==1?'正在进行':'请等待拍卖师操作'}}
              </el-button>
              <span class="timetxt" style="margin-left: 10px;">{{outData.auctionModality==2?'距下次降价':(outData.auctionProgress==1?'自由竞价倒计时：':'限时竞价倒计时：')}}
              </span>
              <span class="countdown J_TimeLeft">
                <var class="day">{{countDowm.day}} </var>
                <em> 天</em>
                <var class="hour">{{countDowm.hour}} </var>
                <em> 时</em>
                <var class="minute">{{countDowm.minute}} </var>
                <em> 分</em>
                <var class="second">{{countDowm.second}} </var>
                <em> 秒</em>
              </span>
            </template>
          </template>
        </div>
        <div class="lot-content-quoted-div">
          <div class="price-div-class">
            <template v-if="outData.auctionStatus==0">
              <span class="price-label">起拍价：</span>
              <span class="price-value" style="color:rgb(30,169,106);font-size:22px;">{{outData.startPrice}}元</span>
            </template>
            <template v-else-if="outData.auctionStatus!=-1">
              <span class="price-label">{{outData.auctionStatus==3?'成交价：':'当前价：'}}</span>
              <span class="price-value">{{parseFloat(outData.currentQuoted).toFixed(2)}}元</span>
            </template>
          </div>
          <div>
            <template v-if="outData.auctionStatus==-1||outData.auctionStatus==3">
              <div class="result_span">本标的已{{outData.auctionStatus==3?'成交':(outData.backOutStatus==1?'撤拍':'流拍')}}</div>
            </template>
            <template v-else>
              <template v-if="activeApplyState">
                <template v-if="outData.auctionStatus==0">
                  <el-button v-if="activeApplyState==1" disabled style="width:210px;margin-left:60px;margin-top: 20px;"
                    type="primary">等待审核</el-button>
                  <el-button v-if="activeApplyState==2" disabled style="width:210px;margin-left:60px;margin-top: 20px;"
                    type="primary">等待开拍</el-button>
                </template>
                <template v-else>
                  <div class="price-div-class">
                    <template v-if="activeApplyState==1">
                      <el-button :key="outData.id" v-if="activeApplyState==1" disabled style="width:210px;margin-left:60px;"
                        type="primary">等待审核</el-button>
                    </template>
                    <template v-else>
                      <span class="price-label">出价：</span>
                      <el-input-number v-if="outData.auctionModality!=2 && outData.round==0" v-model="activeQuoted"
                        :min="parseFloat(outData.currentQuoted)" size="small"
                        style="width:270px" :step="parseFloat(outData.bidIncrement)"
                        :disabled='outData.auctionStatus!=1 || activeApplyState==1'></el-input-number>
                      <el-input-number v-else-if="outData.auctionModality!=2" v-model="activeQuoted"
                        :min="parseFloat(outData.currentQuoted) + parseFloat(outData.bidIncrement)" size="small"
                        style="width:270px" :step="parseFloat(outData.bidIncrement)"
                        :disabled='outData.auctionStatus!=1 || activeApplyState==1'></el-input-number>
                      <el-input-number v-else :value="outData.currentQuoted" size="small" style="width:270px" disabled></el-input-number>
                      <el-button :key="outData.id" v-if="activeApplyState==2" :disabled="outData.auctionStatus!=1"
                        style="width:100px;margin-left:40px;" type="primary" @click="quotedPrice">报价</el-button>
                    </template>
                  </div>
                </template>
              </template>
              <div v-else class="quoted-apply">
                <el-button style="width:150px" type="primary" @click="toApply">报名</el-button>
              </div>
            </template>
          </div>
        </div>
        <div class="lot-content-info-div">
          <div class="lot-content-info-row flex-row">
            <span class="span3-label">起拍价</span>
            <span class="span3-value">{{outData.startPrice}}元</span>
            <span class="span3-label">保证金</span>
            <span class="span3-value">{{outData.depositePrice}}元</span>
            <span class="span3-label">{{outData.auctionModality!=2?'加价':'降价'}}幅度</span>
            <span class="span3-value">{{outData.bidIncrement}}元</span>
          </div>
          <div class="flex-row">
            <span class="span3-label">评估价</span>
            <span class="span3-value">{{outData.evalPrice}}元</span>
            <span class="span3-label">保留价</span>
            <span class="span3-value">{{outData.isReservePrice==1?'有':'无'}}</span>
            <span class="span3-label">优先竞买人</span>
            <span class="span3-value">无</span>
          </div>
          <div class="flex-row">
            <span class="span3-label" style="font-size: 12px;">{{outData.auctionModality!=2?"自由竞价时间":""}}</span>
            <span class="span3-value"
              style="font-size: 12px;width:185px">
              {{outData.auctionModality!=2?formatFreeTime(outData.freeBidTime,1):""}}</span>
            <span class="span3-label" style="font-size: 12px;">{{outData.auctionModality!=2?"限时竞价时间":"降价周期"}}</span>
            <span class="span3-value"
              style="font-size: 12px;width:185px">{{formatFreeTime(outData.limitBidTime,1)}}</span>
          </div>
          <!-- <div class="flex-row">
            <span class="span3-label">拍品数量</span>
            <span class="span3-value">4000</span>
            <span class="span3-label">拍品单位</span>
            <span class="span3-value">吨</span>
            <span class="span3-label">所在地</span>
            <span class="span3-value">山东省济南市</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cmsGetApplyState, cmsGetBidCode } from '@/api/biz/auction/lotApply'
import { cmsQuoted } from '@/api/biz/auction/lotQuoted'
import imgZoom from './s-imgZoom';
import countDowm from './countdown.vue'
import auctionModalityOptions from "@/enum/auctionModalityOptions";
export default {
  name: "HallLotContent",
  props: ['outLotId', 'outData', 'meetingData'],
  components: { imgZoom, countDowm },
  computed: {
    isLogin() {
      return this.userInfo.user_name ? 1 : 0;
    },
    executeTime(){
      return new Date(new Date(this.outData.executeTime).getTime() + 1000*this.outData.timeRemaining);
    },
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      activeLotImgLink: '',
      activeApplyState: 0,
      bidCode: 0,
      activeQuoted: 0,
      countDowm: { day: 0, hour: 0, minute: 0, second: 0, },
      activeTimeCountDown: null,
      countDownTimer: null,
      auctionModalityOptions: [],
    }
  },
  methods: {
    // 格式化数据
    formatEnum(key, child) {
      for (var index in child) {
        if (child[index].key == key) {
          return child[index].value;
        }
      }
      return "";
    },
    formatFreeTime(time, type) {
      let t = parseInt(time);
      return (type == 1 ? parseInt(t / (24 * 60 * 60)) + '天' : '') + parseInt(t / (60 * 60) % 24) + '时' + parseInt(t / 60 % 60) + '分' + parseInt(t % 60) + '秒';
    },
    async quotedPrice() {
      let priceMod = (this.activeQuoted - parseFloat(this.outData.currentQuoted)) % parseFloat(this.outData.bidIncrement);
      if (priceMod != 0) {
        this.$message({ type: 'warning', message: '报价不符合规则' });
        return;
      }
      const lotQuoted = {
        meetingId: this.outData.meetingId,
        lotId: this.outLotId,
        quoted: this.outData.auctionModality!=2?this.activeQuoted:this.outData.currentQuoted,
        round: this.outData.round,
        priceState: 1,
        bidderId: this.userInfo.user_id,
        meetingType: this.meetingData.meetingType
      }
      let isQuoted = true;
      if (this.userInfo.user_id == this.outData.bidderId) {
        await this.$confirm('当前已领先报价，是否继续报价?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          isQuoted = true;
        }).catch(() => {
          isQuoted = false;
        })
      }else{
        await this.$confirm('尊敬的 '+this.bidCode+' 号，您提交的竞价为：人民币 '+lotQuoted.quoted+' 元', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          isQuoted = true;
        }).catch(() => {
          isQuoted = false;
        })
      }
      if (!isQuoted) {
        return;
      }
      
      cmsQuoted(lotQuoted).then(res => {
        let data = res.data.data;
        if (data == 99) {
          this.$message({ type: 'success', message: '报价成功！' })
        } else if (data == 2 || data == 3 || data == -1) {
          this.$message({ type: 'warning', message: '标的当前状态下无法报价！' })
        } else if (data == 0) {
          this.$message({ type: 'warning', message: '有其他竞买人报价领先于您的报价！' })
        } else if (data == 2024) {
          this.$message({ type: 'warning', message: '超出授权数量,无法报价！' })
        }
      })
    },
    getApplyState() {
      if (this.isLogin) {
        cmsGetApplyState({ lotId: this.outLotId, bidderId: this.userInfo.user_id }).then(res => {
          this.activeApplyState = res.data.data;
        })
        cmsGetBidCode({ lotId: this.outLotId, bidderId: this.userInfo.user_id }).then(res => {
          this.bidCode = res.data.data;
        })
      } else {
        this.activeApplyState = 0;
      }
    },
    toApply() {
      if (this.isLogin) {
        if(this.userInfo.companyId==null||this.userInfo.isAuth==0){
          return this.$message({ type: 'warning', message: '请完成实名认证审核' })
        }
        this.$router.push({
          path: "/toApply", query: {
            lotId: this.outLotId,
            meetingId: this.outData.meetingId
          }
        });
      } else {
        sessionStorage.setItem('activeLotId', this.outLotId);
        this.$router.push("/login");
      }
    },
    changeShowImg(id) {
      this.$nextTick(() => {
        this.outData.lotImg.forEach(e => {
          if (e.id == id) {
            this.activeLotImgLink = e.link
            document.getElementById(`content` + id).style.borderColor = 'red'
          } else {
            document.getElementById(`content` + e.id).style.borderColor = 'transparent'
          }
        })
      })
    },
    /**
     * 父组件调用
     */
    queryInfoByLotId(id) {
      if(this.outData.round==0){
        this.$set(this, 'activeQuoted', parseFloat(this.outData.currentQuoted));
      }else{
        this.$set(this, 'activeQuoted', (parseFloat(this.outData.currentQuoted) + parseFloat(this.outData.bidIncrement)));
      }
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer)
      }
      this.formatTime();
      console.log('---', id)
      this.changeShowImg(this.outData.lotImg[0].id);
    },
    formatTime() {
      if (this.outData.auctionStatus == 1) {
        this.activeTimeCountDown = this.outData.timeRemaining - (new Date().getTime() / 1000 - new Date(this.outData.executeTime).getTime() / 1000)
        this.countDownTimer = setInterval(() => {
          if (this.activeTimeCountDown > 0) {
            this.activeTimeCountDown--;
            this.countDowm = {
              day: parseInt(this.activeTimeCountDown / (24 * 60 * 60)),
              hour: parseInt(this.activeTimeCountDown / (60 * 60) % 24),
              minute: parseInt(this.activeTimeCountDown / 60 % 60),
              second: parseInt(this.activeTimeCountDown % 60)
            }
          }
        }, 1000)
      } else {
        this.activeTimeCountDown = this.outData.timeRemaining
        this.countDowm = {
          day: parseInt(this.activeTimeCountDown / (24 * 60 * 60)),
          hour: parseInt(this.activeTimeCountDown / (60 * 60) % 24),
          minute: parseInt(this.activeTimeCountDown / 60 % 60),
          second: parseInt(this.activeTimeCountDown % 60)
        }
      }
    },
  },
  created() {
    this.auctionModalityOptions = auctionModalityOptions;
    if (this.isLogin) {//如果登陆了就判断是否报名
      this.getApplyState();
    }
  }
}
</script>

<style scoped>
.lot-content-info-row {
  height: 30px;
  width: 525px;
}
.span3-value {
  display: inline-block;
  width: 98px;
  height: 30px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(235, 238, 245, 1);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}
.span3-label {
  display: inline-block;
  width: 73px;
  height: 30px;
  opacity: 1;
  background: rgba(243, 245, 247, 1);
  border: 1px solid rgba(235, 238, 245, 1);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lot-content-info-div {
  margin-top: 20px;
  width: 525px;
  height: 120px;
}
.price-div-class {
  margin-left: 20px;
  margin-top: 20px;
  height: 27px;
}
.lot-content-quoted-div {
  overflow: hidden;
  margin-top: 20px;
  width: 525px;
  height: 130px;
  opacity: 1;
  border-radius: 5px;
  background: rgba(243, 245, 247, 1);
}
.ready-type-span-class {
  display: inline-block;
  width: 76px;
  height: 28px;
  border-radius: 2px;
  background: rgba(30, 169, 106, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}
.end-type-span-class {
  display: inline-block;
  width: 60px;
  height: 28px;
  border-radius: 2px;
  background: rgba(214, 54, 59, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}
.time-type-div {
  margin-top: 20px;
  width: 525px;
  height: 28px;
  opacity: 1;
}
.view-count-value {
  display: inline-block;
  /** 文本1 */
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(153, 153, 153, 1);
}
.view-count-label {
  display: inline-block;
  /** 文本2 */
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
}
.eye-img-class {
  margin-top: -5px;
  margin-right: 5px;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  opacity: 1;
}
.view-count-div {
  margin-top: 20px;
  width: 400px;
  height: 20px;
}
.small-img-div {
  margin-top: 10px;
  width: 400px;
  height: 60px;
  opacity: 1;
  overflow: hidden;
  background: rgba(243, 245, 247, 1);
}
.lot-content-right {
  margin-left: 30px;
  display: inline-block;
  width: 525px;
  height: 380px;
  vertical-align: bottom;
}
.lot-content-left {
  display: inline-block;
  width: 400px;
  height: 380px;
  vertical-align: bottom;
}
.big-img-div {
  margin-top: 20px;
  width: 400px;
  height: 220px;
  z-index: 1000;
}
.lot-content-div {
  display: inline-block;
  margin-left: 30px;
  width: 960px;
  height: 380px;
  vertical-align: bottom;
}
.lot-title-div {
  margin-left: 30px;
  width: 960px;
  height: 60px;
  opacity: 1;
  border-bottom: 1px solid rgba(233, 237, 242, 1);
  /** 文本1 */
  font-size: 24px;
  font-weight: 500;
  line-height: 60px;
  color: rgba(0, 0, 0, 1);
}
.result_span {
  margin-top: 8px;
  margin-left: 183px;
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background: rgba(254, 240, 240, 1);
  border: 1px solid rgba(214, 54, 59, 1);
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: rgba(214, 54, 59, 1);
}
.from-value {
  color: #333;
}
.from-quoted {
  color: rgb(153, 153, 153);
  line-height: 25px;
}
.el-row-from {
  margin-top: 10px;
}
.servicefeetips {
  line-height: 30px;
  margin-top: 20px;
  height: 30px;
  background-color: rgb(243, 245, 247);
}
.quoted-apply {
  margin-top: 20px;
  text-align: center;
}
.price-value {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(214, 54, 59, 1);
  margin-left: 5px;
}
.price-label {
  min-width: 50px;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(0, 0, 0, 1);
}
.quoted-div {
  margin-top: 10px;
  line-height: 45px;
  width: 450px;
  height: 150px;
  background-color: rgb(243, 245, 247);
}
.content-time {
  margin-left: 20px;
}
.content-info {
  margin-top: 30px;
}
.content-img-div {
  margin-top: 30px;
}
.content-img-dom {
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
  border: 1px solid #fff;
  width: 67px;
  height: 50px;
  opacity: 1;
}
.content-img-li {
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
}

.flex-row {
  display: flex;
}
</style>