<template>
    <el-container>
    <br />
    <el-header align="center">
      <!-- 放置头部图片 -->
      <div class="headerDiv"></div>
    </el-header>
    <el-main>
      <div class="typeBox" v-if="isShow">
         <h1 class="chektitle">请选择账号类型</h1>
         <el-form ref="form" :model="form" label-width="80px">
            <div class="radioGroup"></div>
            <el-radio-group v-model="form.regType">
               <el-radio class="regTypeRadio" label="bidder">竞买个人账户</el-radio>
               <el-radio class="regTypeRadio" label="bidAgency">竞买机构账户</el-radio>
               <!-- <el-radio class="regTypeRadio" label="auctionCompany">拍卖企业</el-radio> -->
            </el-radio-group>
         <div class="buttonDiv">
            <el-button class="nextButton" type="primary" @click="typeNext">下一步</el-button>
         </div>
    
         </el-form>
   </div>

   <div v-else class="registerFlow">
      <!-- 步骤条 -->
      <!-- <h2 class="registerTitle"> {{ registerTitle }}</h2> -->
      <el-steps :active="active" finish-status="success" align-center>
         <el-step :title="title"></el-step>
         <el-step title="填写账号信息"></el-step>
         <el-step title="密码重置成功"></el-step>
      </el-steps>
      <!-- 手机号或邮箱注册 -->
      <div v-if="active == 0 && !verify" class="registerForm">
      <el-form  ref="phoneOrEmail" :model="form" :rules="rules" label-width="150px">
         <el-form-item v-if="form.regType == 'bidder' " label="请输入手机号:"  prop="phone">
            <el-input v-model="form.phone" placeholder="请输入要绑定的手机号"></el-input>
         </el-form-item>
         <el-form-item v-else label="请输入邮箱:" prop="email">
            <el-input v-model="form.email" placeholder="请输入邮箱地址"></el-input>
         </el-form-item >
    
            <el-form-item  class="checkbox" prop="isRead" label-width="0px">
            <el-checkbox v-model="form.isRead">我已阅读</el-checkbox>
            <a href="###" class="agreement">《英大平台会员注册协议》</a>
            <a href="###" class="agreement">《英大平台网络拍卖服务协议》</a>
            <a href="###" class="agreement">《英大平台隐私政策》</a>
         </el-form-item>
      </el-form>
   </div>
      <!-- 账号信息 -->
      <div v-else-if="active == 1 && !verify" class="registerForm">
      <el-form  ref="userForm" :model="form" :rules="rules" label-width="150px" >
         <el-form-item label="用户名:"  prop="account">
            <el-input v-model="form.account" placeholder="请设置用户名"  auto-complete="off"></el-input>
         </el-form-item>
         <el-form-item label="设置密码:"  prop="password" >
            <el-input v-model="form.password" placeholder="请记住密码" show-password  auto-complete="off"></el-input>
            <el-progress :percentage="percentage" :color="customColorMethod" :format="format"></el-progress>
         </el-form-item>
         <el-form-item label="重复密码:"  prop="password2">
            <el-input v-model="form.password2" placeholder="确认您的密码" show-password  auto-complete="off"></el-input>
         </el-form-item>
         <el-form-item label="验证码:"  prop="code">
            <el-row :span="24">
            <el-col :span="16">
            <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
         </el-col>
         <el-col :span="8">
            <div class="login-code">
            <img :src="form.image" class="login-code-img" @click="refreshCode"
            />
          </div>
        </el-col>
         </el-row>
         </el-form-item>
      </el-form>
   </div>
   <!-- 注册成功 -->
   <div v-else-if="active == 2 && !verify" class="registerForm">
      <h1 class="regSuccess">密码重置成功，请立即登录</h1>
   </div>
   <!-- 邮箱手机验证码 -->
   <div v-if="verify" class="verifyForm">
       <div>
            <span class="codeSpan">验证码已发送至：</span>
            <span v-if="form.regType == 'bidder'" class="regSuccess">{{ form.phone }}</span>
            <span v-else class="regSuccess">{{ form.email  }}</span>
            <div class="elFormCode">
            <el-form label-width="80px">
               <el-form-item label="验证码：">
                  <el-input v-model="form.regCode">
                     <el-button slot="append"  @click="handleSend" :disabled="msgkey" style="width: 120px;">{{msgText}}</el-button>
                  </el-input>
               </el-form-item>
            </el-form>
         </div>
         </div>
   </div>
      <div class="buttonDiv">
      <el-button v-if="active == 0 && !verify" class="nextButton" type="primary" @click="verifyNext">下一步</el-button>
      <el-button v-if="(active == 0 && verify) || active == 1" class="nextButton" type="primary" @click="next">下一步</el-button>
      <el-button v-if="active == 2" class="nextButton" type="primary" @click="toLogin">立即登录</el-button>
      </div>
   </div>
    </el-main>
  </el-container>
</template>

<script>

import {getCaptcha, sendRegisterBidder, sendEmailValidate} from "@/api/user";
import {checkRegPhoneCode, findPassword, checkRegEmailCode} from "@/api/system/user";
export default {
      name: 'findPassword',
      data() {
         // 手机号校验规则
         var validatePhone = (rule, value, callback) => {
            let pattern = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
            if (value === '') {
               callback(new Error('请输入您的手机号'));
            } else if(!pattern.test(value)){
               callback(new Error('请您输入正确的手机号'))
            } else {
               callback();
            }
         };
         // 协议校验规则
         var validateIsRead = (rule, value, callback) => {
            if (value === false) {
               callback(new Error('请同意注册协议'))
             } else {
               callback();
            }
         };
         // 用户名校验规则
       var validateAccount = (rule, value, callback) => {
         var reg = /^[^\u4e00-\u9fa5\s]{6,16}$/
         if(value === ''){
            callback(new Error('请输入登录账号'));
         } else if(!reg.test(value)){
            callback(new Error('用户名必须是6-20位之间的字母、数字、下划线、减号，并且以字母开头'));
         } else {
            callback();
         }
       };
         // 密码校验规则
      var validatePassword = (rule, value, callback) => {
         // 密码校验
         let passwordRegex = /^[^\u4e00-\u9fa5\s]{6,16}$/;
         // 密码强度
         // 使用正则表达式判断密码强度
            if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)) {
               this.percentage = 100;
            } else if (/(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,}/.test(value)) {
               this.percentage = 70;
            } else {
               this.percentage = 30;
            }
         
            if (value === '') {
            callback(new Error('请输入密码'));
            } else if (!passwordRegex.test(value)) {
            callback(new Error('密码长度在6-16之间，不能使用中文、空格、非法字符'));
            } else {
            callback();
            }
      };
      // 二次密码校验
      const validatePassword2 = (rule, value, callback) => {
         if (value === '') {
         callback(new Error('请再次输入密码'));
         } else if (value !== this.form.password) {
         callback(new Error('两次输入密码不一致'));
         } else {
         callback();
         }
       };
         return {
            // 邮箱手机号验证
            verify: false, 
            // 密码进度条
            percentage: 0,
            codeForm:{},
            form: {
               isRead: true, // 阅读协议
               isAdmin: 1, // 是否是管理员，后面会根据user是否是管理员来返回认证信息审核状态
            },
            isShow: true, // div开关
            registerTitle: '',
            msgText: '',
            msgTime: '',
            msgkey: false,
            active: 0, // 步骤
            agreement: false,
            agreement1: false,
            agreement2: false,
            rules:{
               phone:   [{ validator: validatePhone, trigger: 'blur' }],
               email:   [{ required: true, message: '请输入您的电子邮箱', trigger: 'blur'},
                        {type: 'email', message:'邮箱格式不正确，请重新输入',trigger: 'blur'}],
               isRead:  [{validator: validateIsRead, trigger: 'change' }],
               account: [{validator:validateAccount, trigger: 'blur'}],
               password: [{validator:validatePassword, tigger: 'change'}],
               password2: [{validator:validatePassword2, tigger: 'blur'}],
            },
            title: '',
         }
     },
     computed: {
      config() {
         return {
            MSGINIT: '发送验证码',
            MSGSCUCCESS: '秒后重发',
            MSGTIME: 60
         };
      }
     },
     created(){
      this.msgText = this.config.MSGINIT;
      this.msgTime = this.config.MSGTIME;
     },
   methods:{
      // 注册类型下一步
      typeNext(){
         if(this.form.regType == null){
            this.$message({
               showClose: true,
               message: '请选择账号类型后点击下一步！',
               type: 'warning'
            });
           return
         } else {
            if(this.form.regType == 'bidder') {
            //    this.registerTitle = '个人用户注册';
               this.title = '通过手机号找回';
            } else {
            //    this.registerTitle = '机构用户注册';
               this.title = '通过邮箱账号找回';
            }
            this.isShow = false
         }
      },
      // 步骤下一步
      next() {
         if(this.active == 0){
            if(this.form.regType == 'bidder'){
               checkRegPhoneCode(this.form.phone,this.form.regCode,this.form.key).then((res) => {
                  if(res.data.success){
                     this.verify = false;
                     this.refreshCode(); 
                     // 验证通过 active++
                     this.active++;
                  }
               }) 
            } else {
               checkRegEmailCode(this.form.email,this.form.regCode,this.form.key).then((res) => {
                  if(res.data.success){
                     this.verify = false;
                     this.refreshCode(); 
                     // 验证通过 active++
                     this.active++;
                  }
               })
            } 
         } else if(this.active == 1){
            // 竞买个人账户保存注册信息
            this.$refs.userForm.validate((valid,done) => {
               if(valid) {
                     findPassword(this.form).then((res) => {
                      if(res.data.success){
                        this.$message.success(res.data.msg)
                        // 注册成功
                        this.active++;
                      }
                     }).catch((err) => {
                         this.$message.error(err.msg || err.message);
                     })
               } else {
                  // 机构保存注册信息
                  done()
               }
            })
         }
      },
      // 邮箱手机验证下一步
      verifyNext() {
         this.$refs.phoneOrEmail.validate((valid) => {
               if(valid) {
                    this.handleSend();
                    this.verify = true;
               } 
            });
      },
      // 认证跳转
      toLogin(){
        this.$router.push({path:"/login"});
      },
      // 图片验证码
      refreshCode() {
          getCaptcha().then(res => {
            const data = res.data;
            // 图片验证码key覆盖短信或邮箱验证码
            this.form.key = data.key;
            this.$set(this.form, 'image', data.image)
          })
      },
      // 手机youxian发送验证码
      handleSend(){
         this.msgText = this.msgTime + this.config.MSGSCUCCESS;
         this.msgkey = true;
         const time = setInterval(() => {
            this.msgTime--;
            this.msgText = this.msgTime + this.config.MSGSCUCCESS;
            if(this.msgTime === 0) {
               this.msgTime = this.config.MSGTIME;
               this.msgText = this.config.MSGINIT;
               this.msgkey = false;
               clearInterval(time)
            }
         }, 1000);
         if(this.form.regType == 'bidder'){
               sendRegisterBidder(this.form.phone).then((res) => {
               const data = res.data.data;
               if(data.success) {
                  // 第一次邮箱或者手机验证码，点击下一步校验成功后这个key会被图片验证码的key替换
                  this.form.key = data.id;
                  this.$message.success(res.data.msg);
               }
            }).catch((err) => {
               this.$message.error(err.msg || err.message);
            })
         } else {
               sendEmailValidate(this.form.email).then((res) => {
                  const data = res.data.data;
                  if(data.success) {
                     this.form.key = data.id;
                     this.$message.success(res.data.msg);
                  }
               }).catch((err) => {
               this.$message.error(err.msg || err.message);
            })
         }
        
      },
            //  密码条强度颜色
       customColorMethod(percentage) {
         if (percentage <= 30) {
               return '#909399';
         } else if (percentage <= 70) {
               return '#e6a23c';
         } else {
               return '#3399FF';
         } 
      }, 
      // 密码条显示字
      format(percentage) {
         if (percentage <= 30) {
            return '低';
         } else if (percentage <= 70) {
               return '中';
         } else {
               return '高';
         } 
      },
   }
}
</script>
<style scoped>
.chektitle {
    font-size: 18px;
    font-weight: 400;
}
.typeBox {
   font-size: 16px;
   text-align: center;
   color: #050001;
   line-height: 30px;
   margin-top: 50px;
}
.regTypeRadio{
   display: block;
   line-height: 40px;
   text-align: left;
}
.agreement{
   color:crimson;
   font-size: 14px;
}
.registerForm{
   width: 600px;
   margin: 0 auto;
   margin-top: 50px;
}
.headerDiv{
   position: fixed;
   top: 0;
   left: 0;
   background-color: green;
   width: 100%;
   height: 98px;
   line-height: 130px;
   margin-bottom: 50px;
}
.registerFlow{
   margin-top: 50px;
}
.regSuccess{
   color:crimson;
   font-size: 20px;
   font-weight: 500px;
   line-height: 3em;
}
.radioGroup{
   margin-left: 0px;
   margin-top: 50px;
}
.buttonDiv{
   margin-top: 50px;
   width: 100%;
   display: flex;

}
.nextButton{
   width: 10%;
   display: block;
   margin: 0 auto;
}
.registerTitle{
   margin-left: 18%;
}
.checkbox{
   margin-left:13%
}
.verifyForm{
   width: 500px;
   margin: 0 auto;
   margin-top: 50px;
}
.codeSpan{
   margin-left: 20%;
}
.elFormCode{
   margin-left: 17%;
}
.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 10px;
}
.login-code-img {
  margin-top: 2px;
  width: 100px;
  height: 38px;
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 38px;
  text-indent: 5px;
  text-align: center;
  cursor:pointer!important;
}
.blockSpan{
   display: block;
   line-height: 2em;
}
</style>