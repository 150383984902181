<template>
  <div class="sifa-content">
    <div class="sifa-cont">
      <div class="my-auction">个人中心</div>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-tree highlight-current :data="data" :props="defaultProps" icon-class="none"
            @node-click="handleNodeClick"></el-tree>
        </el-col>
        <el-col :span="18">
          <router-view></router-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'PersonalCenter',
  components: {
  },
  data() {
    return {
      data: [{
        label: '账户安全',
        prop: 'account',
      },
      {
        label: '我的参拍',
        prop: 'myAuction'
      },
      {
        label: '软件使用费',
        prop: 'platformPrice',
        children: [{
          label: '未缴纳',
          prop: 'noPayment'
        }, {
          label: '已缴纳',
          prop: 'yesPayment'
        }]
      },
      {
        label: '保证金',
        prop: 'deposit'
      },
      {
        label: '发票管理',
        prop: 'invoice',
        children: [{
          label: '按订单开票',
          prop: 'makeInvoice'
        }, {
          label: '开票历史',
          prop: 'invoiceHistory'
        }]
      },
      {
        label: '招商管理',
        prop: 'attract',
        children: [{
          label: '我的需求',
          prop: 'myRequired'
        }]
      },
      {
        label: '佣金管理',
        prop: 'commission'
      }
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
        prop: 'prop'
      }
    }
  },
  methods: {
    toAccount() {
      this.$router.push('/personalCenter/accountSecurity/accountShow')
    },
    toMyBid() {
      this.$router.push('/personalCenter/myBid')
    },
    handleNodeClick(data) {
      console.log(data);
      const prop = data.prop;
      if (prop == 'account') {
        this.$router.push('/personalCenter/accountSecurity/accountShow')
      } else if (prop == 'myAuction') {
        this.$router.push('/personalCenter/myBid')
      } else if (prop == 'myRequired') {
        this.$router.push('/personalCenter/attractManager')
      } else if (prop == 'commission') {
        this.$router.push('/personalCenter/commission')
      } else if (prop == 'makeInvoice') {
        this.$router.push('/personalCenter/orderInvoice')
      } else if (prop == 'invoiceHistory') {
        this.$router.push('/personalCenter/invoiceHistory')
      } else if (prop == 'yesPayment') {
        this.$router.push({path:"/personalCenter/platformPrice",query: {platformStatus: 2}})
      } else if (prop == 'noPayment') {
        this.$router.push({path:"/personalCenter/platformPrice",query: {platformStatus: 0}})
      } else if (prop == 'deposit') {
        this.$router.push("/personalCenter/deposit")
      }
    }
  }
}
</script>
  
<style>
.sifa-content {
  width: 100%;
  background: #f4f5f5;
  height: auto;
}

.sifa-cont {
  /* display:inline-block; */
  /* width: 250px; */
  height: auto;
  margin: 0 auto;
}
.my-auction {
  width: 163px;
  height: 36px;
  padding-left: 3.5%;
  font-size: 16px;
  background: #e9e9e9;
  font-weight: 500;
  line-height: 36px;
}
.el-tree-node__content {
  height: 30px;
}
.el-tree-node__label {
  font-size: 16px;
  padding-left: 16%;
  color: #424242;
}
</style>