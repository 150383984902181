<template>
  <div>
    <div>
      <el-table :data="bulletinData" style="width: 100%" @row-click='jumpDetail' border>
        <el-table-column type="index" width="120" label="序号" align="center"></el-table-column>
        <el-table-column prop="title" label="标题" align="center"></el-table-column>
        <el-table-column prop="content" label="内容" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="tab-content">{{scope.row.content}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding:10px;padding-right:70px;background-color: #fff;margin-top: 10px;">
      <el-pagination align="right" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getCmsList } from '@/api/biz/columnBulletin/columnBulletin'
export default {
  name: 'ColumnBulletinView',
  computed: {
    type() {
      return this.$route.query.type;
    }
  },
  data() {
    return {
      queryParam: {
        type: null,
        title: ''
      },
      bulletinData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
    }
  },
  created() {
    this.init();
  },
  methods: {
    jumpDetail(row) {
      this.$router.push({ path: '/columnBulletinDetail', query: { id: row.id } })
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.queryData();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.queryData();
    },
    queryData() {
      this.queryParam.type = this.type;
      getCmsList(this.page.currentPage, this.page.pageSize, this.queryParam).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.bulletinData = data.records;
      })
    },
    init() {
      this.queryData();
    }
  }
}
</script>

<style>
</style>