<template>
  <div>
    <div class="help-left-menu">
        <div v-for="m in menuList" :key="m.id">
            <div class="help-menu-father-item">
                <span class="hover-point" @click="changeView(m,null)">{{m.name}}</span>
            </div>
            <template v-if="activeData.id==m.id">
                <div v-for="c in m.infoList" :key="c.id" class="help-menu-children-item">
                    <span class="hover-point" @click="changeView(m,c)">{{c.title}}</span>
                </div>
                <div style="height:10px;"></div>
            </template>
        </div>
    </div>
    <div class="help-right-content">
        <div class="help-right-big-title">
            <span>{{activeData.name}}</span>
        </div>
        <div class="help-right-item-out-div">
            <div class="help-right-item-div" v-for="info in activeData.infoList" :key="info.id" :id="info.id">
                <div class="help-right-tiem-title-div">
                    <span class="title-span-bottom">{{info.title}}</span>
                </div>
                <div class="help-right-item-content">
                    <div v-html="info.content"></div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {getCmsList} from '@/api/system/helpConfig';
export default {
  name: 'HelpView',
  data() {
    return {
      menuList: [],
      activeData: {},
    }
  },
  created(){
    this.init();
  },
  watch:{
    type(newVal, oldVal){
      if(newVal&&oldVal){
        this.jumpByRoute();
      }
    }
  },
  computed: {
    type() {
      return this.$route.query.type;
    }
  },
  methods: {
    changeView(father, children){      
      this.activeData = father;
      if(children){
        let startHeight = 320;
        father.infoList.forEach(ele => {
          if(ele.id!=children.id&&ele.sort<children.sort){
            let dom = document.getElementById(ele.id)
            if(dom){
              startHeight+=30;
              startHeight+=document.getElementById(ele.id).scrollHeight;
            }
          }
        });
        document.documentElement.scrollTop=startHeight;
      }
    },
    jumpByRoute(){
      this.activeData = this.menuList[0];
      if(this.type){
        let child = null;
        let end = false;
        this.menuList.forEach(m=>{
          if(m.name==this.type&&!end){
            this.activeData = m;
            end = true;
          }else{
            m.infoList.forEach(i=>{
              if(i.title==this.type&&!end){
                this.activeData = m;
                child = i;
                end = true;
              }
            })
          }
        })
        if(!end){
          document.documentElement.scrollTop=0;
        }
        this.$nextTick(()=>{
          this.changeView(this.activeData, child);
        })
      }

    },
    init(){
      getCmsList().then(res=>{
        this.menuList = res.data.data;
        this.jumpByRoute();
      })
    },
  },
}
</script>

<style>
  .help-right-item-content{
    margin-left: 30px;
    width: 900px;
    line-height: 26px;
    font-size: 14px;
    color: #333;
  }
  .title-span-bottom{
    display: inline-block;
    line-height: 44px;
    font-size: 20px;
    border-bottom: 1px solid rgb(0,102,166);
    margin-bottom: -1px;
  }
  .help-right-tiem-title-div{
    color: rgb(0,102,166);
    line-height: 44px;
    margin-bottom: 25px;
    border-bottom: 1px solid #d7d7d7;
    font-size: 20px;
  }
  .help-right-item-out-div{    
    padding: 40px 30px 0;
  }
  .help-right-item-div{
    margin-bottom: 30px;
  }
  .help-right-big-title{
    padding-left: 30px;
    line-height: 40px;
    font-size: 20px;
    color: #fff;
    background: rgb(0,102,166);
  }
  .help-right-content{
    margin-bottom: 20px;
    width: 1060px;
    margin-left: 20px;
    background: #fff;
    display: inline-block;
  }
  .help-menu-children-item{
    padding-left: 20px;
    margin-top: 10px;
    font-size: 12px;
    color: #555;
    line-height: 32px;
  }
  .hover-point:hover{
    cursor: pointer;
  }
  .help-menu-father-item{
    padding-left: 20px;
    height: 40px;
    background: #e9e9e9;
    font-size: 15px;
    line-height: 40px;
    color: #333;
  }
  .help-left-menu{
    width: 220px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: top;
  }
</style>