<template>
  <div class="login-container" ref="login">
    <LoginHead></LoginHead>
    <div class="login-weaper animated bounceInDown">
      <div class="login-left">
        <div class="login-time">
          一站式招采拍卖服务平台
          <!-- {{time}} -->
        </div>
        <div class="title">
          <div class="title-top">与齐鲁观水共创未来</div>
          <div class="title-content">欢迎加入齐鲁观水电子招采平台，在齐鲁观水，</div>
          <div class="title-content">您可以完善企业资质，收获更多商机，</div>
          <div class="title-content">与核心企业紧密绑定无缝协作，并可收获金融支持。</div>
          <div class="title-content">齐鲁观水，助力企业腾飞。</div>
        </div>
      </div>
      <div class="login-border">
        <div class="login-main">
          <div class="login-register cursor-pointer-text" @click="toRegister">
            <!-- 注册 -->
            没有账户？立即注册
          </div><br><br>
          <h4 class="login-title">
            尊敬的竞买人，欢迎登录您的账户
          </h4>
          <div class="change-login-type">
            <span :class="loginType==0?'login-type-selected':'login-type cursor-pointer-text'"
              @click="changeLoginType(0)">账户登录</span>
            <img class="login-change cursor-pointer-text" @click="changeLoginType" src="../../public/img/change.png">
            <span :class="loginType==1?'login-type-selected':'login-type cursor-pointer-text'"
              @click="changeLoginType(1)">手机号登录</span>
          </div>
          <div class="login-type-div">
            <UserLogin v-if="loginType == 0"></UserLogin>
            <CodeLogin v-if="loginType == 1"></CodeLogin>
          </div>
          <!-- 登录类型按钮 -->
          <div class="login-menu">
            <!-- 注册 -->
            <img src="../../public/img/yellow-warning.png">
            <span>操作指南</span>
            <a href="#" @click="toDownLoadOperationManual">下载</a><br>
            <img src="../../public/img/yellow-warning.png">
            <span style="color:rgba(214, 54, 59, 1)">电话：0123-1234567</span>
          </div>
          <div class="login-bottom-phone">
            <span>
              客服电话 ：
            </span>
            <a href="#">4009210101-7174</a>
            <p>
              (工作日 8:30-17:00)
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import LoginHead from '@/pages/login/LoginHead.vue'
import UserLogin from "./login/userlogin";
import CodeLogin from "./login/codelogin.vue"
import { dateFormat } from "@/util/date";
import { cmsGetUrl } from '@/api/system/param';
// import {getQueryString, getTopUrl} from "@/util/util";
export default {
  name: "LoginVue",
  components: {
    UserLogin,
    CodeLogin,
    LoginHead
  },
  data() {
    return {
      time: "",
      activeName: "user",
      socialForm: {
        tenantId: "000000",
        source: "",
        code: "",
        state: "",
      },
      loginType: 0,
    };
  },
  watch: {
    $route() {
      // this.handleLogin();
    },
  },
  created() {
    // this.handleLogin();
    this.getTime();
  },
  mounted() {
  },
  props: [],
  methods: {
    toDownLoadOperationManual() {

    },
    changeLoginType(type) {
      if (type == 0 || type == 1) {
        this.loginType = type;
      } else {
        this.loginType = (this.loginType + 1) % 2
      }
    },
    getTime() {
      setInterval(() => {
        this.time = dateFormat(new Date());
      }, 1000);
    },
    // handleLogin() {
    //   const topUrl = getTopUrl();
    //   const redirectUrl = "/oauth/redirect/";
    //   // const ssoCode = "?code=";
    //   this.socialForm.source = getQueryString("source");
    //   this.socialForm.code = getQueryString("code");
    //   this.socialForm.state = getQueryString("state");
    //   if (validatenull(this.socialForm.source) && topUrl.includes(redirectUrl)) {
    //     let source = topUrl.split("?")[0];
    //     source = source.split(redirectUrl)[1];
    //     this.socialForm.source = source;
    //   }
    // },
    toRegister() {
      cmsGetUrl({ paramKey: 'registerUrl' }).then(res => {
        let url = res.data.data.paramValue
        window.open(url)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/login.scss";
</style>