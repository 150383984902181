<template>
    <div class="sifa-cont-right">
      <div class="personbox">
        <h2>佣金管理</h2>
        <div class="sub-page"></div>
        <template>
            <el-table :data="data" style="width: 100%" :header-cell-style="{textAlign: 'center'}" :cell-style="{textAlign:'center'}">
                <el-table-column prop="lotName" label="标的名称" width="250"> </el-table-column>
                <el-table-column prop="auctionStatus" label="拍卖状态" :formatter="formatter"> </el-table-column>
                <el-table-column prop="derate" label="佣金减免"> </el-table-column>
                <el-table-column prop="address" label="实缴佣金"> </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="180">
                    <template slot-scope="scope">
                        <el-button @click="view(scope.row)" type="text" size="small">查看结算单</el-button>
                        <el-button @click="pay(scope.row)" type="text" size="small" v-if="scope.row.isPay == 0 || scope.row.isPay == -1">缴纳佣金</el-button>
                        <el-button @click="viewPay(scope.row)" type="text" size="small" v-if="scope.row.isPay == 2 || scope.row.isPay == 1">查看缴纳佣金</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <div class="block">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10,20,30,40,50,100]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
            </el-pagination>
        </div>
        <el-dialog
                title="结算单"
                :visible.sync="dialogVisible"
                width="40%"
                center
                class="custom-dialog">
             <el-row>
                <el-col :span="12">拍卖会名称：{{form.meetingName}}</el-col>
                <el-col :span="12">标的编号: {{form.lotCode}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="12">标的名称: {{form.lotName}}</el-col>
                <el-col :span="12">保证金金额: {{form.depositePrice}}元</el-col>
            </el-row>
            <el-row>
                <el-col :span="12">佣金比例: {{form.ratio}}%</el-col>
                <el-col :span="12">佣金金额: {{form.commission}}元</el-col>
            </el-row>
            <el-row>
                <el-col :span="12">佣金减免: {{form.derate}}元</el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false" style="width: 15%;">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="您的缴费信息"
                :visible.sync="payDialog"
                width="50%"
                center
                class="custom-dialog">
                <el-row>
                    <el-col :span="10">标的名称: {{form.lotName}}</el-col>
                    <el-col :span="7">应缴纳佣金: {{form.commission}}</el-col>
                    <el-col :span="7">佣金减免: {{form.derate}}</el-col>
                </el-row>
                <el-row>
                    <el-form ref="form" :model="form" label-width="100px" v-if="form.payType == 2" size="small">
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="实缴佣金:">
                                    <el-input-number v-model="form.payAmount" :precision="2" :step="0.1" :controls=false></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-col :span="8" v-else>实缴佣金:
                        <span>{{ payAmount }}</span>
                    </el-col>
                </el-row>
                  <!-- 线上支付 -->
                  <div v-if="form.payType == 1">
                    <el-row>
                        <el-button type="primary" @click="getCommissionRecords" style="width: 15%;" size="small">获取缴费记录</el-button>
                    </el-row>
                    <el-row>
                        <el-table :data="tableData" height="250" border style="width: 100%" size="small"  >
                            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column> 
                            <el-table-column prop="payAccount" label="付款人账号" align="center"></el-table-column>
                            <el-table-column prop="genterName" label="收款银行名称" align="center"></el-table-column>
                            <el-table-column prop="genterAccount" label="收款账号" align="center"></el-table-column>
                            <el-table-column prop="payAmount" label="到账金额" align="center"></el-table-column>
                            <el-table-column prop="accountTime" label="到账时间" align="center"></el-table-column>
                        </el-table>
                    </el-row>
                </div>
                <!-- 线下支付 -->
                <div v-if="form.payType == 2" class="upload-container">
                    
                    <el-upload class="upload-demo" drag multiple
                        :limit="1"
                        :action="`/api/sino-resource/oss/endpoint/put-file?${this.website.tokenHeader}=${this.token}`"
                        :on-success="handleCommissionSuccess"
                        :on-remove="comFileRemove"
                        :before-upload="beforeUpload"
                        :on-preview="uploadPreview"
                        >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">请上传缴费凭证附件</div>
                    </el-upload>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitRecords(form.payType)" style="width: 15%;" size="small">提 交</el-button>
                </span>
        </el-dialog>

        <el-dialog
                title="查看缴费信息"
                :visible.sync="viewDialog"
                width="50%"
                center
                class="custom-dialog"
                :on-preview="uploadPreview">
                <el-row>
                    <el-col :span="10">标的名称: {{form.lotName}}</el-col>
                    <el-col :span="7">应缴纳佣金: {{form.commission}}</el-col>
                    <el-col :span="7">佣金减免: {{form.derate}}</el-col>
                </el-row>
                <!-- <el-row>
                    <el-form ref="form" :model="form" label-width="100px" v-if="form.payType == 2" size="small">
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="实缴佣金:">
                                    <el-input-number v-model="form.payAmount" :precision="2" :step="0.1" :controls=false></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-col :span="8" v-else>实缴佣金:
                        <span>{{ payAmount }}</span>
                    </el-col>
                </el-row> -->
                  <!-- 线上支付 -->
                  <div v-if="form.payType == 1">
                    <el-row>
                        <el-table :data="tableData" height="250" border style="width: 100%" size="small"  >
                            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column> 
                            <el-table-column prop="payAccount" label="付款人账号" align="center"></el-table-column>
                            <el-table-column prop="genterName" label="收款银行名称" align="center"></el-table-column>
                            <el-table-column prop="genterAccount" label="收款账号" align="center"></el-table-column>
                            <el-table-column prop="payAmount" label="到账金额" align="center"></el-table-column>
                            <el-table-column prop="accountTime" label="到账时间" align="center"></el-table-column>
                        </el-table>
                    </el-row>
                </div>
                <!-- 线下支付 -->
                <div v-if="form.payType == 2" class="upload-container">
                    
                    <el-upload class="upload-demo" drag multiple
                        :limit="1"
                        :action="`/api/sino-resource/oss/endpoint/put-file?${this.website.tokenHeader}=${this.token}`"
                        :on-success="handleCommissionSuccess"
                        :on-remove="comFileRemove"
                        :before-upload="beforeUpload"
                        :file-list="commissionList"
                        :disabled="true"
                        >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">请上传缴费凭证附件</div>
                    </el-upload>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitRecords(form.payType)" style="width: 15%;" size="small">提 交</el-button>
                </span>
        </el-dialog>
      </div>
      <el-dialog
      title=""
      :visible.sync="IMGdialogVisible"
      :modal-append-to-body="false"
      center
      append-to-body>
      <div v-if="IMGdialogVisible">
          <el-image :src="url"></el-image>
      </div>
      </el-dialog>
      <el-dialog
      title=""
      :visible.sync="PDFdialogVisible"
      fullscreen
      append-to-body>
      <iframe :src="'/pdf/web/viewer.html?file='+ url" width="100%" height="850px" type="application/pdf"></iframe>
    </el-dialog>
    </div>
</template>
  
<script>
  import {mapGetters} from "vuex";
  import {getList, detailCommission as getDetail, payCommission, getCommissionRecord,cmcGetCommission} from '@/api/biz/auction/winLot'
  import AuctionStatusOptions from "@/enum/AuctionStatusOptions";
  import {getToken} from "@/util/auth";

export default {
  name: 'comMission',
  computed: {
        ...mapGetters(["userInfo"]),
        token(){
            return getToken();
        }
    },
  data(){
    return {
        page: {
            pageSize: 10,
            currentPage: 1,
            total: 0
        },
        data:[],
        auctionStatusOptions:[],
        // 结算单
        dialogVisible: false,
        form:{
            payAmount: ''
        },
        // 缴纳
        payDialog: false,
        // 缴费记录表格
        tableData: [],
        commissionFile:{},
        payAmount: '',
        viewDialog: false,
        commissionList:[],
        PDFdialogVisible: false,
        IMGdialogVisible: false,
        // 地址
        url: '',
    }
  },
  created(){
    if(getToken()&&this.userInfo&&this.userInfo.user_id){
      this.init();
    }else{
      this.$router.push("/login")
    }    
  },
  methods:{
    // 文件预览
    uploadPreview(file){
      let fileName = file.name;
      let index = fileName.lastIndexOf('.');
      let fileExten = fileName.substring(index + 1).toLowerCase();
      if(fileExten == 'pdf'){
        this.PDFdialogVisible = true;
        this.url = file.response.data.link;
      } else {
        this.IMGdialogVisible = true;
        this.url = file.response.data.link;
      }
    },
    viewPay(row){
        console.log("查看",row)
        this.viewDialog = true; 
        cmcGetCommission(row.id).then((res) => {
            console.log(11111111,res)
            const data = res.data.data;
            this.form = data;
            if(data.payType == 1){
                this.tableData = data.payInfoList;
            } else {
                this.form.commissionAttach
                this.commissionList.push({name: this.form.commissionAttach.originalName, url:this.form.commissionAttach.link})
                console.log("线下")
            }
        })
    },
    // 佣金凭证限制文件类型
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isPDF = file.type === 'application/pdf';

      if (!isJPG && !isPDF) {
        this.$message.error('只能上传jpg、png或pdf格式的文件');
        return false; // 阻止上传
      }
      return true; // 允许上传
    },
    // 佣金凭证删除
    comFileRemove(){
      this.commissionFile = null;
    },
    // 线下支付上传凭证
    handleCommissionSuccess(res){
      this.commissionFile = res.data
    },
    // 提交缴费记录
    submitRecords(payType){
        console.log('payType',payType)
        // 线上校验
        if(payType == 1){
            this.form.payInfoList = this.tableData;
            if(this.form.payInfoList.length == 0){
                return this.$message('请获取您的佣金缴费记录')
            }
            payCommission(this.form).then(() => {
                this.$message({
                    type: "success",
                    message: "操作成功!"
                });
            })
        }
        // 线下校验
        if(payType == 2){
            this.form.commissionAttach = this.commissionFile;
            if(this.commissionFile == null || Object.keys(this.commissionFile).length === 0){
                return this.$message('请您上传您的佣金缴费凭证')
            }
            if(this.form.payAmount == null) {
                return this.$message('请输入实缴佣金')
            }
            payCommission(this.form).then(() => {
            this.$message({
                type: "success",
                message: "操作成功!"
          });
        })
        }
        this.onLoad(this.page);
        this.payDialog = false;
    },
    // 获取佣金缴费记录
    getCommissionRecords(){
        let params = {
            id: this.form.id,
            meetingId: this.form.meetingId,
        }
        getCommissionRecord(params).then((res) => {
            console.log('1111res',res)
            const data = res.data.data;
            console.log('1111data',data)
            if(res.data.success && data.isPay == 'true'){
                let tempTime = (data.trxdat?(data.trxdat.substring(0,4)+"-"+data.trxdat.substring(4,6)+"-"+data.trxdat.substring(6,8)): new Date().toLocaleDateString().replaceAll('/', '-'))
                     + " " + data.trxtim.substring(0,2) + ":" + data.trxtim.substring(2,4) + ":" + data.trxtim.substring(4,6)
                this.tableData = [{index:0,personCoName:data.acName,payAccount:data.rpyacc,genterName:data.rpynam,
                    genterAccount: (data.inbacc + data.dyanbr), payAmount: data.trxamt, accountTime: tempTime, busino:data.trxnbr, trxset:data.trxset}]
                    this.payAmount = data.trxamt;
            } else {
                this.$message.warning('未查询到缴费记录')
            }
        })
        console.log('请求',params)
    },
    // 初始化
    init(){
        this.auctionStatusOptions = AuctionStatusOptions;
        this.onLoad(this.page);
    },
    // 缴纳佣金
    pay(row){
        this.form = {};
        this.payAmount = '';
        this.tableData = [];
        this.payDialog = true;
        getDetail(row.id).then((res) => {
            const data = res.data.data;
            this.form = data;
        })
    },
    // 查看结算单
    view(row){
        console.log(1111,row)
        this.form = {};
        this.dialogVisible = true;
        getDetail(row.id).then((res) => {
            const data = res.data.data;
            this.form = data;
        })
    },
    //  格式化
    formatter(row, column, cellValue){
        let child = this.auctionStatusOptions;
         for(var index in child) {
          if(child[index].key == cellValue) {
            return child[index].value;
          }
        }
        return ""
    },
    handleSizeChange(val) {
        this.page.pageSize = val;
        this.onLoad(this.page);
    },
    handleCurrentChange(val) {
        this.page.currentPage = val;
        this.onLoad(this.page);
    },
    onLoad(page) {
        getList(page.currentPage, page.pageSize).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          console.log('data',this.data)
        });
      }
  }
}
</script>

<style scoped>
.personbox {
  margin: 0 0 40px;
  display: none;
  background-color: #fff;
  display: block;
}
.personbox h2 {
  height: 30px;
  line-height: 30px;
  background: #e9e9e9;
  color: #424242;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.sub-page{
    margin-top: 2%;
}
.block{
    margin-left: 50%;
    margin-bottom: 2%;
    margin-top: 2%;
}
.el-table--border{
    border: 1px solid #424242;
}
::v-deep .custom-dialog .el-dialog__header{
  background-color:#2196f3;
  padding: 16px 16px 10px;
}
::v-deep .custom-dialog .el-dialog__title{
  color:#fff;
  font-size: 16px;
}
::v-deep .custom-dialog .el-dialog__headerbtn .el-dialog__close{
  color:#fff;
}
::v-deep .el-col{
  line-height: 3em;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh; /* 可以根据需要设置高度 */
}
</style>