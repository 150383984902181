<template>
  <div class="content">
    <div class="title">{{bulletinData.title}}</div>
    <div class="time"> 发布时间：{{bulletinData.publishTime?bulletinData.publishTime:'暂无数据'}}</div>
    <div class="node" v-html="bulletinData.content"></div>
    <!-- <div class="download"> -->
    <el-button style="font-size: 16px;    position: absolute;
    right: 20px;
    bottom: 50px;
  float: right;" type="info"><a :href="bulletinData.attrUrl" style="color: #fff;"> {{bulletinData.title}} —— 附件</a><i
        class="el-icon-download"></i></el-button>

    <!-- </div> -->
  </div>
</template>

<script>
import { getDetail } from '@/api/biz/columnBulletin/columnBulletin'
export default {
  name: 'ColumnBulletinView',
  data() {
    return {
      bulletinData: {},
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    }
  },
  created() {
    this.queryDetail();
  },
  methods: {
    queryDetail() {
      getDetail(this.id).then(res => {
        this.bulletinData = res.data.data;
      })
    }
  }
}
</script>

<style>
.content {
  background: #fff;
  min-height: 680px;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
}
.title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin: 20px 0;
}
.time {
  /* width: 90%; */
  margin: 0 auto;
  color: #999;
  background: #f2f6fc;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.node {
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 16px;
  width: 80%;
  /* margin: 20px auto; */
}
.download {
  padding: 20px;
  background: #409eff;
  font-size: 16px;
  cursor: pointer;
  float: right;
}
a :hover {
  color: #fff;
  color: inherit;
}
</style>