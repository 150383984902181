<template>
        <el-form ref="form" :model="form" label-width="130px" class="authForm" size="small" :rules="rules">
            <el-row class="authRow" v-if="type == 1">
                <el-col :span="10">
                    <el-form-item label="原密码：" :span="10" prop="oldPassword">
                        <el-input  placeholder="请输入原密码" v-model="form.oldPassword" show-password></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="authRow" v-if="type == 1">
                <el-col :span="10">
                    <el-form-item label="新密码：" :span="10" prop="password">
                        <el-input  placeholder="请输入新密码" v-model="form.password" show-password></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="authRow" v-if="type == 1">
                <el-col :span="10">
                    <el-form-item label="重复新密码：" :span="10" prop="password2">
                        <el-input placeholder="请再次输入新密码" v-model="form.password2" show-password></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="authRow" v-if="type == 2">
                <!-- <el-steps :active="active" finish-status="success">
                    <el-step title="验证身份">
                        
                    </el-step>
                    <el-step title="修改手机号"></el-step>
                    <el-step title="修改成功"></el-step>
                </el-steps> -->
                <el-col :span="10">
                    <el-form-item label="手机号：" :span="10" prop="phone">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="authRow" v-if="type == 3">
                <el-col :span="10">
                    <el-form-item label="邮箱账号：" :span="10" prop="email">
                        <el-input v-model="form.email"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-top: 5%;">
                    <el-form-item>
                        <!-- <el-button type="primary" @click="submitForm('form')" style="width: 15%;">提交</el-button> -->
                        <el-button type="primary" @click="submitForm(form,type)" style="width: 15%;">提交</el-button>
                    </el-form-item>
            </el-row>
        </el-form>
</template>

<script>
import {mapGetters} from "vuex";
import md5 from 'js-md5';
import {updatePassword,cmsUpdateUser} from '@/api/system/user';
export default {
name: 'accountEdit',
       
computed: {...mapGetters(["userInfo"]),
    type(){
            return this.$route.query.type;
    },
},
created(){
    console.log('type',this.type)
},
data(){
    var validatePassword = (rule, value, callback) => {
         // 密码校验
        let passwordRegex = /^[^\u4e00-\u9fa5\s]{6,16}$/;
            if (value === '') {
                callback(new Error('请输入密码'));
        } else if (!passwordRegex.test(value)) {
                 callback(new Error('密码长度在6-16之间，不能使用中文、空格、非法字符'));
        } else {
            callback();
        }
      };
       // 二次密码校验
       const validatePassword2 = (rule, value, callback) => {
         if (value === '') {
         callback(new Error('请再次输入密码'));
         } else if (value !== this.form.password) {
         callback(new Error('两次输入密码不一致'));
         } else {
         callback();
         }
       };
        //    手机号校验
       var validatePhone = (rule, value, callback) => {
            let pattern = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
            if (value === '') {
               callback(new Error('请输入您的手机号'));
            } else if(!pattern.test(value)){
               callback(new Error('请您输入正确的手机号'))
            } else {
               callback();
            }
         };
  return {
    form: {},
    data: {},
    rules:{    
        oldPassword: [{required: true, message: '请输入旧密码', trigger: 'blur'}],
        phone:   [{ required: true,validator:validatePhone, trigger: 'blur' }],
        email:   [{ required: true, message: '请输入您的电子邮箱', trigger: 'blur'},
                    {type: 'email', message:'邮箱格式不正确，请重新输入',trigger: 'blur'}],
        password: [{required: true,validator:validatePassword, tigger: 'change'}],
        password2: [{required: true,validator:validatePassword2, tigger: 'blur'}],
        },
    // active: 0
  }
},
methods:{
    submitForm(form,type){
        if(type == 1) {
            this.$refs.form.validate((valid) => {
                if(valid) {
                    updatePassword(md5(form.oldPassword),md5(form.password),md5(form.password2)).then(res => {
                        if(res.data.success){
                            this.$message({
                            type: "success",
                            message: "修改密码成功!"
                         });
                         this.$router.push("/personalCenter/accountSecurity/accountShow")
                        } else {
                            this.$message({
                            type: "error",
                            message: res.data.msg
                            });
                        }
                    })
                }
            }) 
        } else {
            this.$refs.form.validate((valid) => {
                if(valid) {
                    form.id = this.userInfo.user_id;
                    console.log('form',form)
                    cmsUpdateUser(form).then(() => {
                        this.$message({
                            type: "success",
                            message: "操作成功!"
                         });
                         this.$router.push("/personalCenter/accountSecurity/accountShow")
                    })
                }
            })
        }
    }
}
}
</script>

<style>
.authForm{
    margin-top: 3%;
}
.authRow {
    margin-left: 7%;
}
</style>